import './dashboardMenuCommp.scss';
import React, { useCallback, useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator, { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem, Popover, Tooltip } from '@mui/material';
import { NMS_TYPES, NMS_STATUS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { authenticate, clearAuthToken, logout } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';
import { isImportTypeAssertionContainer } from 'typescript';
import { getAllSessionsByPharmacy, getPharmacyById } from 'app/entities/session/session.reducer';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import BoyIcon from '@mui/icons-material/Boy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import zIndex from '@mui/material/styles/zIndex';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { isNull } from 'lodash';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const DashboardMenuCommp = props => {
  const { sessionCounts, handleSessionStatusValue, showMenu, setShowMenu } = props;
  const page = useAppSelector(state => state.session.pageNo);
  const rowsPerPage = useAppSelector(state => state.session.rows);
  const [index, setIndex] = React.useState(0);
  const [showOptions, setShowOptions] = React.useState(false);
  const [values, setValues] = useState({ service_id: 1, session_type: null, session_status: null });
  const [interventionCount, setInterventionCount] = useState(0);
  const [followUpCount, setFollowUpCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [notConsentCount, setNoConsentCount] = useState(0);
  const [notReachableCount, setNotReachableCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [selectedColor, setSelectedColor] = useState('one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.authentication.account);
  const pharmacyName = localStorage.getItem('pharmacy_name') === null ? '' : localStorage.getItem('pharmacy_name');
  const managerName = localStorage.getItem('manager_name') === null ? '' : localStorage.getItem('manager_name');
  const pharmacyId = localStorage.getItem('pharmacy_id') === null ? '' : localStorage.getItem('pharmacy_id');
  const pharmacyDetail = useAppSelector(state => state.session.pharmacyDetail);
  const role = localStorage.getItem('role') === null ? '' : localStorage.getItem('role');
  let pharmacistType = JSON.parse(localStorage.getItem('pharmacist_type'));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleDashboardSwitch = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowOptions(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  const getPharmacyBy = useCallback(
    async (id: number) => {
      await dispatch(getPharmacyById(id));
    },
    [Number(pharmacyId)]
  );

  useEffect(() => {
    setInterventionCount(sessionCounts ? sessionCounts['interventionCount'] : 0);
    setFollowUpCount(sessionCounts ? sessionCounts['followUpCount'] : 0);
    setOverdueCount(sessionCounts ? sessionCounts['overdueCount'] : 0);
    setCompletedCount(sessionCounts ? sessionCounts['completedCount'] : 0);
    setNoConsentCount(sessionCounts ? sessionCounts['noConsentCount'] : 0);
    setNotReachableCount(sessionCounts ? sessionCounts['noReachableCount'] : 0);
  }, [sessionCounts]);

  useEffect(() => {
    getPharmacyBy(Number(pharmacyId));
  }, [Number(pharmacyId)]);

  const handleSessionListing = e => {
    const sessionTypeValues = e.target.getAttribute('data-session-type');
    const dataIndex = e.target.getAttribute('data-index');
    const sessionStatusValues = e.target.getAttribute('data-session-status');
    setIndex(dataIndex);
    handleSessionStatusValue(sessionStatusValues);
    const data = {
      pageNo: 0,
      rows: rowsPerPage,
      q: '',
      service_id: 1,
      session_type: sessionTypeValues,
      session_status: sessionStatusValues,
      sort: {
        address: false,
        fullName: false,
        nextSession: false,
      },
    };
    setTimeout(() => {
      dispatch(getAllSessionsByPharmacy(data));
    }, 50);
  };

  return (
    <Grid
      lg={showMenu ? 0.6 : 2.1}
      xs={12}
      sm={showMenu ? 1 : 3}
      container
      style={{
        backgroundColor: '#004CB2',
        justifyContent: 'center',
        position: 'fixed',
        height: '100vh', // Set a fixed height for the grid
        overflow: 'hidden', // Prevent overflow
        transition: 'ease 0.4s',
      }}
    >
      {/* <Divider style={{ backgroundColor: 'lightgray', height: 3, alignSelf: 'flex-end', marginLeft: 0 }} /> */}
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <img
          src={'../../../content/logos/login-bg.png'} // Use the image URL from the array
          alt="logo"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.2,
          }}
        />
      </div>

      {showMenu === false ? (
        <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%' }}>
          <Tooltip title={'Collapse'}>
            <img
              src={'../../../../content/assets/close-dashboard.png'}
              alt="Close Dashboard"
              onClick={handleDashboardSwitch}
              style={{
                position: 'absolute', // Set absolute positioning
                top: 5, // Adjust positioning for the top
                right: 5, // Adjust positioning for the right
                width: '24px',
                height: '24px',
                cursor: 'pointer', // Change cursor on hover
                transform: showMenu ? 'scaleX(-1)' : 'none', // Mirror invert the icon when showMenu is true
                transition: 'transform 0.6s ease',
                filter: 'invert(1) brightness(3)', // Invert colors and increase brightness
              }}
            />
          </Tooltip>
          <List
            aria-label="Sidebar"
            sx={{
              '--ListItem-paddingLeft': '0px',
              '--ListItemDecorator-size': '20px',
              '--ListItem-minHeight': '10px',
              '--List-nestedInsetStart': '10px',
              [`& .${listItemDecoratorClasses.root}`]: {
                justifyContent: 'space-between',
                pr: '18px',
              },
              '& [role="button"]': {
                borderRadius: '0 2px 2px 0',
              },
              height: 300,
            }}
          >
            <ListItem>
              <ListItemButton
                onClick={() => (showOptions === false ? setShowOptions(true) : setShowOptions(false))}
                style={{
                  backgroundColor: selectedColor === ('one' || 'two') ? '#99D7FF' : 'transparent',
                  cursor: 'pointer',
                  borderRadius: 25,
                  height: 38,
                  // '&:hover': {
                  //   backgroundColor: '#7CB6F8',
                  // },
                }} // Added cursor style for indicating clickability
              >
                <img
                  src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '17px', marginLeft: 20, marginRight: 10 }}
                />
                <ListItemContent
                  sx={{
                    fontWeight: 600,
                    color: 'white',
                    fontSize: 14,
                    marginLeft: 1,
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  <span style={{ color: selectedColor === ('one' || 'two') ? '#004cb2' : 'white' }}>
                    In Progress{' '}
                    {showOptions ? (
                      <ArrowDropUpIcon style={{ color: selectedColor === ('one' || 'two') ? '#004cb2' : 'white', float: 'right' }} />
                    ) : (
                      <ArrowDropDownIcon style={{ color: selectedColor === ('one' || 'two') ? '#004cb2' : 'white', float: 'right' }} />
                    )}
                  </span>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <Popover
              open={showOptions}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 100, left: 300 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <List>
                {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
                  <ListItem
                    onClick={handleSessionListing}
                    data-session-status={NMS_STATUS.IN_PROGRESS}
                    data-session-type={NMS_TYPES.INTERVENTION}
                  >
                    <ListItemButton
                      data-session-type={NMS_TYPES.INTERVENTION}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      selected={index === 1}
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        setSelectedColor('one');
                      }}
                    >
                      <img
                        src={'../../../../content/assets/intervention-logo.png'}
                        alt="Intervention Logo"
                        style={{ height: '17px', marginRight: 10 }}
                      />
                      <ListItemContent
                        data-session-type={NMS_TYPES.INTERVENTION}
                        data-index={1}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                        sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}
                      >
                        <span
                          style={{ color: 'black' }}
                          data-session-type={NMS_TYPES.INTERVENTION}
                          data-index={1}
                          data-session-status={NMS_STATUS.IN_PROGRESS}
                        >
                          Interventions
                        </span>
                      </ListItemContent>
                      <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        {sessionCounts?.interventionCount}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ) : null}

                {pharmacistType === 'follow_up' || pharmacistType === 'both' ? (
                  <ListItem
                    onClick={handleSessionListing}
                    data-session-status={NMS_STATUS.IN_PROGRESS}
                    data-session-type={NMS_TYPES.FOLLOW_UP}
                  >
                    <ListItemButton
                      data-session-type={NMS_TYPES.FOLLOW_UP}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      data-index={2}
                      selected={index === 2}
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        setSelectedColor('one');
                      }}
                    >
                      <img
                        src={'../../../../content/assets/follow-up-icon.png'}
                        alt="Follow-up Logo"
                        style={{ height: '17px', marginRight: 10 }}
                      />
                      <ListItemContent sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}>
                        <span
                          style={{ color: 'black' }}
                          data-session-type={NMS_TYPES.FOLLOW_UP}
                          data-index={2}
                          data-session-status={NMS_STATUS.IN_PROGRESS}
                        >
                          Follow Ups
                        </span>
                      </ListItemContent>
                      <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        {sessionCounts?.followUpCount}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ) : null}
              </List>
            </Popover>
            {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
              <>
                <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_CONSENT}>
                  <ListItemButton
                    data-index={5}
                    data-session-status={NMS_STATUS.NO_CONSENT}
                    selected={index === 5}
                    style={{ backgroundColor: selectedColor === 'five' ? '#99D7FF' : 'transparent', borderRadius: 25 }}
                    onClick={() => {
                      setSelectedColor('five');
                    }}
                  >
                    <img
                      data-index={5}
                      data-session-status={NMS_STATUS.NO_CONSENT}
                      src={'../../../../content/assets/no-svgrepo-com.png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                    />
                    <ListItemContent
                      data-index={5}
                      data-session-status={NMS_STATUS.NO_CONSENT}
                      sx={{
                        fontWeight: 'bold',
                        color: selectedColor === 'five' ? '#4877B1' : undefined,
                        fontSize: 13,
                        marginLeft: 1,
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      <span style={{ color: selectedColor === 'five' ? '#004cb2' : 'white' }} data-session-status={NMS_STATUS.NO_CONSENT}>
                        No Consent
                      </span>
                    </ListItemContent>
                    <Typography
                      level="body-sm"
                      sx={{ fontWeight: 'bold', color: selectedColor === 'five' ? '#004CB2' : 'white', fontSize: 13 }}
                    >
                      {notConsentCount}
                    </Typography>
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_REACHABLE}>
                  <ListItemButton
                    data-index={6}
                    data-session-status={NMS_STATUS.NO_REACHABLE}
                    selected={index === 6}
                    style={{ backgroundColor: selectedColor === 'six' ? '#99D7FF' : 'transparent', borderRadius: 25 }}
                    onClick={() => {
                      setSelectedColor('six');
                    }}
                  >
                    <img
                      data-index={6}
                      data-session-status={NMS_STATUS.NO_REACHABLE}
                      src={'../../../../content/assets/phone-xmark-alt-svgrepo-com.png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                    />
                    <ListItemContent
                      data-index={6}
                      data-session-status={NMS_STATUS.NO_REACHABLE}
                      sx={{
                        fontWeight: 'bold',
                        color: selectedColor === 'six' ? '#4877B1' : 'transparent',
                        fontSize: 13,
                        marginLeft: 1,
                      }}
                    >
                      <span style={{ color: selectedColor === 'six' ? '#004cb2' : 'white' }} data-session-status={NMS_STATUS.NO_REACHABLE}>
                        Not Contactable
                      </span>
                    </ListItemContent>
                    <Typography
                      level="body-sm"
                      sx={{ fontWeight: 'bold', color: selectedColor === 'six' ? '#004CB2' : 'white', fontSize: 13 }}
                    >
                      {notReachableCount}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </>
            ) : null}
          </List>
          {/* <div className="pharmacy_div">*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      justifyContent: 'space-between',*/}
          {/*      marginLeft: '20px',*/}
          {/*      width: '100%',*/}
          {/*      overflow: 'hidden',*/}
          {/*      alignSelf: 'center',*/}
          {/*      marginBottom: 8,*/}
          {/*      marginTop: 8,*/}
          {/*    }}*/}
          {/*  >*/}

          {/*    <div className="detail_block">*/}
          {/*      <InfoIcon className={"icon_height"} />*/}
          {/*      <h1 className="pharmacy_heading_detail">Pharmacy Detail :</h1>*/}
          {/*    </div>*/}

          {/*    /!*<div className="detail_block">*!/*/}
          {/*    /!*  <GroupIcon className={"icon_height"}/>*!/*/}
          {/*    /!*  <span className="pharmacy_heading">Group :</span>*!/*/}
          {/*    /!*  <span className="pharmacy_text">Oakwood</span>*!/*/}
          {/*    /!*</div>*!/*/}

          {/*    <div className="detail_block">*/}
          {/*      <LocalPharmacyIcon className={"icon_height"} />*/}
          {/*      <span className="pharmacy_heading">Name :</span>*/}
          {/*      <span className="pharmacy_text">{pharmacyDetail?.name}</span>*/}
          {/*    </div>*/}

          {/*    <div className="detail_block">*/}
          {/*      <BoyIcon className={"icon_height"} />*/}
          {/*      <span className="pharmacy_heading">Manager Name :</span>*/}
          {/*      <span className="pharmacy_text">{pharmacyDetail?.manager_name}</span>*/}
          {/*    </div>*/}

          {/*    <div>*/}
          {/*      <div className={"detail_block"}>*/}
          {/*        <LocationOnIcon className="icon_height" />*/}
          {/*        <span className="pharmacy_heading">Address :</span>*/}
          {/*      </div>*/}
          {/*      <p className="pharmacy_text">*/}
          {/*        {pharmacyDetail?.address}*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/* </div>*/}

          <div style={{ zIndex: 2, position: 'relative' }}>
            <Grid
              lg={12}
              container
              sx={{
                flexDirection: 'row',
                marginLeft: 0,
                marginTop: 0,
                maxHeight: 600,
              }}
            >
              <div
                style={{
                  borderRadius: '35px', // Circular corners
                  padding: 20, // Padding for internal spacing
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional shadow for a 3D effect
                  maxWidth: '100%', // Ensures box width fits well within the container
                  margin: 'auto', // Center the box horizontally
                  border: '1px solid white', // White border
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 38,
                    borderRadius: 1,
                    alignSelf: 'left',
                    justifyContent: 'left',
                    alignItems: 'left',
                  }}
                >
                  <h1
                    style={{
                      color: '#FFFFFF',
                      fontSize: 18,
                      fontWeight: 1000,
                      marginTop: 8,
                      paddingLeft: '5px',
                      fontFamily: 'Roboto, sans-serif',
                      textDecoration: 'underline',
                    }}
                  >
                    Pharmacy Details
                  </h1>
                </div>

                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                  <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                      <h1
                        style={{
                          color: 'white',
                          fontSize: 13,
                          marginTop: 5,
                          marginLeft: 5,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <LocalPharmacyIcon />
                      </h1>
                    </div>
                    <div style={{ width: 150, backgroundColor: '' }}>
                      <h1
                        style={{
                          fontSize: 13,
                          color: 'white',
                          marginTop: 8,
                          marginLeft: 0,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {pharmacyDetail?.name}
                      </h1>
                    </div>
                  </div>
                </div>
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                  <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                      <h1
                        style={{
                          color: 'white',
                          fontSize: 13,
                          marginTop: 5,
                          marginLeft: 5,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <AccountBoxIcon />
                      </h1>
                    </div>
                    <div style={{ width: 150, backgroundColor: '' }}>
                      <h1
                        style={{
                          fontSize: 13,
                          color: 'white',
                          marginTop: 8,
                          marginLeft: 0,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {pharmacyDetail?.manager_name}
                      </h1>
                    </div>
                  </div>
                </div>
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                  <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                      <h1
                        style={{
                          color: 'white',
                          fontSize: 13,
                          marginTop: 5,
                          marginLeft: 5,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <LocalPhoneIcon />
                      </h1>
                    </div>
                    <div style={{ width: 150, backgroundColor: '' }}>
                      <h1
                        style={{
                          fontSize: 13,
                          color: 'white',
                          marginTop: 5,
                          marginLeft: 0,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {pharmacyDetail?.contact_number}
                      </h1>
                    </div>
                  </div>
                </div>
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                  <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                      <h1
                        style={{
                          color: 'white',
                          fontSize: 13,
                          marginTop: 5,
                          marginLeft: 5,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <AccessTimeIcon />
                      </h1>
                    </div>
                    <div style={{ width: 150, backgroundColor: '' }}>
                      <h1
                        style={{
                          fontSize: 13,
                          color: 'white',
                          marginTop: 5,
                          marginLeft: 0,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {!pharmacyDetail?.startTime || !moment(pharmacyDetail?.startTime).isValid()
                          ? ' - '
                          : moment(pharmacyDetail?.startTime).format('hh:mm a')}
                        to
                        {!pharmacyDetail?.endTime || !moment(pharmacyDetail?.endTime).isValid()
                          ? ' - '
                          : moment(pharmacyDetail?.endTime).format('hh:mm a')}
                      </h1>
                    </div>
                  </div>
                  <Divider style={{ backgroundColor: 'white', marginTop: '20px', alignSelf: 'center', height: '2px' }} />
                </div>
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                  <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                      <h1
                        style={{
                          color: 'white',
                          fontSize: 13,
                          marginTop: 5,
                          marginLeft: 5,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <PinDropIcon />{' '}
                      </h1>
                    </div>
                    <div style={{ width: 150, backgroundColor: '' }}>
                      <h1
                        style={{
                          color: 'white',
                          fontSize: 13,
                          marginTop: 5,
                          marginLeft: 0,
                          fontWeight: 400,
                          alignSelf: 'center',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {pharmacyDetail?.address}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </div>

          {/* <div className="logout_div">
            <img src={'../../../../content/assets/profile1.png'} alt="logo" style={{ height: '35px' }} onClick={() => {}} />

            <div
              style={{
                justifyContent: 'space-between',
                marginLeft: 5,
                width: '70%',
                overflow: 'hidden',
                alignSelf: 'center',
                marginTop: 8,
              }}
            >
              <div>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
              <h1 className="logout_text"> {role === null || role === 'null' ? '' : role?.replace(/"/g, '')}</h1>
              <h1
                style={{
                  fontSize: 10,
                  color: 'white',
                  marginLeft: 0.5,
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: -3,
                }}
              >
                {pharmacyName.replace(/"/g, '')}
              </h1>
              <h1
                style={{
                  fontSize: 10,
                  color: 'white',
                  marginLeft: 0.5,
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: -3,
                }}
              >
                {managerName === null || managerName === 'null' ? '' : managerName.replace(/"/g, '')}
              </h1>
            </div>
            <IconButton
              onClick={handleClick}
              style={{
                backgroundColor: '',
                marginRight: 5,
                height: 30,
                width: 20,
                padding: '0px',
                display: 'flex',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/threedots.png'} alt="logo" style={{ height: '25px' }} />
            </IconButton>
          </div> */}
        </Grid>
      ) : (
        <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%', backgroundColor: '' }}>
          <Tooltip title={'Expand'}>
            <img
              src={'../../../../content/assets/close-dashboard.png'}
              alt="Close Dashboard"
              onClick={handleDashboardSwitch}
              style={{
                position: 'absolute', // Set absolute positioning
                top: 5, // Adjust positioning for the top
                right: 5, // Adjust positioning for the right
                width: '24px',
                height: '24px',
                cursor: 'pointer', // Change cursor on hover
                transform: showMenu ? 'scaleX(-1)' : 'none', // Mirror invert the icon when showMenu is true
                transition: 'transform 0.6s ease',
                filter: 'invert(1) brightness(3)',
              }}
            />
          </Tooltip>
          <List
            aria-label="Sidebar"
            sx={{
              '--ListItem-paddingLeft': '0px',
              '--ListItemDecorator-size': '20px',
              '--ListItem-minHeight': '10px',
              '--List-nestedInsetStart': '10px',
              [`& .${listItemDecoratorClasses.root}`]: {
                justifyContent: 'space-between',
                pr: '18px',
              },
              '& [role="button"]': {
                borderRadius: '0 2px 2px 0',
              },
              height: 300,
            }}
          >
            <ListItem>
              <ListItemButton
                onClick={() => (showOptions === false ? setShowOptions(true) : setShowOptions(false))}
                style={{
                  backgroundColor: selectedColor === ('one' || 'two') ? '#7CB6F8' : 'transparent',
                  cursor: 'pointer',
                  borderRadius: 25,
                  height: 38,
                  // '&:hover': {
                  //   backgroundColor: '#7CB6F8',
                  // },
                }} // Added cursor style for indicating clickability
              >
                <img
                  src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '17px', marginLeft: 20, marginRight: 10 }}
                />
              </ListItemButton>
            </ListItem>

            <Popover
              open={showOptions}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 100, left: 80 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <List>
                {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
                  <ListItem
                    onClick={handleSessionListing}
                    data-session-status={NMS_STATUS.IN_PROGRESS}
                    data-session-type={NMS_TYPES.INTERVENTION}
                  >
                    <ListItemButton
                      data-session-type={NMS_TYPES.INTERVENTION}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      selected={index === 1}
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        setSelectedColor('one');
                      }}
                    >
                      <img
                        src={'../../../../content/assets/intervention-logo.png'}
                        alt="Intervention Logo"
                        style={{ height: '17px', marginRight: 10 }}
                      />
                      <ListItemContent
                        data-session-type={NMS_TYPES.INTERVENTION}
                        data-index={1}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                        sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}
                      >
                        <span
                          style={{ color: 'black' }}
                          data-session-type={NMS_TYPES.INTERVENTION}
                          data-index={1}
                          data-session-status={NMS_STATUS.IN_PROGRESS}
                        >
                          Interventions
                        </span>
                      </ListItemContent>
                      <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        {sessionCounts?.interventionCount}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ) : null}
                {pharmacistType === 'follow_up' || pharmacistType === 'both' ? (
                  <ListItem
                    onClick={handleSessionListing}
                    data-session-status={NMS_STATUS.IN_PROGRESS}
                    data-session-type={NMS_TYPES.FOLLOW_UP}
                  >
                    <ListItemButton
                      data-session-type={NMS_TYPES.FOLLOW_UP}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      data-index={2}
                      selected={index === 2}
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        setSelectedColor('one');
                      }}
                    >
                      <img
                        src={'../../../../content/assets/follow-up-icon.png'}
                        alt="Follow-up Logo"
                        style={{ height: '17px', marginRight: 10 }}
                      />
                      <ListItemContent sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}>
                        <span
                          style={{ color: 'black' }}
                          data-session-type={NMS_TYPES.FOLLOW_UP}
                          data-index={2}
                          data-session-status={NMS_STATUS.IN_PROGRESS}
                        >
                          Follow Ups
                        </span>
                      </ListItemContent>
                      <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        {sessionCounts?.followUpCount}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ) : null}
              </List>
            </Popover>
            {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
              <>
                <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_CONSENT}>
                  <ListItemButton
                    data-index={5}
                    data-session-status={NMS_STATUS.NO_CONSENT}
                    selected={index === 5}
                    style={{ backgroundColor: selectedColor === 'five' ? '#7CB6F8' : 'transparent', borderRadius: 25 }}
                    onClick={() => {
                      setSelectedColor('five');
                    }}
                  >
                    <img
                      data-index={5}
                      data-session-status={NMS_STATUS.NO_CONSENT}
                      src={'../../../../content/assets/no-svgrepo-com.png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                    />
                    <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>
                      {notConsentCount}
                    </Typography>
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_REACHABLE}>
                  <ListItemButton
                    data-index={6}
                    data-session-status={NMS_STATUS.NO_REACHABLE}
                    selected={index === 6}
                    style={{ backgroundColor: selectedColor === 'six' ? '#7CB6F8' : 'transparent', borderRadius: 25 }}
                    onClick={() => {
                      setSelectedColor('six');
                    }}
                  >
                    <img
                      data-index={6}
                      data-session-status={NMS_STATUS.NO_REACHABLE}
                      src={'../../../../content/assets/phone-xmark-alt-svgrepo-com.png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                    />
                    <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>
                      {notReachableCount}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </>
            ) : null}
          </List>
        </Grid>
      )}
    </Grid>
  );
};
