import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isValidDate, s2ab } from 'app/shared/util/utits';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import {
  assignPharmacy,
  deletePharmacyById,
  deleteUserwithRoles,
  downloadAllPharmacies,
  getAllServicePharmacists,
  getPharmacies,
  listAllPharmacy,
  getGroupNames,
  updatePharmacy,
} from '../admin-reducer';
import { IAssignPharmacy, IListingPharmacy, IListingPharmacist } from '../interface';

const intialState: IListingPharmacy = {
  size: 20,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
  group: '',
};

const intialStatePharmacist: IListingPharmacist = {
  size: 20,
  page: 0,
  searchQuery: '',
  from: '',
  to: '',
  group: '',
};

const initialState = {
  userId: 0,
};

const useManagePharmacy = () => {
  const pageNo = useAppSelector(state => state.admin.pharmacistPage);
  const limit = useAppSelector(state => state.admin.pharmacistRows);
  const totalCount = useAppSelector(state => state.admin.pharmacistCount);
  const allServicePharmacist = useAppSelector(state => state.admin.listAllServicePharmacist);

  const [pharmacistId, setPharmacistId] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  const allPharmacies = useAppSelector(state => state.admin.allListPharmacies);
  const allPharmacy = useAppSelector(state => state.admin.listPharmacy);
  const allGroupNames = useAppSelector(state => state.admin.allGroupNames);

  const [searchCreteria, setsearchCreteria] = useState<IListingPharmacist>(intialState);
  const [open, setOpen] = useState(false);
  const [pharmacyIdToDeleted, setPharmacyIdToBeDeleted] = useState();
  const [pharmaciesGroupDialog, setPharmaciesGroupDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [selectedPharmacyIds, setSelectedPharmacyIds] = useState([]);
  const [selectedPharmacyData, setSelectedPharmacyData] = useState([]);
  const [openGroups, setOpenGroups] = useState([]);
  const [assignedPharmacies, setAssignedPharmacies] = useState(new Set());
  const [enrichedDataSource, setEnrichedDataSource] = useState(null);
  const [selectedPharmacies, setSelectedPharmacies] = useState({});

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emptyRowCount = 10;
  const emptyCellCount = 7;

  useEffect(() => {
    const data = allServicePharmacist.map(pharmacist => {
      if (pharmacist.pharmacies) {
        const pharma = pharmacist.pharmacies.map(pharmacy => ({
          pharmacy: pharmacy.pharmacy,
        }));
        return {
          id: pharmacist.id,
          firstName: pharmacist.internalUser.firstName,
          gphcNumber: pharmacist.gphcNumber,
          contact_number: pharmacist.contact_number,
          pharmacies: pharma,
          internalUser: pharmacist.internalUser,
          asset: pharmacist.pharmacies[0]?.sessionType.name,
        };
      }
    });
    setEnrichedDataSource(data);
    setSelectedPharmacies({});
  }, [allServicePharmacist]);

  const filteredPharmacies = allPharmacies?.filter(pharmacy => pharmacy.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const filteredGroups2 = allGroupNames.filter(group => group.name.toLowerCase().includes(searchTerm.toLowerCase()));

  useEffect(() => {
    const params = {
      ...intialStatePharmacist,
    };
    dispatch(getAllServicePharmacists(params));
  }, []);

  const validationSchema = () => {
    return yup.object().shape({
      userId: yup.number().required('userID is a required field.').moreThan(0, 'Please Choose Pharmacy'),
    });
  };

  const setPharmacyIdAndOpenStatus = useCallback(id => {
    setPharmacyIdToBeDeleted(id);
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedPharmacyData([]);
    setSelectedPharmacyIds([]);
    setAssignedPharmacies(new Set());
    const params = {
      ...intialStatePharmacist,
    };
    dispatch(getAllServicePharmacists(params));
  };

  const onSubmit = useCallback(
    async pharmacy => {
      if (!pharmacy.interventions?.selected && !pharmacy.followup?.selected) {
        toast.error('Select Asset to Assign');
      } else {
        const data = {
          pharmacistId: pharmacistId, // Use the relevant pharmacist ID
          pharmacy: {
            pharmacyId: pharmacy.id,
            pendingIntervention: pharmacy.interventions.selected,
            pendingFollowUp: pharmacy.followup.selected,
          },
        };

        try {
          const res = await dispatch(assignPharmacy(data));

          if (res.type === 'manage_pharmacy/assign_pharmacy/fulfilled') {
            toast.success('Pharmacy assigned successfully');
            setEnrichedDataSource(null);
            const params = {
              ...intialStatePharmacist,
            };
            await dispatch(getAllServicePharmacists(params));
            setAssignedPharmacies(prev => new Set(prev).add(pharmacy.id)); // Mark as assigned
          } else {
            toast.error('Failed to assign pharmacy');
          }
        } catch (error) {
          console.error('Error assigning pharmacy:', error);
          toast.error('An error occurred. Please try again.');
        }
      }
    },
    [searchCreteria, pharmacistId] // Include necessary dependencies
  );

  const handlePharmacy = useCallback(
    (pharmacy: number) => {
      setPharmacistId(pharmacy);
      setModalOpen(true);
      const params = {
        ...intialStatePharmacist,
      };
      if (allServicePharmacist.length <= 0) {
        dispatch(getAllServicePharmacists(params));
      }
    },
    [allServicePharmacist]
  );
  const handleEdit = useCallback(
    item => {
      setPharmacistId(item.id);
      const pharmacyIds = item.pharmacies.map(pharmacy => pharmacy.pharmacy.id);

      setSelectedPharmacyIds(pharmacyIds);
      setAssignedPharmacies(new Set(pharmacyIds));

      const selectedData = allPharmacies
        .filter(pharmacy => pharmacyIds.includes(pharmacy.id))
        .map(pharmacy => {
          const pharmacist = allServicePharmacist.find(p => p.id === item.id);
          if (!pharmacist) {
            return null;
          }

          const sessionType = pharmacist.pharmacies.find(pharmacyEntry => pharmacyEntry.pharmacy.id === pharmacy.id)?.sessionType?.name;

          let interventionsSelected = false;
          let followUpSelected = false;

          if (sessionType === 'both') {
            interventionsSelected = true;
            followUpSelected = true;
          } else if (sessionType === 'intervention') {
            interventionsSelected = true;
          } else if (sessionType === 'follow_up') {
            followUpSelected = true;
          }

          return {
            id: pharmacy.id,
            name: pharmacy.name,
            target: pharmacy.target,
            interventions: { count: pharmacy.pendingInterventions, selected: interventionsSelected },
            followup: { count: pharmacy.pendingFollowUps, selected: followUpSelected },
            completedInterventions: pharmacy.completedInterventions,
          };
        })
        .filter(Boolean);
      setSelectedPharmacyData(selectedData);
      setModalOpen(true);
    },
    [allPharmacies, allServicePharmacist]
  );

  const handleEditSubmit = useCallback(
    async pharmacy => {
      const data = {
        pharmacistId: pharmacistId, // Use the relevant pharmacist ID
        pharmacy: {
          pharmacyId: pharmacy.id,
          pendingIntervention: pharmacy.interventions.selected,
          pendingFollowUp: pharmacy.followup.selected,
        },
      };
      try {
        const res = await dispatch(updatePharmacy(data));
        if (res.type === 'manage_pharmacy/update_pharmacy/fulfilled') {
          toast.success('Pharmacy Updated successfully');
          setEnrichedDataSource(null);
          const params = {
            ...intialStatePharmacist,
          };
          await dispatch(getAllServicePharmacists(params));
        } else {
          toast.error('Failed to Update pharmacy');
        }
      } catch (error) {
        toast.error('An error occurred. Please try again.');
      }
    },
    [searchCreteria, pharmacistId] // Include necessary dependencies
  );

  const handleOkay = () => {
    const selectedPharmacyDataMap = selectedPharmacyData.reduce((map, pharmacy) => {
      map[pharmacy.id] = pharmacy;
      return map;
    }, {});
    const updatedSelectedData = allPharmacies
      .filter(pharmacy => selectedPharmacyIds.includes(pharmacy.id))
      .map(pharmacy => {
        const existingPharmacy = selectedPharmacyDataMap[pharmacy.id];
        if (existingPharmacy) {
          return {
            ...existingPharmacy,
            interventions: {
              ...existingPharmacy.interventions,
              count: pharmacy.pendingInterventions,
            },
            followup: {
              ...existingPharmacy.followup,
              count: pharmacy.pendingFollowUps,
            },
            completedInterventions: pharmacy.completedInterventions,
          };
        } else {
          return {
            id: pharmacy.id,
            name: pharmacy.name,
            target: pharmacy.target,
            interventions: { count: pharmacy.pendingInterventions, selected: false },
            followup: { count: pharmacy.pendingFollowUps, selected: false },
            completedInterventions: pharmacy.completedInterventions,
          };
        }
      });
    const finalSelectedDataMap = updatedSelectedData.reduce((map, pharmacy) => {
      map[pharmacy.id] = pharmacy;
      return map;
    }, {});
    const remainingSelectedPharmacies = selectedPharmacyData.filter(pharmacy => selectedPharmacyIds.includes(pharmacy.id));
    const finalSelectedData = [
      ...new Map([...Object.values(finalSelectedDataMap), ...remainingSelectedPharmacies].map(item => [item.id, item])).values(),
    ];
    setSelectedPharmacyData(finalSelectedData);
    setPharmaciesGroupDialog(false);
  };

  const handlePharmacySelection = (selectedPharmacy, item) => {
    const pharmacyId = parseInt(selectedPharmacy, 10);
    const pharmacist = allServicePharmacist.find(pharmacist => pharmacist.id === item.id);
    const selectedPharmacyDetails = pharmacist.pharmacies.find(pharmacyEntry => pharmacyEntry.pharmacy.id === pharmacyId);
    setSelectedPharmacies(prev => ({
      ...prev,
      [item.id]: pharmacyId,
    }));
    if (!selectedPharmacyDetails) {
      console.log('Selected pharmacy details not found.');
      return;
    }

    setEnrichedDataSource(prevData =>
      prevData.map(pharmacistData => {
        if (pharmacistData.id === pharmacist.id) {
          return {
            ...pharmacistData,
            asset: selectedPharmacyDetails.sessionType.name,
          };
        }
        return pharmacistData;
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    const params = {
      ...searchCreteria,
      page: newPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: newPage,
    });
    setEnrichedDataSource(null);
    dispatch(getAllServicePharmacists(params));
  };

  const handleChangeRowsPerPage = event => {
    const pagePerRow = event.target.value;
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    setEnrichedDataSource(null);
    dispatch(getAllServicePharmacists(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };

    dispatch(getAllServicePharmacists(params));
  };

  const debouncedSearch = debounce(handleSearch, 3000);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllServicePharmacists(params));
  };
  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllServicePharmacists(params));
  };

  const handleRole = event => {
    let value = event.target.value;
    value = value === 'All Groups' ? '' : value;
    const params = {
      ...searchCreteria,
      group: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      group: value,
    });
    dispatch(getAllServicePharmacists(params));
  };

  const handleRefresh = useCallback(() => {
    const params = {
      ...intialStatePharmacist,
    };

    setsearchCreteria({
      ...intialStatePharmacist,
      from: '',
      to: '',
      searchQuery: '',
    });
    setEnrichedDataSource(null);
    dispatch(getAllServicePharmacists(params));
  }, []);

  const downloadPharmacyInExcelFile = useCallback(async () => {
    const res: any = await dispatch(downloadAllPharmacies(searchCreteria));

    if (res.type === 'manage_pharmacy/download_all_pharmacy/fulfilled') {
      const rolesRecord = res.payload.data.results;
      const data = rolesRecord.map(elem => {
        const { appUsers } = elem;
        const user = appUsers?.length ? appUsers.filter(appUser => appUser?.internalUser?.authorities?.[0]?.name !== 'CLIENT') : [];
        console.log({ user });
        const userName = user?.length ? user[0]?.appUser?.internalUser?.firstName : 'Not Assigned Yet';
        const userEmail = user?.length ? user[0]?.appUser?.internalUser?.email : 'Not Assigned Yet';

        return {
          'Pharmacy Name': elem.name,
          'Gphc Number': elem.gphc_umber,
          'Pharmacy Contact No.': elem.contact_number,
          'Assigned To (Name)': userName,
          'Assigned To (Email)': userEmail,
        };
      });

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef]?.v?.toString() ?? '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'pharmacies');

      // Generate XLSX data in binary form
      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary data to a Blob
      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link for the Excel file
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Pharmacy List.xlsx'; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria]);

  const fetchAllPharmacies = useCallback(() => {
    dispatch(getPharmacies());
  }, []);

  const listAllRolesWithDateRange = useCallback(() => {
    setEnrichedDataSource(null);
    dispatch(getAllServicePharmacists(searchCreteria));
    dispatch(getGroupNames());
  }, []);

  useEffect(() => {
    fetchAllPharmacies();
    listAllRolesWithDateRange();
  }, []);

  const handleOpenPharmaciesGroupDialog = () => setPharmaciesGroupDialog(true);
  const handleClosePharmaciesGroupDialog = () => {
    setPharmaciesGroupDialog(false);
  };
  const handleTabChangePharmaciesGroupDialog = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChangePharmaciesGroupDialog = e => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = id => {
    handleClose();
  };

  const handleSelectPharmacy = id => {
    setSelectedPharmacyIds(prev => (prev.includes(id) ? prev.filter(pharmacyId => pharmacyId !== id) : [...prev, id]));
  };

  const handleToggleGroup = groupId => {
    setOpenGroups(prev => (prev.includes(groupId) ? prev.filter(id => id !== groupId) : [...prev, groupId]));
  };

  const areAllPharmaciesSelectedInGroup = groupId => {
    const groupPharmacies = filteredGroups2.find(group => group.id === groupId)?.pharmacy || [];
    return groupPharmacies.every(pharmacy => selectedPharmacyIds.includes(pharmacy.id));
  };

  const states = {
    pageNo,
    limit,
    totalCount,
    // allListPharmacy,
    allPharmacies,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    open,
    pharmacyIdToDeleted,
    modalOpen,
    allServicePharmacist,
    initialState,
    validationSchema,
    allGroupNames,
    pharmaciesGroupDialog,
    searchTerm,
    tabValue,
    selectedPharmacyIds,
    selectedPharmacyData,
    openGroups,
    enrichedDataSource,
    filteredPharmacies,
    filteredGroups2,
    assignedPharmacies,
    selectedPharmacies,
  };
  const handlers = {
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    downloadPharmacyInExcelFile,
    handleClose,
    setOpen,
    setPharmacyIdAndOpenStatus,
    handleEdit,
    handlePharmacy,
    handleModalClose,
    onSubmit,
    handleOpenPharmaciesGroupDialog,
    handleClosePharmaciesGroupDialog,
    handleTabChangePharmaciesGroupDialog,
    handleSearchChangePharmaciesGroupDialog,
    handleSelect,
    handleSelectPharmacy,
    handleToggleGroup,
    handleOkay,
    areAllPharmaciesSelectedInGroup,
    setSelectedPharmacyData,
    setSelectedPharmacyIds,
    handlePharmacySelection,
    handleEditSubmit,
  };

  return {
    states,
    handlers,
  };
};

export default useManagePharmacy;
