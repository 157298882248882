import React from 'react';
import useListingTable from 'app/modules/deo-work-flow/hooks/useListingTable';
import moment from 'moment/moment';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Chip,
  OutlinedInput,
  InputLabel,
  TablePagination,
  Tooltip,
  Box,
  CircularProgress,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DailySummaryHook from 'app/modules/deo-work-flow/hooks/dailySummaryHook';
import { useAppSelector } from 'app/config/store';
import { useState } from 'react';
import { generalMonthArray } from 'app/shared/util/utits';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import './daily-summary.scss';
export const DailySummary = props => {
  const { states, handlers } = DailySummaryHook();
  const {
    allPharmacies,
    allPharmaciesOfUser,
    allServicesPharmacist,
    selectedPharmacies,
    searchCreteria,
    filteredPharmacies,
    allDeoSessionStatsCurrentDate,
    allGroupNames,
    checkedTabs,
  } = states;
  const {
    handleSelectService,
    handleSelectPharmacy,
    handleSelectBranch,
    handleExcelFileStats,
    handleRefresh,
    handleSelectMonth,
    handledateFrom,
    handledateTill,
    handleTabClick,
  } = handlers;

  const role = localStorage.getItem('role');
  const loading = useAppSelector(state => state.deo.unSubmitRecordDeleteLoading);
  const loadingStats = useAppSelector(state => state.deo.loading);
  const navigate = useNavigate();

  const formatDate = date => {
    if (date) {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment().format('DD-MM-YYYY');
    }
  };
  const gridData = [
    {
      icon: '../../../../content/assets/completed-nms.png',
      heading: 'Completed NMS',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_nms
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/completed-interventions.png',
      heading: 'Completed Interventions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_intervention
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/completed-follow-up.png',
      heading: 'Completed Follow-ups',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_up
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/pending-interventions.png',
      heading: 'Pending Interventions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].intervention_pending
        : 0,
    },
    {
      icon: '../../../../content/assets/pending-follow-up.png',
      heading: 'Pending Follow-ups',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].follow_up_pending
        : 0,
    },
    {
      icon: '../../../../content/assets/intervention-not-delivered.png',
      heading: 'Intervention Not Delivered',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].intervention_not_delivered
        : 0,
    },
    {
      icon: '../../../../content/assets/no-answer.png',
      heading: 'No Answers',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].no_answers : 0,
    },
    {
      icon: '../../../../content/assets/no-consents.png',
      heading: 'No Consents',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].no_consents : 0,
    },
    {
      icon: '../../../../content/assets/not-new-medicine.png',
      heading: 'Not New Medicine',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].not_new_medicine
        : 0,
    },
    {
      icon: '../../../../content/assets/not-eligible-conditions.png',
      heading: 'Not Eligible Conditions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].not_eligible
        : 0,
    },
    {
      icon: '../../../../content/assets/patient-left-the-pharmacy.png',
      heading: 'Patient Left Pharmacy',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].patient_left
        : 0,
    },
    {
      icon: '../../../../content/assets/incorrect-number.png',
      heading: 'Incorrect Number',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].incorrect_number
        : 0,
    },
    // {
    //   icon: '../../../../content/assets/Total Roles.png',
    //   heading: 'InEligible',
    //   heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].in_eligible : 0,
    // },
  ];
  const handleFileUpload = () => {
    navigate('/operator/dashboard/session-deo-file-uploaded');
  };
  const renderFilter = () => {
    switch (role) {
      case '"MANAGER_DEO"':
      case '"LEAD_DEO"':
      case '"DEO"':
        return (
          <>
            <Grid
              container
              style={{
                width: '100%',
                backgroundColor: '#F5F6FA',
                marginTop: 35,
                padding: '10px 20px',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              {/* Today's Date with Icon */}
              <Grid item lg={3} xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <CalendarTodayIcon style={{ fontSize: 20, color: '#7CB6F8', marginRight: 8 }} />
                <h1 style={{ fontSize: 16, color: '#333', margin: 0 }}>{'Today - ' + moment().format('DD MMMM, YYYY')}</h1>
              </Grid>

              {/* Spacer */}
              <Grid item lg={6} xs={6} />

              {/* Buttons Section */}
              <Grid
                item
                lg={3}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: 10,
                }}
              >
                {/* Refresh Button */}
                <Tooltip title="Refresh">
                  <IconButton
                    onClick={handleRefresh}
                    style={{
                      backgroundColor: '#7CB6F8',
                      height: 40,
                      width: 40,
                      padding: '8px',
                      borderRadius: 8,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <RefreshIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                {/* Download Button */}
                <Tooltip title="Download Stats">
                  <IconButton
                    onClick={handleExcelFileStats}
                    style={{
                      backgroundColor: '#7CB6F8',
                      height: 40,
                      width: 40,
                      padding: '8px',
                      borderRadius: 8,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <CloudDownloadIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '20px' }}>
              {/* All Groups Filter */}
              <Grid item style={{ marginRight: 10 }}>
                <Select
                  id="demo-simple-select"
                  value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
                  onChange={handleSelectBranch}
                  className={'mui-field-design-filter'}
                >
                  <MenuItem value={'All Groups'}>All Groups</MenuItem>
                  {allGroupNames.map(elem => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {/* All Pharmacies Filter */}
              <Grid item style={{ marginRight: 10 }}>
                <Select
                  id="demo-simple-select"
                  value={searchCreteria.pharmacy === '' ? ['All Pharmacies'] : [Number(searchCreteria.pharmacy)]}
                  onChange={handleSelectPharmacy}
                  renderValue={selected => {
                    if (selected[0] === 'All Pharmacies') {
                      return 'All Pharmacies';
                    } else {
                      const selectedPharmacy = filteredPharmacies.find(pharmacy => pharmacy.id === selected[0]);
                      return selectedPharmacy ? selectedPharmacy.name : '';
                    }
                  }}
                  className={'clock mui-field-design-filter'}
                >
                  <MenuItem value={['All Pharmacies']}>All Pharmacies</MenuItem>
                  {filteredPharmacies.map(elem => (
                    <MenuItem key={elem.id} value={[elem.id]}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item style={{ marginRight: 10 }}>
                <Select
                  id="demo-simple-select"
                  value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
                  onChange={handleSelectService}
                  className={'clock mui-field-design-filter'}
                >
                  <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
                  {allServicesPharmacist.map(elem => {
                    return (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.firstName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item style={{ marginRight: 10 }}>
                <Tooltip title="Date From">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      className="clock mui-field-design-filter"
                      format="DD/MM/YYYY"
                      value={searchCreteria.from === '' ? null : searchCreteria.from}
                      onChange={newValue => handledateFrom(newValue)}
                    />
                  </LocalizationProvider>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Date Till">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      value={searchCreteria.to === '' ? null : searchCreteria.to}
                      className="clock mui-field-design-filter"
                      format="DD/MM/YYYY"
                      onChange={newValue => handledateTill(newValue)}
                    />
                  </LocalizationProvider>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        );
      case '"ROLE_ADMIN"':
        return (
          <>
            <Grid
              container
              style={{
                width: '100%',
                backgroundColor: '#F5F6FA',
                marginTop: 35,
                padding: '10px 20px',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              {/* Today's Date with Icon */}
              <Grid item lg={3} xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <CalendarTodayIcon style={{ fontSize: 20, color: '#7CB6F8', marginRight: 8 }} />
                <h1 style={{ fontSize: 16, color: '#333', margin: 0 }}>{'Today - ' + moment().format('DD MMMM, YYYY')}</h1>
              </Grid>

              {/* Spacer */}
              <Grid item lg={6} xs={6} />

              {/* Buttons Section */}
              <Grid
                item
                lg={3}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: 10,
                }}
              >
                {/* Refresh Button */}
                <Tooltip title="Refresh">
                  <IconButton
                    onClick={handleRefresh}
                    style={{
                      backgroundColor: '#7CB6F8',
                      height: 40,
                      width: 40,
                      padding: '8px',
                      borderRadius: 8,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <RefreshIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                {/* Download Button */}
                <Tooltip title="Download Stats">
                  <IconButton
                    onClick={handleExcelFileStats}
                    style={{
                      backgroundColor: '#7CB6F8',
                      height: 40,
                      width: 40,
                      padding: '8px',
                      borderRadius: 8,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <CloudDownloadIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '20px' }}>
              <Grid item style={{ marginRight: 10 }}>
                <Select
                  className={'mui-field-design-filter'}
                  id="demo-simple-select"
                  value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
                  onChange={handleSelectBranch}
                >
                  <MenuItem value={'All Groups'}>All Groups</MenuItem>
                  {allGroupNames.map(elem => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item style={{ marginRight: 10 }}>
                <Select
                  className={'mui-field-design-filter'}
                  multiple
                  value={selectedPharmacies}
                  onChange={handleSelectPharmacy}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return 'All Pharmacies';
                    }
                    const selectedPharmacyNames = selected.map(id => {
                      const pharmacy = allPharmacies.find(pharmacy => pharmacy.id === id);
                      return pharmacy ? pharmacy.name : '';
                    });
                    return (
                      <div>
                        {selectedPharmacyNames.map(name => (
                          <Chip key={name} label={name} size="small" />
                        ))}
                      </div>
                    );
                  }}
                >
                  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
                  {filteredPharmacies.map(elem => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item style={{ marginRight: 10 }}>
                <Select
                  className={'selectFieldCss mui-field-design-filter'}
                  id="demo-simple-select"
                  value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
                  onChange={handleSelectService}
                >
                  <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
                  {allServicesPharmacist.map(elem => {
                    return (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.firstName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item style={{ marginRight: 10 }}>
                <Select
                  className={'mui-field-design-filter'}
                  id="demo-simple-select"
                  value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                  onChange={handleSelectMonth}
                >
                  {generalMonthArray.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item style={{ marginRight: 10 }}>
                <Tooltip title="Date From">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      className="clock mui-field-design-filter"
                      format="DD/MM/YYYY"
                      value={searchCreteria.from === '' ? null : searchCreteria.from}
                      onChange={newValue => handledateFrom(newValue)}
                    />
                  </LocalizationProvider>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Date Till">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      value={searchCreteria.to === '' ? null : searchCreteria.to}
                      className="clock mui-field-design-filter"
                      format="DD/MM/YYYY"
                      onChange={newValue => handledateTill(newValue)}
                    />
                  </LocalizationProvider>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        );
      case '"CLIENT"':
        return (
          <>
            <Grid
              container
              style={{
                width: '100%',
                backgroundColor: '#F5F6FA',
                marginTop: 35,
                padding: '10px 20px',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              {/* Today's Date with Icon */}
              <Grid item lg={3} xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <CalendarTodayIcon style={{ fontSize: 20, color: '#7CB6F8', marginRight: 8 }} />
                <h1 style={{ fontSize: 16, color: '#333', margin: 0 }}>{'Today - ' + moment().format('DD MMMM, YYYY')}</h1>
              </Grid>

              {/* Spacer */}
              <Grid item lg={6} xs={6} />

              {/* Buttons Section */}
              <Grid
                item
                lg={3}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: 10,
                }}
              >
                {/* Refresh Button */}
                <Tooltip title="Refresh">
                  <IconButton
                    onClick={handleRefresh}
                    style={{
                      backgroundColor: '#7CB6F8',
                      height: 40,
                      width: 40,
                      padding: '8px',
                      borderRadius: 8,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <RefreshIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                {/* Download Button */}
                <Tooltip title="Download Stats">
                  <IconButton
                    onClick={handleExcelFileStats}
                    style={{
                      backgroundColor: '#7CB6F8',
                      height: 40,
                      width: 40,
                      padding: '8px',
                      borderRadius: 8,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <CloudDownloadIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            {/* Filters Section */}
            <Grid container style={{ marginTop: 20, gap: '10px', alignItems: 'center' }}>
              {/* Pharmacies Select */}
              <Grid item>
                <Select
                  className={'mui-field-design-filter'}
                  multiple
                  value={selectedPharmacies}
                  onChange={handleSelectPharmacy}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return 'All Pharmacies';
                    }
                    const selectedPharmacyNames = selected.map(id => {
                      const pharma = allPharmaciesOfUser.find(pharm => pharm.id === id);
                      return pharma ? pharma.name : '';
                    });
                    return (
                      <div>
                        {selectedPharmacyNames.map(name => (
                          <Chip key={name} label={name} size="small" style={{ marginRight: 5 }} />
                        ))}
                      </div>
                    );
                  }}
                >
                  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
                  {allPharmaciesOfUser.map(elem => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {/* Month Select */}
              <Grid item>
                <Select
                  id="demo-simple-select"
                  value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                  onChange={handleSelectMonth}
                  className={'mui-field-design-filter'}
                >
                  {generalMonthArray.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </>

          // <>
          //   <Grid lg={12} container style={{ width: '100%', backgroundColor: '#F5F6FA', marginTop: 35 }}>
          //     <Grid item lg={3} xs={3} style={{ display: 'flex', marginTop: 10 }}>
          //       <h1 style={{ fontSize: 14, color: 'black' }}>{'Today - ' + moment().format('DD MMMM, YYYY')}</h1>
          //     </Grid>
          //
          //     <Grid item lg={6} xs={6} style={{ display: 'flex' }} />
          //
          //     <Grid item lg={3} xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 30 }}>
          //       <Tooltip title="Refresh">
          //         <IconButton
          //           onClick={handleRefresh}
          //           style={{
          //             backgroundColor: '#7CB6F8',
          //             height: 34,
          //             width: 35,
          //             padding: '0px',
          //             justifyContent: 'center',
          //             borderRadius: 5,
          //             cursor: 'pointer',
          //             transition: 'background-color 0.3s',
          //           }}
          //         >
          //           <img
          //             src={'../../../../content/assets/refresh.png'}
          //             alt="logo"
          //             style={{ height: '17px', marginRight: 0, marginLeft: 0 }}
          //           />
          //         </IconButton>
          //       </Tooltip>
          //       <Tooltip title="Download Today Stats">
          //         <IconButton
          //           onClick={() => handleExcelFileStats()}
          //           style={{
          //             backgroundColor: '#7CB6F8',
          //             marginLeft: 5,
          //             height: 34,
          //             width: 35,
          //             justifyContent: 'center',
          //             borderRadius: 5,
          //             cursor: 'pointer',
          //             transition: 'background-color 0.3s',
          //           }}
          //         >
          //           <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginLeft: '1px' }} />
          //         </IconButton>
          //       </Tooltip>
          //     </Grid>
          //   </Grid>
          //
          //   <Grid container style={{ marginTop: '20px' }}>
          //     <Grid item style={{ marginRight: 10 }}>
          //       <Select
          //         multiple
          //         value={selectedPharmacies}
          //         onChange={handleSelectPharmacy}
          //         displayEmpty
          //         renderValue={selected => {
          //           if (selected.length === 0) {
          //             return 'All Pharmacies';
          //           }
          //           const selectedPharmacyNames = selected.map(id => {
          //             const pharma = allPharmaciesOfUser.find(pharm => pharm.id === id);
          //             return pharma ? pharma.name : '';
          //           });
          //           return (
          //             <div>
          //               {selectedPharmacyNames.map(name => (
          //                 <Chip key={name} label={name} size="small" />
          //               ))}
          //             </div>
          //           );
          //         }}
          //         style={{
          //           width: '200px',
          //           height: '100%',
          //           fontSize: '12px',
          //           backgroundColor: '#EFEFEF',
          //           color: '#393939',
          //         }}
          //       >
          //         <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
          //         {allPharmaciesOfUser.map(elem => (
          //           <MenuItem key={elem.id} value={elem.id}>
          //             {elem.name}
          //           </MenuItem>
          //         ))}
          //       </Select>
          //     </Grid>
          //
          //     <Grid item>
          //       <Select
          //         id="demo-simple-select"
          //         value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
          //         onChange={handleSelectMonth}
          //         style={{
          //           width: '200px',
          //           height: '100%',
          //           fontSize: '12px',
          //           backgroundColor: '#EFEFEF',
          //           color: '#393939',
          //         }}
          //       >
          //         {generalMonthArray.map((month, index) => (
          //           <MenuItem key={index} value={month}>
          //             {month}
          //           </MenuItem>
          //         ))}
          //       </Select>
          //     </Grid>
          //   </Grid>
          // </>
        );
      default:
        null;
    }
  };
  const commonFields = () => {
    return (
      <>
        <Tooltip title="Refresh">
          <IconButton
            onClick={handleRefresh}
            style={{
              backgroundColor: '#7CB6F8',
              height: 34,
              width: 35,
              padding: '0px',
              justifyContent: 'center',
              borderRadius: 5,
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Today Stats">
          <IconButton
            onClick={() => handleExcelFileStats()}
            style={{
              backgroundColor: '#7CB6F8',
              marginLeft: 5,
              height: 34,
              width: 35,
              justifyContent: 'center',
              borderRadius: 5,
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginLeft: '1px' }} />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  return (
    <Grid lg={9.7} item style={{ width: '100%', padding: '0 5%', backgroundColor: '#F5F6FA', marginTop: '0.4%' }}>
      {renderFilter()}

      {/*<Grid lg={12} container style={{ width: '100%', backgroundColor: '#F5F6FA', marginTop: 35 }}>*/}
      {/*  <Grid item lg={3} xs={3} style={{ display: 'flex', marginTop: 10 }}>*/}
      {/*    <h1 style={{ fontSize: 14, color: 'black' }}>{'Today - ' + moment().format('DD MMMM, YYYY')}</h1>*/}
      {/*  </Grid>*/}
      {/*  <Grid item lg={6} xs={6} style={{ display: 'flex' }} />*/}
      {/*  <Grid item lg={3} xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 30 }}>*/}
      {/*    {commonFields()}*/}
      {/*    {(role === '"MANAGER_DEO"' || role === '"LEAD_DEO"' || role === '"DEO"') && (*/}
      {/*      <Tooltip title="Upload">*/}
      {/*        <IconButton*/}
      {/*          onClick={handleFileUpload}*/}
      {/*          style={{*/}
      {/*            backgroundColor: '#21AD64',*/}
      {/*            height: 34,*/}
      {/*            width: 100,*/}
      {/*            padding: '0px',*/}
      {/*            display: 'flex',*/}
      {/*            borderRadius: 5,*/}
      {/*            cursor: 'pointer',*/}
      {/*            transition: 'background-color 0.3s',*/}
      {/*            marginLeft: 10, // Add margin here*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <h1 style={{ fontSize: 12, color: 'white', marginTop: 10 }}>Upload</h1>*/}
      {/*        </IconButton>*/}
      {/*      </Tooltip>*/}
      {/*    )}*/}
      {/*  </Grid>*/}
      {/*  <Grid lg={12} xs={12} style={{ display: 'flex', height: '9%' }}>*/}
      {/*    <Select*/}
      {/*      id="demo-simple-select"*/}
      {/*      value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}*/}
      {/*      onChange={handleSelectBranch}*/}
      {/*      style={{*/}
      {/*        width: '20%',*/}
      {/*        height: '80%',*/}
      {/*        fontSize: '12px',*/}
      {/*        backgroundColor: '#EFEFEF',*/}
      {/*        color: '#393939',*/}
      {/*        marginRight: '10px',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <MenuItem value={'All Groups'}>All Groups</MenuItem>*/}
      {/*      {allGroupNames.map(elem => (*/}
      {/*        <MenuItem key={elem.id} value={elem.id}>*/}
      {/*          {elem.name}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </Select>*/}

      {/*    {(role === '"MANAGER_DEO"' || role === '"LEAD_DEO"' || role === '"DEO"') && (*/}
      {/*      <Select*/}
      {/*        id="demo-simple-select"*/}
      {/*        value={searchCreteria.pharmacy === '' ? ['All Pharmacies'] : [Number(searchCreteria.pharmacy)]}*/}
      {/*        onChange={handleSelectPharmacy}*/}
      {/*        renderValue={selected => {*/}
      {/*          if (selected[0] === 'All Pharmacies') {*/}
      {/*            return 'All Pharmacies';*/}
      {/*          } else {*/}
      {/*            const selectedPharmacy = filteredPharmacies.find(pharmacy => pharmacy.id === selected[0]);*/}
      {/*            return selectedPharmacy ? selectedPharmacy.name : '';*/}
      {/*          }*/}
      {/*        }}*/}
      {/*        style={{*/}
      {/*          width: '20%',*/}
      {/*          height: '80%',*/}
      {/*          fontSize: '12px',*/}
      {/*          backgroundColor: '#EFEFEF',*/}
      {/*          color: '#393939',*/}
      {/*          marginRight: '10px',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <MenuItem value={['All Pharmacies']}>All Pharmacies</MenuItem>*/}
      {/*        {filteredPharmacies.map(elem => (*/}
      {/*          <MenuItem key={elem.id} value={[elem.id]}>*/}
      {/*            {elem.name}*/}
      {/*          </MenuItem>*/}
      {/*        ))}*/}
      {/*      </Select>*/}
      {/*    )}*/}

      {/*    <Select*/}
      {/*      id="demo-simple-select"*/}
      {/*      value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}*/}
      {/*      onChange={handleSelectService}*/}
      {/*      style={{*/}
      {/*        width: '21%',*/}
      {/*        height: '80%',*/}
      {/*        fontSize: '12px',*/}
      {/*        backgroundColor: '#EFEFEF',*/}
      {/*        color: '#393939',*/}
      {/*        marginRight: '10px',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>*/}
      {/*      {allServicesPharmacist.map(elem => {*/}
      {/*        return (*/}
      {/*          <MenuItem key={elem.id} value={elem.id}>*/}
      {/*            {elem.firstName}*/}
      {/*          </MenuItem>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </Select>*/}
      {/*    {role === '"ROLE_ADMIN"' && (*/}
      {/*      <>*/}
      {/*        <Select*/}
      {/*          id="demo-simple-select"*/}
      {/*          value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}*/}
      {/*          onChange={handleSelectMonth}*/}
      {/*          style={{*/}
      {/*            width: '21%',*/}
      {/*            height: '80%',*/}
      {/*            fontSize: '12px',*/}
      {/*            backgroundColor: '#EFEFEF',*/}
      {/*            color: '#393939',*/}
      {/*            marginRight: '10px',*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {generalMonthArray.map((month, index) => (*/}
      {/*            <MenuItem key={index} value={month}>*/}
      {/*              {month}*/}
      {/*            </MenuItem>*/}
      {/*          ))}*/}
      {/*        </Select>*/}
      {/*        <Tooltip title="Date From">*/}
      {/*          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">*/}
      {/*            <DatePicker*/}
      {/*              className="clock"*/}
      {/*              format="DD/MM/YYYY"*/}
      {/*              value={searchCreteria.from === '' ? null : searchCreteria.from}*/}
      {/*              onChange={newValue => handledateFrom(newValue)}*/}
      {/*              sx={{*/}
      {/*                width: '15%',*/}
      {/*                fontSize: '12px',*/}
      {/*                backgroundColor: '#EFEFEF',*/}
      {/*                color: '#393939',*/}
      {/*                marginRight: '10px',*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </LocalizationProvider>*/}
      {/*        </Tooltip>*/}
      {/*        <Tooltip title="Date Till">*/}
      {/*          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">*/}
      {/*            <DatePicker*/}
      {/*              value={searchCreteria.to === '' ? null : searchCreteria.to}*/}
      {/*              className="clock"*/}
      {/*              format="DD/MM/YYYY"*/}
      {/*              onChange={newValue => handledateTill(newValue)}*/}
      {/*              sx={{*/}
      {/*                width: '15%',*/}
      {/*                fontSize: '12px',*/}
      {/*                backgroundColor: '#EFEFEF',*/}
      {/*                color: '#393939',*/}
      {/*                marginRight: '10px',*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </LocalizationProvider>*/}
      {/*        </Tooltip>*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

      {/*<Grid lg={12} container style={{ width: '100%', backgroundColor: '#F5F6FA' }}>*/}
      {/*  {role === '"ROLE_ADMIN"' && (*/}
      {/*  <FormControl style={{ marginTop: '10px', width: '100%' }}>*/}
      {/*    <Select*/}
      {/*      multiple*/}
      {/*      value={selectedPharmacies}*/}
      {/*      onChange={handleSelectPharmacy}*/}
      {/*      displayEmpty*/}
      {/*      renderValue={selected => {*/}
      {/*        if (selected.length === 0) {*/}
      {/*          return 'All Pharmacies';*/}
      {/*        }*/}
      {/*        const selectedPharmacyNames = selected.map(id => {*/}
      {/*          const pharmacy = allPharmacies.find(pharmacy => pharmacy.id === id);*/}
      {/*          return pharmacy ? pharmacy.name : '';*/}
      {/*        });*/}
      {/*        return (*/}
      {/*          <div>*/}
      {/*            {selectedPharmacyNames.map(name => (*/}
      {/*              <Chip key={name} label={name} size="small" />*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*        );*/}
      {/*      }}*/}
      {/*      style={{*/}
      {/*        height: '100%',*/}
      {/*        width: '42%',*/}
      {/*        fontSize: '12px',*/}
      {/*        backgroundColor: '#EFEFEF',*/}
      {/*        color: '#393939',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>*/}
      {/*      {filteredPharmacies.map(elem => (*/}
      {/*        <MenuItem key={elem.id} value={elem.id}>*/}
      {/*          {elem.name}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </Select>*/}
      {/*  </FormControl>*/}
      {/*  )}*/}
      {/*</Grid>*/}

      <Grid className={'daily-overview-tile'} lg={12} xs={4} style={{ width: '100%', marginBottom: 10, backgroundColor: '#F5F6FA' }}>
        {gridData.map((item, index) =>
          index % 4 === 0 ? (
            <Grid container key={index} style={{ width: '100%', justifyContent: 'space-between' }}>
              {gridData.slice(index, index + 4).map((subItem, subIndex) => {
                const isChecked = checkedTabs.includes(subItem.heading);
                return (
                  <Grid
                    key={subIndex}
                    lg={2.7}
                    style={{
                      height: 140,
                      justifyContent: 'center',
                      background: isChecked
                        ? 'linear-gradient(135deg, #d6d6d6, #b0bec5)' // Selected gradient
                        : 'linear-gradient(135deg, #e3f2fd, #bbdefb)', // Default gradient
                      marginTop: 40,
                      cursor: 'pointer',
                      borderRadius: 8,
                      transition: 'all 0.3s ease', // Smooth hover and selection effect
                      boxShadow: isChecked
                        ? '0px 8px 16px rgba(0, 123, 255, 0.3)' // Selected shadow
                        : '0px 4px 8px rgba(0, 0, 0, 0.1)', // Default shadow
                      ...(subIndex !== 3 ? { marginRight: 20 } : {}),
                    }}
                    onClick={() => handleTabClick(subItem.heading)}
                    onMouseEnter={e => {
                      e.currentTarget.style.filter = 'brightness(1.1)'; // Hover brightness
                      e.currentTarget.style.boxShadow = '0px 8px 16px rgba(0, 0, 0, 0.2)'; // Hover shadow
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.filter = 'brightness(1)'; // Reset brightness
                      e.currentTarget.style.boxShadow = isChecked
                        ? '0px 8px 16px rgba(0, 123, 255, 0.3)' // Retain selected shadow
                        : '0px 4px 8px rgba(0, 0, 0, 0.1)'; // Reset shadow
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 100,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: 20,
                        }}
                      >
                        <img src={subItem.icon} alt="logo" style={{ height: '40px' }} />
                        <h1 style={{ fontSize: 13, color: '#707070', marginTop: 10 }}>{subItem.heading}</h1>
                        <h1 style={{ fontSize: 17, marginTop: 5 }}>{loadingStats ? <CircularProgress /> : subItem.heading2}</h1>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          ) : null
        )}
      </Grid>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 1300,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};
export default DailySummary;
