import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import '../admin-work-flow/rich-data-table/admin-dashboard.scss';
import Contractor from './contractorWorkflow';
import DailySummary from 'app/modules/deo-work-flow/deo-listing-component/daily-summary';

export const ContractorMenu = props => {
  const role = localStorage.getItem('role');
  const [showMenu, setShowMenu] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState('one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.authentication.account);
  const { state }: any = useLocation();

  useEffect(() => {
    if (state) {
      switch (state.selectedColor) {
        case 'one':
          setIndex(1);
          break;
        case 'two':
          setIndex(2);
          break;
        case 'three':
          setIndex(3);
          break;
        default:
          setIndex(1);
      }
      setSelectedColor(state.selectedColor);
      setIndex(state.index);
    }
  }, [state]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  const handleDashboardSwitch = () => {
    setShowMenu(!showMenu);
  };
  const handleTabClick = (color, idx) => {
    console.log({ color, idx });
    setSelectedColor(color);
    setIndex(idx);
  };

  return (
    <Grid lg={12} container style={{ height: '100%', position: 'fixed' }}>
      <Grid
        lg={showMenu ? 0.6 : 2.1}
        xs={12}
        sm={showMenu ? 1 : 3}
        container
        style={{
          backgroundColor: '#004CB2',
          justifyContent: 'center',
          transition: 'ease 0.4s',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <img
            src={'../../../content/logos/login-bg.png'} // Use the image URL from the array
            alt="logo"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: 0.2,
            }}
          />
        </div>

        {showMenu === false ? (
          <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%' }}>
            <Tooltip title={'Collapse'}>
              <img
                src={'../../../../content/assets/close-dashboard.png'}
                alt="Close Dashboard"
                onClick={handleDashboardSwitch}
                style={{
                  position: 'absolute', // Set absolute positioning
                  top: 5, // Adjust positioning for the top
                  right: 5, // Adjust positioning for the right
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer', // Change cursor on hover
                  transform: showMenu ? 'scaleX(-1)' : 'none', // Mirror invert the icon when showMenu is true
                  transition: 'transform 0.6s ease',
                  filter: 'invert(1) brightness(3)', // Invert colors and increase brightness
                }}
              />
            </Tooltip>
            <List
              aria-label="Sidebar"
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
                [`& .${listItemDecoratorClasses.root}`]: {
                  justifyContent: 'space-between',
                  pr: '18px',
                },
                '& [role="button"]': {
                  borderRadius: '0 2px 2px 0',
                },
                height: 300,
              }}
            >
              <ListItem
                onClick={() => {
                  handleTabClick('two', 2);
                }}
              >
                <ListItemButton
                  data-index={2}
                  selected={index === 2}
                  style={{ backgroundColor: selectedColor === 'two' ? '#99D7FF' : 'transparent', borderRadius: 25 }}
                  onClick={() => {
                    setSelectedColor('two');
                  }}
                >
                  <img
                    data-index={2}
                    src={'../../../../content/assets/in-progress-svgrepo-com.png'}
                    alt="logo"
                    style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={2}
                    sx={{
                      fontWeight: 600,
                      color: selectedColor === 'two' ? '#4877B1' : undefined,
                      fontSize: 14,
                      marginLeft: 0.7,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <span style={{ color: selectedColor === 'two' ? '#004cb2' : 'white' }} data-index={2}>
                      Summary
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  handleTabClick('three', 3);
                }}
              >
                <ListItemButton
                  data-index={3}
                  selected={index === 3}
                  style={{ backgroundColor: selectedColor === 'three' ? '#99D7FF' : 'transparent', borderRadius: 25 }}
                  onClick={() => {
                    setSelectedColor('three');
                  }}
                >
                  <img
                    data-index={3}
                    src={'../../../../content/assets/overview.png'}
                    alt="logo"
                    style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={3}
                    sx={{
                      fontWeight: 600,
                      color: selectedColor === 'three' ? '#4877B1' : undefined,
                      fontSize: 14,
                      marginLeft: 0.7,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <span style={{ color: selectedColor === 'three' ? '#004cb2' : 'white' }} data-index={3}>
                      Overview
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        ) : (
          <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%', backgroundColor: '' }}>
            <Tooltip title={'Expand'}>
              <img
                src={'../../../../content/assets/close-dashboard.png'}
                alt="Close Dashboard"
                onClick={handleDashboardSwitch}
                style={{
                  position: 'absolute', // Set absolute positioning
                  top: 5, // Adjust positioning for the top
                  right: 5, // Adjust positioning for the right
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer', // Change cursor on hover
                  transform: showMenu ? 'scaleX(-1)' : 'none', // Mirror invert the icon when showMenu is true
                  transition: 'transform 0.6s ease',
                  filter: 'invert(1) brightness(3)',
                }}
              />
            </Tooltip>
            <List
              aria-label="Sidebar"
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
                [`& .${listItemDecoratorClasses.root}`]: {
                  justifyContent: 'space-between',
                  pr: '18px',
                },
                '& [role="button"]': {
                  borderRadius: '0 2px 2px 0',
                },
                height: 300,
              }}
            >
              <ListItem
                onClick={() => {
                  handleTabClick('two', 2);
                }}
              >
                <ListItemButton
                  onClick={() => {
                    setSelectedColor('two');
                  }}
                  style={{
                    backgroundColor: selectedColor === 'two' ? '#7CB6F8' : 'transparent',
                    cursor: 'pointer',
                    borderRadius: 25,
                    height: 38,
                    // '&:hover': {
                    //   backgroundColor: '#7CB6F8',
                    // },
                  }} // Added cursor style for indicating clickability
                >
                  <img
                    data-index={2}
                    src={'../../../../content/assets/in-progress-svgrepo-com.png'}
                    alt="logo"
                    style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  handleTabClick('three', 3);
                }}
              >
                <ListItemButton
                  onClick={() => {
                    setSelectedColor('three');
                  }}
                  style={{
                    backgroundColor: selectedColor === 'three' ? '#7CB6F8' : 'transparent',
                    cursor: 'pointer',
                    borderRadius: 25,
                    height: 38,
                    // '&:hover': {
                    //   backgroundColor: '#7CB6F8',
                    // },
                  }} // Added cursor style for indicating clickability
                >
                  <img
                    src={'../../../../content/assets/overview.png'}
                    alt="logo"
                    style={{ height: '17px', marginLeft: 20, marginRight: 10 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        )}
      </Grid>
      {index === 2 && selectedColor === 'two' && (
        <>
          <DailySummary />
        </>
      )}
      {index === 3 && selectedColor === 'three' && (
        <>
          <Contractor showMenu={showMenu} />
        </>
      )}
    </Grid>
  );
};
export default ContractorMenu;
