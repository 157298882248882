import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './session-deo-blanksheet.scss';
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Chip,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  FormGroup,
  ListSubheader,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createPatientEntity,
  getDeoEntities,
  getDeoEntity,
  updatePatientEntity,
  updateMedicineEntity,
  getPharmacistAgreedActionReferBackTypes,
  getMedicineConditions,
  referGp,
} from 'app/entities/session/session.reducer';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import patientReportedIssueType, {
  getEntities as getPatientReportedIssueType,
} from 'app/entities/patient-reported-issue-type/patient-reported-issue-type.reducer';

import adviceToPatientType, {
  getEntities as adviceToPatientProvided,
} from 'app/entities/advice-to-patient-type/advice-to-patient-type.reducer';
import patientAgreedActionType, {
  getEntities as getPatientAgreedActionType,
} from 'app/entities/patient-agreed-action-type/patient-agreed-action-type.reducer';

import pharmacistAgreedActionType, {
  getEntities as getPharmacistAgreedActionType,
} from 'app/entities/pharmacist-agreed-action-type/pharmacist-agreed-action-type.reducer';
import sessionConsultationMethod, {
  getEntities as getConsultationMethod,
} from 'app/entities/session-consultation-method/session-consultation-method.reducer';

import healthyLivingAdviceType, {
  getEntities as getHealthyLivingAdvice,
} from 'app/entities/healthy-living-advice-type/healthy-living-advice-type.reducer';
import sessionPreviouslyDeliveredNoType, {
  getEntities as getPreviouslyDeliveredNoType,
} from 'app/entities/session-previously-delivered-no-type/session-previously-delivered-no-type.reducer';
import sessionMedicineUsedAsPrescribedNoType, {
  getEntities as getSessionMedicineUsedAsPrescribedNoType,
} from 'app/entities/session-medicine-used-as-prescribed-no-type/session-medicine-used-as-prescribed-no-type.reducer';
import { AUTHORITIES, NMS_STATUS, NMS_TYPES } from 'app/config/constants';
import moment from 'moment';
import { ethnicGroups } from 'app/shared/util/utits';
import { getEntities as getProvidedInformationType } from 'app/entities/patient-provided-information-type/patient-provided-information-type.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

interface IDeoSession {
  id: number | string | null;
  prescriptionMedicineId: number | string | null;
  fullName: any;
  dateofBirth: Date;
  nextSession: Date;
  address: any;
  telephone: any;
  secondary_telephone: any;
  comment: any;
  // gpName: any;
  // gpAddress: any;
  // gpEmail: any;
  // gpTelephone: any;
  nhsNumber: any;
  medicineArray: Array<any>;
}
interface IGPDetail {
  session_id: number | string;
  name: string;
  email: string;
  telephone: string;
  address: string;
  gPPracticeName: string;
  referDetail: string;
  referDetailComments: string;
  referDetailTypes: Array<any>;
}

interface MedicineCondition {
  id: number;
  value: string;
}

interface AdviceProvidedFollowUp {
  id: number;
  value: string;
}
interface MedicineValues {
  prescriptionMedicineId: number | null;
  actualFollowUpDate: string | null;
  actualFollowUpTime: string | null;
  followUpDate: string | null;
  followUpTime: string | null;
  followUpNote: string | null;
  interventionNote: string | null;
  lastModifiedDate: string | null;
  lastModifiedDateTime: string | null;
  medicineConditions: MedicineCondition[];
  adviceProvidedFollowUp?: AdviceProvidedFollowUp[];
  sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck?: number | null;
  sessionMedicineUsedAsPrescribedNoTypeFollowUp?: number | null;
  sessionMedicineUsedAsPrescribedNoTypeFollowUpValue?: string;
  pharmacistAgreedReferBackFollowUp?: string | null;
  pharmacistAgreedReferBackFollowUpId?: number | null;
  pharmacistAgreedReferBackRecordFollowUpId?: number | null;
  pharmacistAgreedReferBackFollowUpValue?: string | null;
  nextSession?: string | null;
  nextSessionTime?: string | null;
  medicineUsedAsPrescribedFollowUp?: boolean;
}

type adviceProvidedOtherIntervention = any | null;
type patientAgreedActionOther = any | null;
type agreedPharmacistActionOther = any | null;
type pharmacistAgreedReferBackOtherIntervention = any | null;
type healthyLivingAdviceOther = any | null;
type patientReportedIssuesOther = any | null;

interface IDeoMedicineSessionPayload {
  prescriptionMedicineId: number | null;
  id: number | string | null;
  actualFollowUpDate: string | null;
  actualFollowUpTime: string | null;
  followUpDate: string | null;
  followUpTime: string | null;

  lastModifiedDate: string | null;
  lastModifiedDateTime: string | null;
  nextSession: string | null;
  nextSessionTime: string | null;
}

const defaultMedicineValues: MedicineValues = {
  prescriptionMedicineId: null,
  actualFollowUpDate: '',
  actualFollowUpTime: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
  followUpDate: '',
  followUpTime: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
  followUpNote: null,
  interventionNote: null,
  lastModifiedDate: '',
  medicineConditions: null,
  lastModifiedDateTime: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
  adviceProvidedFollowUp: [],
  sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck: null,
  sessionMedicineUsedAsPrescribedNoTypeFollowUp: null,
  sessionMedicineUsedAsPrescribedNoTypeFollowUpValue: '',
  pharmacistAgreedReferBackFollowUp: null,
  pharmacistAgreedReferBackFollowUpId: null,
  nextSession: '',
  nextSessionTime: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
  medicineUsedAsPrescribedFollowUp: false,
  pharmacistAgreedReferBackRecordFollowUpId: null,
  pharmacistAgreedReferBackFollowUpValue: null,
};

export const BlankSheetFormComp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [inputText, setInputText] = useState('');
  const [chips, setChips] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState('Select Medicine');

  const [values, setValues] = useState({});
  const [medicineValues, setMedicineValues] = useState<MedicineValues>(defaultMedicineValues);
  const [gpValues, setGpValues] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [showPharmacistAgreedActionOther, setShowPharmacistAgreedActionOther] = useState(null);
  const [showPharmacistAgreedActionReferBack, setShowPharmacistAgreedActionReferBack] = useState(null);
  const [showPharmacistAgreedActionReferBackFollowUp, setShowPharmacistAgreedActionReferBackFollowUp] = useState(null);
  const [showPharmacistAgreedActionReferBackOtherIntervention, setShowPharmacistAgreedActionReferBackOtherIntervention] = useState(null);
  const [showPharmacistAgreedActionReferBackOtherFollowUp, setShowPharmacistAgreedActionReferBackOtherFollowUp] = useState(null);

  const [showIssueReportedByPatientOther, setShowIssueReportedByPatientOther] = useState(null);
  const [showInformationProvidedOther, setShowInformationProvidedOther] = useState(null);
  const [showAdviceProvidedOther, setShowAdviceProvidedOther] = useState(null);
  const [showAdviceProvidedFollowUp, setShowAdviceProvidedFollowUp] = useState(null);
  const [showAdviceProvidedFollowUpOther, setShowAdviceProvidedFollowUpOther] = useState(null);

  const [showHealthyLivingAdviceOther, setShowHealthyLivingAdviceOther] = useState(null);
  const [showInterventionDelivered, setShowInterventionDelivered] = useState(null);
  const [showInterventionDeliveredOther, setShowInterventionDeliveredOther] = useState(null);
  const [defaultChecked, setDefaultChecked] = useState(null);

  const [declinedPromptShow, setDeclinedPromptShow] = useState(false);
  const [showConditionsDropdown, setShowConditionsDropdown] = useState(null);
  const [sessionValuesMedicine, setSessionValuesMedicine] = useState({});
  const [sessionValues, setSessionValues] = useState({});

  const [showMedicineRelatedContent, setShowMedicineRelatedContent] = useState(null);
  const [showMedicineUsedAsPrescribedFollowUp, setShowMedicineUsedAsPrescribedFollowUp] = useState(null);
  const [showMedicinePrescribed, setShowMedicinePrescribed] = useState(null);
  const [adviceProvidedFollowUp, setAdviceProvidedFollowUp] = useState(false);

  const [selectMedicineConditions, setSelectMedicineConditions] = useState([]);
  const [medicineConditions, setMedicineConditions] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [nextSession, setNextSession] = useState(null);
  const [selectedMedicineIndex, setSelectedMedicineIndex] = useState(0);
  const [selectedMedicineKey, setSelectedMedicineKey] = useState(0);

  const [isEdit, setIsEdit] = useState(false);
  const [isCompleted, setIsCompleted] = useState(null);

  const [fieldErrorMedicine, setFieldErrorMedicine] = useState({
    medicineUsedAsPrescribed: true,
    patientReportsNotUsingMedicineAsPrescribed: true,
    issueReportedByPatient: true,
    sessionMedicineUsedAsPrescribedNoType: false,
    patientReportedIssues: true,
    patientReportedIssuesOther: false,
    adviceToPatient: true,
    medicineConditions: true,
    adviceToPatientOther: false,
    patientAgreedAction: true,
    patientAgreedActionOther: false,
    agreedPharmacistAction: true,
    agreedPharmacistActionOther: false,
    pharmacistAgreedReferBackIntervention: false,
    pharmacistAgreedReferBackOtherIntervention: false,
    pharmacistAgreedReferBackOtherFollowUp: false,
    healthyLivingAdvice: true,
    healthyLivingAdviceOther: false,
    newMedicine: true,
  });
  const { file_id } = useParams();
  const [fieldError, setFieldError] = useState({
    fullName: false,
    dateofBirth: false,
    nextSession: false,
    telephone: false,
    // secondary_telephone: false,
    nhsNumber: false,
    address: false,
    // gpName: false,
    // gpEmail: false,
    // gpEmail_helper: '',
    telephone_helper: '',
    // secondary_telephone_helper: '',
    nhsNumber_helper: '',
    // gpAddress: false,
    // gpTelephone: false,
    // gpTelephone_helper: '',
  });
  const deoSessionEntity = useAppSelector(state => state.session.deoSessionEntity);
  const previouslyDeliveredNoTypeEntities = useAppSelector(state => state.sessionPreviouslyDeliveredNoType.entities);
  const consultationMethodEntities = useAppSelector(state => state.sessionConsultationMethod.entities);
  const medicineConditionsList = useAppSelector(state => state.session.medicineConditionsList);
  const patientAgreedActionTypeEntities = useAppSelector(state => state.patientAgreedActionType.entities);
  const pharmacistAgreedActionTypeEntities = useAppSelector(state => state.pharmacistAgreedActionType.entities);

  const pharmacistReferBackTypes = useAppSelector(state => state.session.pharmacistReferBackTypes);
  const medicineUsedAsPrescribedNoTypeEntities = useAppSelector(state => state.sessionMedicineUsedAsPrescribedNoType.entities);
  const adviceToPatientTypeEntities = useAppSelector(state => state.adviceToPatientType.entities);
  const healthyLivingAdviceEntities = useAppSelector(state => state.healthyLivingAdviceType.entities);
  const patientReportedIssueTypeEntities = useAppSelector(state => state.patientReportedIssueType.entities);
  const patientProvideInformationTypeEntities = useAppSelector(state => state.patientProvidedInformationType.entities);
  const role =
    localStorage.getItem('role') === null || localStorage.getItem('role') === '' || localStorage.getItem('role') === undefined
      ? ''
      : JSON.parse(localStorage.getItem('role'));
  const isAdmin =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN])) && role === 'ROLE_ADMIN';

  const [selectedOptionEthnic, setSelectedOptionEthnic] = useState('');
  const [showTextFieldEthnic, setShowTextFieldEthnic] = useState(false);
  const handleChangeEthnic = event => {
    const value = event.target.value;
    setSelectedOptionEthnic(value);
    setShowTextFieldEthnic(value === 'Any other ethnic group');
    setMedicineValues(oldValues => ({ ...oldValues, ['ethnicityOfPatient']: value }));
  };
  const handleChangeEthnicOther = event => {
    const value = event.target.value;
    setMedicineValues(oldValues => ({ ...oldValues, ['ethnicityOfPatientOther']: value }));
  };
  const defaultListingValues = {
    isValid: '',
    fileId: file_id !== undefined ? file_id : localStorage.getItem('fileId'),
    pageNo: 0,
    rows: 20,
    q: '',
  };
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      dispatch(getDeoEntity(id));
    }
  }, [id]);

  useEffect(() => {
    if (
      deoSessionEntity?.sessionStatus?.name === 'completed' ||
      (deoSessionEntity?.sessionStatus?.id === 1 && deoSessionEntity?.prevSessionType.id === 2)
    ) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [deoSessionEntity]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isEdit) {
      const medicineNames =
        deoSessionEntity.prescription &&
        deoSessionEntity.prescription.prescriptionMedicines.length > 0 &&
        deoSessionEntity.prescription.prescriptionMedicines.map(pm => pm.medicine.name);
      if (medicineNames && medicineNames.length > 0) {
        setChips(medicineNames);
      }
      const dateOfBirthString = deoSessionEntity.patient.dateofBirth;
      const nextSessionString = deoSessionEntity.nextSession;
      setDateOfBirth(dateOfBirthString);
      setNextSession(nextSessionString);
      setValues({
        id: deoSessionEntity && deoSessionEntity?.id,
        prescriptionMedicineId: deoSessionEntity && deoSessionEntity.prescription ? deoSessionEntity?.prescription?.id : '',
        fullName: deoSessionEntity && deoSessionEntity.patient ? deoSessionEntity.patient?.firstName : '',
        dateofBirth: deoSessionEntity && deoSessionEntity.patient ? dateOfBirthString : '',
        nextSession: deoSessionEntity && deoSessionEntity.nextSession ? nextSessionString : '',
        telephone: deoSessionEntity && deoSessionEntity.patient ? deoSessionEntity.patient.telephone : '',
        secondary_telephone: deoSessionEntity && deoSessionEntity.patient ? deoSessionEntity.patient.secondary_telephone : '',
        nhsNumber: deoSessionEntity && deoSessionEntity.patient ? deoSessionEntity.patient.nhsNumber : '',
        address: deoSessionEntity && deoSessionEntity.patient ? deoSessionEntity.patient.address : '',
        comment: deoSessionEntity ? deoSessionEntity.comment : '',
        // gpName: deoSessionEntity && deoSessionEntity.gP ? deoSessionEntity.gP.name : '',
        // gpEmail: deoSessionEntity && deoSessionEntity.gP ? deoSessionEntity.gP.email : '',
        // gpAddress: deoSessionEntity && deoSessionEntity.gP ? deoSessionEntity.gP.address : '',
        // gpTelephone: deoSessionEntity && deoSessionEntity.gP ? deoSessionEntity.gP.telephone : '',
        medicineArray: medicineNames.length > 0 ? medicineNames : [],
      });

      setFieldError({
        fullName: deoSessionEntity && deoSessionEntity.patient && deoSessionEntity.patient.firstName === '',
        dateofBirth:
          deoSessionEntity &&
          deoSessionEntity.patient &&
          deoSessionEntity.patient.dateofBirth === '' &&
          moment(deoSessionEntity.patient.dateofBirth, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
        nextSession: deoSessionEntity && deoSessionEntity.nextSession === '',
        telephone: deoSessionEntity && deoSessionEntity.patient && deoSessionEntity.patient.telephone === '',
        // secondary_telephone: deoSessionEntity && deoSessionEntity.patient && deoSessionEntity.patient.secondary_telephone === '',
        nhsNumber: deoSessionEntity && deoSessionEntity.patient && deoSessionEntity.patient.nhsNumber === '',
        address: deoSessionEntity && deoSessionEntity.patient && deoSessionEntity.patient.address === '',

        // gpName: deoSessionEntity && deoSessionEntity.gP && deoSessionEntity.gP.name === '',
        // gpEmail: deoSessionEntity && deoSessionEntity.gP && deoSessionEntity.gP.email === '',
        // gpEmail_helper: '',
        telephone_helper: '',
        // secondary_telephone_helper: '',
        nhsNumber_helper: '',
        // gpAddress: deoSessionEntity && deoSessionEntity.gP && deoSessionEntity.gP.address === '',
        // gpTelephone: deoSessionEntity && deoSessionEntity.gP && deoSessionEntity.gP.telephone === '',
        // gpTelephone_helper: '',
      });
      checkValidationOnLoad(
        deoSessionEntity.patient.telephone,
        deoSessionEntity.patient.secondary_telephone,
        deoSessionEntity.gP.telephone,
        deoSessionEntity.patient.nhsNumber,
        deoSessionEntity.gP.email,
        deoSessionEntity.patient.dateofBirth
      );
    }
  }, [deoSessionEntity]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    const patientDetailEntities = deoSessionEntity && deoSessionEntity?.patient;
    if (isEdit && isCompleted && deoSessionEntity) {
      setSelectedOptionEthnic(patientDetailEntities.ethnicity);
      if (patientDetailEntities.ethnicity === 'Any other ethnic group') {
        setShowTextFieldEthnic(patientDetailEntities.ethnicity === 'Any other ethnic group');
      } else {
        setShowTextFieldEthnic(false);
      }
      console.log({ deoSessionEntity });
      setMedicineValues(prevState => ({
        ...prevState,
        id: deoSessionEntity && deoSessionEntity?.id,
        sessionType: deoSessionEntity ? deoSessionEntity.prevSessionType?.id : null,
        scrAccess: deoSessionEntity ? deoSessionEntity?.scrAccess : '',
        previouslyDelivered: deoSessionEntity ? deoSessionEntity?.previouslyDelivered : '',
        followUpDeclined: deoSessionEntity ? deoSessionEntity?.followUpDeclinedByPatient : null,
        declinedReason: deoSessionEntity ? deoSessionEntity?.declinedReason : '',
        notDeclinedByPatient: deoSessionEntity?.followUpDeclinedByPatient === false ? deoSessionEntity?.notDeclinedReason : null,
        ethnicityOfPatient: patientDetailEntities.ethnicity,
        ethnicityOfPatientOther: patientDetailEntities.ethnicity === 'Any other ethnic group' ? patientDetailEntities.ethnicityOther : null,
        sessionPreviouslyDeliveredNoType: deoSessionEntity?.sessionPreviouslyDeliveredNoType?.id
          ? deoSessionEntity?.sessionPreviouslyDeliveredNoType?.id
          : null,
        sessionPatientPreviouslyDeliveredOtherDescription: deoSessionEntity?.sessionPatientPreviouslyDeliveredOtherDescription || '',
        lastModifiedDate: deoSessionEntity?.actualInterventionDate
          ? new Date(deoSessionEntity.actualInterventionDate).toISOString().split('T')[0]
          : '',
        lastModifiedDateTime: deoSessionEntity?.actualInterventionDate
          ? new Date(deoSessionEntity.actualInterventionDate).toLocaleTimeString('en-US', {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            })
          : null,

        nextSession: deoSessionEntity?.nextSession ? new Date(deoSessionEntity.nextSession).toISOString().split('T')[0] : '',
        nextSessionTime: deoSessionEntity?.nextSession
          ? new Date(deoSessionEntity.nextSession).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
          : null,
        methodOfConsultationIntervention: deoSessionEntity?.sessionPatientMethodOfConsultation
          ? deoSessionEntity.sessionPatientMethodOfConsultation.find(issue => issue.sessionType?.id === 1)?.sessionConsultationMethod?.id
          : null,
        methodOfConsultationInterventionValue: deoSessionEntity?.sessionPatientMethodOfConsultation
          ? deoSessionEntity.sessionPatientMethodOfConsultation.find(issue => issue.sessionType?.id === 1)?.sessionConsultationMethod?.name
          : null,

        methodOfConsultationFollowUp: deoSessionEntity?.sessionPatientMethodOfConsultation
          ? deoSessionEntity.sessionPatientMethodOfConsultation.find(issue => {
              return issue.sessionType?.id === 2;
            })?.sessionConsultationMethod?.id
          : null,
        methodOfConsultationFollowUpValue: deoSessionEntity?.sessionPatientMethodOfConsultation
          ? deoSessionEntity.sessionPatientMethodOfConsultation.find(issue => issue.sessionType?.id === 2)?.sessionConsultationMethod?.name
          : null,

        medicineConditions:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? [
                {
                  id:
                    deoSessionEntity.prescription.prescriptionMedicines[selectedMedicineIndex]?.sessionMedicineCondition?.medicineCondition
                      ?.id || null,
                  value:
                    deoSessionEntity.prescription.prescriptionMedicines[selectedMedicineIndex]?.sessionMedicineCondition?.medicineCondition
                      ?.name || null,
                },
              ]
            : [],

        medicineUsedAsPrescribed:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribed
            : null,
        medicineUsedAsPrescribedFollowUp:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribedFollowUp
            : '',
        sessionMedicineUsedAsPrescribedNoType:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribed === false
              ? deoSessionEntity?.prescription?.prescriptionMedicines[
                  selectedMedicineIndex
                ]?.sessionPatientMedicineUsedAsPrescribedNoType.find(issue => issue.sessionType?.id === 1)
                  ?.sessionMedicineUsedAsPrescribedNoType?.id
              : null
            : null,
        sessionMedicineUsedAsPrescribedNoTypeValue:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribed === false
              ? deoSessionEntity?.prescription?.prescriptionMedicines[
                  selectedMedicineIndex
                ]?.sessionPatientMedicineUsedAsPrescribedNoType.find(issue => issue.sessionType?.id === 1)
                  ?.sessionMedicineUsedAsPrescribedNoType?.name
              : ''
            : '',
        sessionMedicineUsedAsPrescribedNoTypeFollowUpValue:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribedFollowUp === false
              ? deoSessionEntity?.prescription?.prescriptionMedicines[
                  selectedMedicineIndex
                ]?.sessionPatientMedicineUsedAsPrescribedNoType.find(issue => issue.sessionType?.id === 2)
                  ?.sessionMedicineUsedAsPrescribedNoType?.name
              : ''
            : '',
        sessionMedicineUsedAsPrescribedNoTypeFollowUp:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribedFollowUp === false
              ? deoSessionEntity?.prescription?.prescriptionMedicines[
                  selectedMedicineIndex
                ]?.sessionPatientMedicineUsedAsPrescribedNoType.find(issue => issue.sessionType?.id === 2)
                  ?.sessionMedicineUsedAsPrescribedNoType?.id
              : null
            : null,

        sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck:
          selectedMedicineKey === deoSessionEntity.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.id.toString()
            ? deoSessionEntity?.prescription?.prescriptionMedicines[selectedMedicineIndex]?.medicineUsedAsPrescribedFollowUp === false
              ? deoSessionEntity?.prescription?.prescriptionMedicines[
                  selectedMedicineIndex
                ]?.sessionPatientMedicineUsedAsPrescribedNoType.find(issue => issue.sessionType?.id === 2)
                  ?.sessionMedicineUsedAsPrescribedNoType?.id
              : null
            : null,
        patientProvidedInformation: deoSessionEntity.sessionPatientProvidedInformations
          ? deoSessionEntity.sessionPatientProvidedInformations
              .filter(issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => ({
                id: issue.patientProvidedInformationType?.id,
                value: issue.patientProvidedInformationType?.name,
              }))
          : [],
        patientReportedIssues: deoSessionEntity.sessionPatientReportedIssues
          ? deoSessionEntity.sessionPatientReportedIssues
              .filter(issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => ({
                id: issue.patientReportedIssueType?.id,
                value: issue.patientReportedIssueType?.name,
              }))
          : [],
        patientReportedIssuesOther: deoSessionEntity.sessionPatientReportedIssues
          ? deoSessionEntity?.sessionPatientReportedIssues.find(
              issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey && issue.patientReportedIssueType?.name === 'Other'
            )?.description || ''
          : '',
        adviceProvidedIntervention: deoSessionEntity.sessionAdviceToPatients
          ? deoSessionEntity.sessionAdviceToPatients
              .filter(issue => issue.sessionType?.id === 1 && issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => ({
                id: issue.adviceToPatientType?.id,
                value: issue.adviceToPatientType?.name,
              }))
          : [],

        adviceProvidedFollowUp: deoSessionEntity.sessionAdviceToPatients
          ? deoSessionEntity?.sessionAdviceToPatients
              .filter(issue => issue.sessionType?.id === 2 && issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => ({
                id: issue.adviceToPatientType?.id,
                value: issue.adviceToPatientType?.name,
              }))
          : [],

        adviceProvidedOtherIntervention: deoSessionEntity.sessionAdviceToPatients
          ? deoSessionEntity?.sessionAdviceToPatients.find(
              issue =>
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.sessionType?.id === 1 &&
                issue.adviceToPatientType?.name === 'Other'
            )?.description
          : '',
        adviceProvidedOtherFollowUp: deoSessionEntity.sessionAdviceToPatients
          ? deoSessionEntity?.sessionAdviceToPatients.find(
              issue =>
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.sessionType?.id === 2 &&
                issue.adviceToPatientType?.name === 'Other'
            )?.description
          : '',
        patientAgreedAction: deoSessionEntity.sessionPatientAgreedActions
          ? deoSessionEntity?.sessionPatientAgreedActions
              .filter(issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => ({
                id: issue.patientAgreedActionType?.id,
                value: issue.patientAgreedActionType?.name,
              }))
          : [],
        patientAgreedActionOther: deoSessionEntity.sessionPatientAgreedActions
          ? deoSessionEntity.sessionPatientAgreedActions.find(
              issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey && issue.patientAgreedActionType?.name === 'Other'
            )?.description
          : '',
        agreedPharmacistAction: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity?.sessionPharmacistAgreedActions
              .filter(issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey && issue.sessionType?.id === 1)
              .map(issue => ({
                id: issue.pharmacistAgreedActionType?.id,
                value: issue.pharmacistAgreedActionType?.name,
              }))
          : [],
        agreedPharmacistActionOther: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity?.sessionPharmacistAgreedActions.find(
              issue =>
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey && issue.pharmacistAgreedActionType?.name === 'Other'
            )?.description || ''
          : '',

        pharmacistAgreedReferBackIntervention: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity?.sessionPharmacistAgreedActions.filter(
              issue =>
                issue.sessionType?.id === 1 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description === 'Referred back to the prescriber'
            )[0]?.sessionPharmacistAgreedActionReferBack[0]?.pharmacistAgreedActionReferBackPrescriberTypesEntity?.id || null
          : null,
        pharmacistAgreedReferBackInterventionId: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity?.sessionPharmacistAgreedActions.filter(
              issue =>
                issue.sessionType?.id === 1 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description === 'Referred back to the prescriber'
            )[0]?.sessionPharmacistAgreedActionReferBack[0]?.pharmacistAgreedActionReferBackPrescriberTypesEntity?.id || null
          : null,

        pharmacistAgreedReferBackRecordInterventionId: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity.sessionPharmacistAgreedActions.find(
              issue =>
                issue.sessionType?.id === 1 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description == 'Referred back to the prescriber'
            )?.id || null
          : null,
        pharmacistAgreedReferBackRecordFollowUpId: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity.sessionPharmacistAgreedActions.find(
              issue =>
                issue.sessionType?.id === 2 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description == 'Referred back to the prescriber'
            )?.id || null
          : null,

        pharmacistAgreedReferBackInterventionValue: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity.sessionPharmacistAgreedActions.filter(
              issue =>
                issue.sessionType?.id === 1 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description === 'Referred back to the prescriber'
            )[0]?.sessionPharmacistAgreedActionReferBack[0]?.pharmacistAgreedActionReferBackPrescriberTypesEntity?.name || null
          : null,

        pharmacistAgreedReferBackFollowUp: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity.sessionPharmacistAgreedActions
              .filter(issue => issue.sessionType?.id === 2 && issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => issue.sessionPharmacistAgreedActionReferBack[0]?.pharmacistAgreedActionReferBackPrescriberTypesEntity?.id)[0] ||
            null
          : null,

        pharmacistAgreedReferBackFollowUpValue: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity.sessionPharmacistAgreedActions.filter(
              issue =>
                issue.sessionType?.id === 2 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description === 'Referred back to the prescriber'
            )[0]?.sessionPharmacistAgreedActionReferBack[0]?.pharmacistAgreedActionReferBackPrescriberTypesEntity?.name || null
          : null,

        pharmacistAgreedReferBackFollowUpId: deoSessionEntity.sessionPharmacistAgreedActions
          ? deoSessionEntity?.sessionPharmacistAgreedActions.filter(
              issue =>
                issue.sessionType?.id === 2 &&
                issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                issue.description === 'Referred back to the prescriber'
            )[0]?.sessionPharmacistAgreedActionReferBack[0]?.pharmacistAgreedActionReferBackPrescriberTypesEntity?.id || null
          : null,
        pharmacistAgreedReferBackOtherIntervention: (() => {
          const action = deoSessionEntity.sessionPharmacistAgreedActions
            ? deoSessionEntity.sessionPharmacistAgreedActions.find(
                issue =>
                  issue.sessionType?.id === 1 &&
                  issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                  issue.description === 'Referred back to the prescriber'
              )
            : null;
          if (action) {
            const firstIssue = action.sessionPharmacistAgreedActionReferBack?.[0];
            if (firstIssue && firstIssue.pharmacistAgreedActionReferBackPrescriberTypesEntity?.name === 'Other') {
              return firstIssue?.description;
            }
          }
          return null;
        })(),

        pharmacistAgreedReferBackOtherFollowUp: (() => {
          const action = deoSessionEntity.sessionPharmacistAgreedActions
            ? deoSessionEntity.sessionPharmacistAgreedActions.find(
                issue =>
                  issue.sessionType?.id === 2 &&
                  issue.prescriptionMedicine?.id.toString() === selectedMedicineKey &&
                  issue.description === 'Referred back to the prescriber'
              )
            : null;
          if (action) {
            const firstIssue = action.sessionPharmacistAgreedActionReferBack?.[0];
            if (firstIssue && firstIssue.pharmacistAgreedActionReferBackPrescriberTypesEntity?.name === 'Other') {
              return firstIssue?.description;
            }
          }
          return null;
        })(),

        healthyLivingAdvice: deoSessionEntity?.sessionHealthyLivingAdvices
          ? deoSessionEntity?.sessionHealthyLivingAdvices
              .filter(issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey)
              .map(issue => ({
                id: issue.healthyLivingAdviceType?.id,
                value: issue.healthyLivingAdviceType?.name,
              }))
          : [],
        healthyLivingAdviceOther: deoSessionEntity?.sessionHealthyLivingAdvices
          ? deoSessionEntity?.sessionHealthyLivingAdvices.find(
              issue => issue.prescriptionMedicine?.id.toString() === selectedMedicineKey && issue.healthyLivingAdviceType?.name === 'Other'
            )?.description
          : '',
        actualFollowUpDate: deoSessionEntity?.actualFollowUpdDate
          ? new Date(deoSessionEntity.actualFollowUpdDate).toISOString().split('T')[0]
          : '',
        actualFollowUpTime: deoSessionEntity?.actualFollowUpdDate
          ? new Date(deoSessionEntity.actualFollowUpdDate).toLocaleTimeString('en-US', {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            })
          : null,
        followUpDate: deoSessionEntity?.followUpdDate ? new Date(deoSessionEntity.followUpdDate).toISOString().split('T')[0] : '',
        followUpTime: deoSessionEntity?.followUpdDate
          ? new Date(deoSessionEntity.followUpdDate).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
          : null,

        interventionNote: deoSessionEntity?.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.interventionNote || '',
        followUpNote: deoSessionEntity?.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.description || '',
      }));
    }
    if (isEdit && isCompleted && patientDetailEntities) {
      setGpValues(prevState => ({
        ...prevState,
        name: deoSessionEntity.gP?.name || '',
        email: deoSessionEntity.gP?.email || '',
        address: deoSessionEntity.gP?.address || '',
        telephone: deoSessionEntity.gP?.telephone || '',
        gPPracticeName: deoSessionEntity.gP?.gPPracticeName || '',
        session_id: id,
      }));
    }
  }, [isEdit, isCompleted, deoSessionEntity, selectedMedicineIndex, selectedMedicineKey]);

  const checkValidationOnLoad = (telephone, secondary_telephone, gpTelephone, nhsNumber, email, dateofbirth) => {
    if (telephone === null || telephone === undefined || telephone === '') {
      setFieldError(oldValues => ({ ...oldValues, ['telephone']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`telephone_helper`]: '' }));
    } else if (telephone.replace(/\s/g, '').length !== 11) {
      setFieldError(oldValues => ({ ...oldValues, ['telephone']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`telephone_helper`]: 'Telephone must be of 11 digits.' }));
    } else if (!/^[0-9\s]+$/.test(telephone)) {
      setFieldError(oldValues => ({ ...oldValues, ['telephone']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`telephone_helper`]: 'Telephone cannot contain alphabetic characters.' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, ['telephone']: false }));
      setFieldError(oldValues => ({ ...oldValues, [`telephone_helper`]: '' }));
    }

    // if (secondary_telephone === null || secondary_telephone === undefined || secondary_telephone === '') {
    //   setFieldError(oldValues => ({ ...oldValues, ['secondary_telephone']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`secondary_telephone_helper`]: '' }));
    // } else if (telephone.replace(/\s/g, '').length !== 11) {
    //   setFieldError(oldValues => ({ ...oldValues, ['secondary_telephone']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`secondary_telephone_helper`]: 'Telephone must be of 11 digits.' }));
    // } else if (!/^[0-9\s]+$/.test(telephone)) {
    //   setFieldError(oldValues => ({ ...oldValues, ['secondary_telephone']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`secondary_telephone_helper`]: 'Telephone cannot contain alphabetic characters.' }));
    // } else {
    //   setFieldError(oldValues => ({ ...oldValues, ['secondary_telephone']: false }));
    //   setFieldError(oldValues => ({ ...oldValues, [`secondary_telephone_helper`]: '' }));
    // }
    // if (gpTelephone === null || gpTelephone === undefined || gpTelephone === '') {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpTelephone']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`gpTelephone_helper`]: '' }));
    // } else if (gpTelephone.replace(/\s/g, '').length !== 11) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpTelephone']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`gpTelephone_helper`]: 'Telephone must be of 11 digits.' }));
    // } else if (!/^[0-9\s]+$/.test(gpTelephone)) {
    //   setFieldError(oldValues => ({ ...oldValues, ['telephone']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`telephone_helper`]: 'Telephone cannot contain alphabetic characters.' }));
    // } else {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpTelephone']: false }));
    //   setFieldError(oldValues => ({ ...oldValues, [`gpTelephone_helper`]: '' }));
    // }

    const expression = /^\d{10}$/;
    const hasSpaces = /\s/.test(nhsNumber);
    if (nhsNumber === undefined || nhsNumber === '' || nhsNumber === null) {
      setFieldError(oldValues => ({ ...oldValues, ['nhsNumber']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`nhsNumber_helper`]: '' }));
    } else if (nhsNumber.toString().length !== 10) {
      setFieldError(oldValues => ({ ...oldValues, ['nhsNumber']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`nhsNumber_helper`]: 'NHS number must be of 10 digits.' }));
    } else if (!expression.test(nhsNumber.toString())) {
      setFieldError(oldValues => ({ ...oldValues, ['nhsNumber']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`nhsNumber_helper`]: 'Only numbers are allowed.' }));
    } else if (hasSpaces) {
      setFieldError(oldValues => ({ ...oldValues, ['nhsNumber']: true }));
      setFieldError(oldValues => ({ ...oldValues, [`nhsNumber_helper`]: 'NHS number cannot contain spaces.' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, ['nhsNumber']: false }));
      setFieldError(oldValues => ({ ...oldValues, [`nhsNumber_helper`]: '' }));
    }

    // const emailExpression = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
    // if (email === undefined || email === '' || email === null) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpEmail']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`"gpEmail_helper`]: '' }));
    // } else if (!emailExpression.test(email)) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpEmail']: true }));
    //   setFieldError(oldValues => ({ ...oldValues, [`gpEmail_helper`]: 'Invalid email address' }));
    // } else {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpEmail']: false }));
    //   setFieldError(oldValues => ({ ...oldValues, [`gpEmail_helper`]: '' }));
    // }

    if (
      dateofbirth === undefined ||
      dateofbirth === '' ||
      dateofbirth === null ||
      moment(deoSessionEntity.patient.dateofBirth, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      setFieldError(oldValues => ({ ...oldValues, ['dateofBirth']: true }));
    }
  };

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    // if (e.target.name === 'gpEmail') {
    //   checkEmailValidations(e);
    // } else
    if (e.target.name === 'telephone') {
      checkTelephoneValidations(e);
    } else if (e.target.name === 'secondary_telephone') {
      checkTelephoneValidations(e);
    } else if (e.target.name === 'nhsNumber') {
      checknhsNumberValidations(e);
    } else {
      checkSingleFieldValidations(e);
    }
  };

  const checkEmailValidations = e => {
    const expression = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    } else if (!expression.test(e.target.value)) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'Invalid email address' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    }
  };

  const checkTelephoneValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    } else if (e.target.value.replace(/\s/g, '').length !== 11) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'Telephone must be of 11 digits.' }));
    } else if (!/^[0-9\s]+$/.test(e.target.value)) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'Telephone cannot contain alphabetic characters.' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    }
  };

  const checknhsNumberValidations = e => {
    const expression = /^\d{10}$/;
    const hasSpaces = /\s/.test(e.target.value);
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    } else if (e.target.value.toString().length !== 10) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'NHS number must be of 10 digits.' }));
    } else if (!expression.test(e.target.value.toString())) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'Only numbers are allowed.' }));
    } else if (hasSpaces) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'NHS number cannot contain spaces.' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    }
  };

  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['fullName'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['fullName']: true }));
    }
    if (values['dateofBirth'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['dateofBirth']: true }));
    }
    if (values['nextSession'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['nextSession']: true }));
    }
    if (values['telephone'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['telephone']: true }));
    }
    // if (values['secondary_telephone'] === undefined) {
    //   setFieldError(oldValues => ({ ...oldValues, ['secondary_telephone']: true }));
    // }
    if (values['nhsNumber'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['nhsNumber']: true }));
    }
    if (values['address'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['address']: true }));
    }
    // if (values['gpName'] === undefined) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpName']: true }));
    // }
    // if (values['gpEmail'] === undefined) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpEmail']: true }));
    // }
    // if (values['gpAddress'] === undefined) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpAddress']: true }));
    // }
    // if (values['gpTelephone'] === undefined) {
    //   setFieldError(oldValues => ({ ...oldValues, ['gpTelephone']: true }));
    // }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['fullName'] &&
      !fieldError['dateofBirth'] &&
      !fieldError['telephone'] &&
      // !fieldError['secondary_telephone'] &&
      !fieldError['nhsNumber'] &&
      !fieldError['address'] &&
      // !fieldError['gpName'] &&
      // !fieldError['gpEmail'] &&
      // !fieldError['gpAddress'] &&
      // !fieldError['gpTelephone'] &&
      values['fullName'] !== undefined &&
      values['dateofBirth'] !== undefined &&
      values['nextSession'] !== undefined &&
      values['telephone'] !== undefined &&
      // values['secondary_telephone'] !== undefined &&
      values['nhsNumber'] !== undefined &&
      values['address'] !== undefined
      // values['gpName'] !== undefined &&
      // values['gpEmail'] !== undefined &&
      // values['gpAddress'] !== undefined &&
      // values['gpTelephone'] !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange2 = event => {
    setInputText(event.target.value);
  };

  const handleInputKeyPress = event => {
    if (event.key === 'Enter' && inputText.trim() !== '') {
      const newChips = [...chips, inputText];
      setChips(newChips);
      setInputText('');
      setValues(oldValues => ({ ...oldValues, medicineArray: newChips }));
    }
  };

  const handleChipDelete = chipToDelete => {
    // Remove the selected chip
    setChips(chips.filter(chip => chip !== chipToDelete));
    setValues(oldValues => ({ ...oldValues, medicineArray: chips.filter(chip => chip !== chipToDelete) }));
  };

  const handleDateOfBirth = e => {
    const d = dayjs(e);
    setDateOfBirth(e);
    setValues(oldValues => ({ ...oldValues, ['dateofBirth']: d }));
    if (values['dateofBirth'] !== null || values['dateofBirth'] !== undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['dateofBirth']: false }));
    }
  };
  const handleNextSessionDate = e => {
    const d = dayjs(e);
    setNextSession(e);
    setValues(oldValues => ({ ...oldValues, ['nextSession']: d }));
    if (values['nextSession'] !== null || values['nextSession'] !== undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['nextSession']: false }));
    }
  };
  const handleRedirect = () => {
    if (isAdmin) {
      navigate('/admin/dashboard', { state: { selectedColor: 'three', index: 3 } });
    } else {
      if (file_id !== undefined) {
        navigate('/operator/edit/file/' + file_id);
      } else if (localStorage.getItem('fileId')) {
        navigate('/operator/session-listing');
      } else {
        navigate('/operator/dashboard', { state: { activeItem: 'Overview' } });
      }
    }
  };

  const handleMedicinePrescribedFollowUp = e => {
    const { name, checked } = e.target;

    setMedicineValues(oldValues => {
      const updatedValues = { ...oldValues };

      if (name === 'medicineNotUsedAsPrescribedFollowUp') {
        updatedValues.sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck = checked ? 1 : null;
        setShowMedicineUsedAsPrescribedFollowUp(checked);

        if (!checked) {
          updatedValues.sessionMedicineUsedAsPrescribedNoTypeFollowUp = null;
          updatedValues.sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck = null;
          updatedValues.sessionMedicineUsedAsPrescribedNoTypeFollowUpValue = null;
          updatedValues.medicineUsedAsPrescribedFollowUp = true;
        }
      } else if (name === 'medicineUsedAsPrescribedFollowUp') {
        updatedValues.medicineUsedAsPrescribedFollowUp = checked;
        updatedValues.sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck = checked ? null : 1;
        updatedValues.sessionMedicineUsedAsPrescribedNoTypeFollowUp = null;
        setShowMedicineUsedAsPrescribedFollowUp(!checked);
      }

      return updatedValues;
    });
  };

  const clearValues = () => {
    setValues({
      fullName: '',
      address: '',
      telephone: '',
      secondary_telephone: '',
      comment: '',
      dateofBirth: new Date(),
      nextSession: new Date(),
      nhsNumber: '',
      // gpName: '',
      // gpTelephone: '',
      // gpEmail: '',
      // gpAddress: '',
      // gpTelephone_helper: '',
      telephone_helper: '',
      secondary_telephone_helper: '',
      nhsNumber_helper: '',
      // gpEmail_helper: '',
    });
    setFieldError({
      fullName: true,
      dateofBirth: true,
      nextSession: true,
      telephone: true,
      // secondary_telephone: true,
      nhsNumber: true,
      address: true,
      // gpName: true,
      // gpEmail: true,
      // gpEmail_helper: '',
      telephone_helper: '',
      // secondary_telephone_helper: '',
      nhsNumber_helper: '',
      // gpAddress: true,
      // gpTelephone: true,
      // gpTelephone_helper: '',
    });
    setChips([]);
  };
  const submitSection = () => {
    if (!isEdit && isAllowedToSubmit()) {
      const result = dispatch(createPatientEntity(values as IDeoSession))
        .then(res => {
          dispatch(getDeoEntities(defaultListingValues));
          if (res?.payload['status'] === 201) {
            toast.success('Patient created successfully.');
          }
        })
        .catch(error => {
          toast.error(error);
        });
      clearValues();
    } else if (isEdit && isAllowedToSubmit()) {
      const result = dispatch(updatePatientEntity(values as IDeoSession))
        .then(res => {
          if (res?.payload['status'] === 200) {
            toast.success('Patient updated successfully.');
            if (isAdmin) {
              navigate('/admin/dashboard', { state: { selectedColor: 'three', index: 3 } });
            } else {
              dispatch(getDeoEntities(defaultListingValues));
              if (file_id !== undefined) {
                navigate('/operator/edit/file/' + file_id);
              } else {
                navigate('/operator/session-listing', { state: { activeItem: 'Overview' } });
              }
            }
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  };

  // eslint-disable-next-line complexity
  const convertToPayload = (values: MedicineValues): IDeoMedicineSessionPayload => ({
    ...values,
    prescriptionMedicineId: values.prescriptionMedicineId || null,
    actualFollowUpDate: values.actualFollowUpDate ? new Date(values.actualFollowUpDate).toISOString().split('T')[0] : null,
    actualFollowUpTime: values.actualFollowUpTime ? values.actualFollowUpTime : null,
    followUpDate: values.followUpDate ? new Date(values.followUpDate).toISOString().split('T')[0] : null,
    followUpTime: values.followUpTime ? values.followUpTime : null,
    id: deoSessionEntity && deoSessionEntity?.id,

    lastModifiedDate: values.lastModifiedDate ? new Date(values.lastModifiedDate).toISOString().split('T')[0] : null,
    lastModifiedDateTime: values.lastModifiedDateTime ? values.lastModifiedDateTime : null,
    nextSession: values.nextSession ? new Date(values.nextSession).toISOString().split('T')[0] : null,
    nextSessionTime: values.nextSessionTime ? values.nextSessionTime : null,
  });

  const submitUpdateMedicineSection = () => {
    if (isEdit && isCompleted) {
      const convertedMedicineValues = convertToPayload(medicineValues);

      dispatch(updateMedicineEntity(convertedMedicineValues))
        .then(res => {
          if (res?.payload['status'] === 200) {
            toast.success('Session updated successfully.');
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  };

  useEffect(() => {
    setSessionValues(oldValues => ({ ...oldValues, session_id: id }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, session_id: id }));
  }, [id]);

  useEffect(() => {
    setMedicineValues(oldValues => ({ ...oldValues, ['scrAccess']: 'true' }));
    setMedicineValues(oldValues => ({ ...oldValues, ['previouslyDelivered']: 'true' }));
  }, []);

  useEffect(() => {
    dispatch(getPharmacistAgreedActionReferBackTypes({}));
    dispatch(getHealthyLivingAdvice({}));
    dispatch(getConsultationMethod({}));
    dispatch(getPreviouslyDeliveredNoType({}));
    dispatch(getSessionMedicineUsedAsPrescribedNoType({}));
    dispatch(getPatientReportedIssueType({}));
    dispatch(getProvidedInformationType({}));
    dispatch(adviceToPatientProvided({}));
    dispatch(getPatientAgreedActionType({}));
    dispatch(getPharmacistAgreedActionType({}));
    dispatch(getMedicineConditions());
  }, []);

  useEffect(() => {
    setShowInterventionDeliveredOther(
      deoSessionEntity?.sessionPreviouslyDeliveredNoType?.id && deoSessionEntity?.sessionPreviouslyDeliveredNoType?.id === 5
    );
  }, [deoSessionEntity]);

  useEffect(() => {
    setShowInterventionDelivered(deoSessionEntity?.previouslyDelivered === false);
  }, [deoSessionEntity]);

  useEffect(() => {
    if (medicineValues['medicineConditions'] !== undefined) {
      setShowConditionsDropdown(medicineValues['medicineConditions'] !== null);
    }
  }, [medicineValues['medicineConditions']]);

  useEffect(() => {
    if (medicineValues['patientReportedIssues']) {
      const hasOther = medicineValues['patientReportedIssues'].some(issue => issue.value === 'Other');
      setShowIssueReportedByPatientOther(hasOther);
    }
  }, [medicineValues['patientReportedIssues']]);

  useEffect(() => {
    if (medicineValues['patientProvidedInformation']) {
      const hasOther = medicineValues['patientProvidedInformation'].some(issue => issue.value === 'Other');
      setShowInformationProvidedOther(hasOther);
    }
  }, [medicineValues['patientProvidedInformation']]);

  useEffect(() => {
    if (medicineValues['adviceProvidedIntervention']) {
      const hasOther = medicineValues['adviceProvidedIntervention'].some(issue => issue.value === 'Other');
      setShowAdviceProvidedOther(hasOther);
    }
  }, [medicineValues['adviceProvidedIntervention']]);

  useEffect(() => {
    if (medicineValues['patientAgreedAction']) {
      const hasOther = medicineValues['patientAgreedAction'].some(issue => issue.value === 'Other');
      setShowOther(hasOther);
    }
  }, [medicineValues['patientAgreedAction']]);

  useEffect(() => {
    if (medicineValues['agreedPharmacistAction']) {
      const hasOther = medicineValues['agreedPharmacistAction'].some(issue => issue.value === 'Other');
      setShowPharmacistAgreedActionOther(hasOther);

      const hasReferBackOther = medicineValues['agreedPharmacistAction'].some(issue => issue.value === 'Referred back to the prescriber');
      setShowPharmacistAgreedActionReferBack(hasReferBackOther);
    }
  }, [medicineValues['agreedPharmacistAction']]);

  useEffect(() => {
    const pharmacistAgreedReferBackIntervention = medicineValues['pharmacistAgreedReferBackIntervention'];
    if (pharmacistAgreedReferBackIntervention !== null && pharmacistAgreedReferBackIntervention === 10) {
      setShowPharmacistAgreedActionReferBackOtherIntervention(true);
    } else {
      setShowPharmacistAgreedActionReferBackOtherIntervention(false);
    }
  }, [medicineValues['pharmacistAgreedReferBackIntervention']]);

  useEffect(() => {
    if (medicineValues['healthyLivingAdvice']) {
      const hasOther = medicineValues['healthyLivingAdvice'].some(issue => issue.value === 'Other');
      setShowHealthyLivingAdviceOther(hasOther);
    }
  }, [medicineValues['healthyLivingAdvice']]);

  useEffect(() => {
    if (medicineValues['medicineUsedAsPrescribedFollowUp'] === false) {
      setShowMedicineUsedAsPrescribedFollowUp(true);
    } else {
      setShowMedicineUsedAsPrescribedFollowUp(false);
    }
  }, [medicineValues['medicineUsedAsPrescribedFollowUp']]);

  useEffect(() => {
    const adviceFollowUp = medicineValues['adviceProvidedFollowUp'];
    setShowAdviceProvidedFollowUp(adviceFollowUp?.length > 0);
  }, [medicineValues['adviceProvidedFollowUp']]);

  useEffect(() => {
    if (medicineValues?.adviceProvidedFollowUp) {
      setShowAdviceProvidedFollowUpOther(medicineValues.adviceProvidedFollowUp.some(advice => advice.value === 'Other'));
    }
  }, [medicineValues?.adviceProvidedFollowUp]);

  useEffect(() => {
    if (medicineValues['pharmacistAgreedReferBackFollowUp'] === null) {
      setShowPharmacistAgreedActionReferBackFollowUp(false);
    } else if (medicineValues['pharmacistAgreedReferBackFollowUp'] !== null) {
      setShowPharmacistAgreedActionReferBackFollowUp(true);
    }
  }, [medicineValues['pharmacistAgreedReferBackFollowUp']]);

  useEffect(() => {
    const pharmacistAgreedReferBackFollowUp = medicineValues['pharmacistAgreedReferBackFollowUp'];
    if (pharmacistAgreedReferBackFollowUp !== null && pharmacistAgreedReferBackFollowUp.toString() === '10') {
      setShowPharmacistAgreedActionReferBackOtherFollowUp(true);
    } else {
      setShowPharmacistAgreedActionReferBackOtherFollowUp(false);
    }
  }, [medicineValues['pharmacistAgreedReferBackFollowUp']]);

  useEffect(() => {
    setShowMedicinePrescribed(
      deoSessionEntity?.prescription?.prescriptionMedicines?.[selectedMedicineIndex]?.medicineUsedAsPrescribed === false
    );
  }, [deoSessionEntity, selectedMedicineIndex]);

  useEffect(() => {
    if (medicineValues['followUpDeclined'] === true) {
      setDeclinedPromptShow(true);
    } else if (medicineValues['followUpDeclined'] === false) {
      setDeclinedPromptShow(false);
    }
  }, [medicineValues['followUpDeclined']]);

  useEffect(() => {
    if (pharmacistReferBackTypes && pharmacistReferBackTypes.length > 0) {
      const referBackOptionsArray = pharmacistReferBackTypes.map(label => label.name);
      setGpValues(oldValues => ({ ...oldValues, referDetailTypes: referBackOptionsArray }));
    }
  }, [pharmacistReferBackTypes]);

  useEffect(() => {
    if (medicineValues['pharmacistAgreedReferBackInterventionValue'] !== null) {
      setGpValues(oldValues => ({
        ...oldValues,
        ['referDetail']: medicineValues['pharmacistAgreedReferBackInterventionValue'],
        ['referDetailComments']: medicineValues['pharmacistAgreedReferBackOtherIntervention'],
      }));
    }
    if (
      medicineValues['pharmacistAgreedReferBackInterventionValue'] !== null &&
      medicineValues['pharmacistAgreedReferBackFollowUpValue'] !== null
    ) {
      setGpValues(oldValues => ({
        ...oldValues,
        ['referDetail']: medicineValues['pharmacistAgreedReferBackFollowUpValue'],
        ['referDetailComments']: medicineValues['pharmacistAgreedReferBackOtherFollowUp'],
      }));
    }
  }, [medicineValues, gpValues]);

  const checkSingleFieldValidationsMedicine = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const setFormValuesMedicine = e => {
    if (e.target.name === 'pharmacistAgreedReferBackOther') {
      setGpValues(oldValues => ({
        ...oldValues,
        ['referDetailComments']: e.target.value,
      }));
    }
    setMedicineValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const handleFollowUpDeclined = e => {
    setMedicineValues(oldValues => ({ ...oldValues, followUpDeclined: e.target.value }));
    if (e.target.value === 'true') {
      setDeclinedPromptShow(true);
    } else {
      setDeclinedPromptShow(false);
      setMedicineValues(oldValues => ({ ...oldValues, ['declinedReason']: '' }));
    }
  };

  const handleFollowUpDeclinedReason = e => {
    setMedicineValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };
  const handleNotDeclinedByPatient = e => {
    setMedicineValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const handleAdviceProvidedOtherFollowUp = e => {
    setMedicineValues(oldValues => ({ ...oldValues, adviceProvidedOtherFollowUp: e.target.value }));
  };

  const handleScrAccess = e => {
    setMedicineValues(oldValues => ({ ...oldValues, scrAccess: e.target.value }));
    setFormValues(e);
  };

  const handleInterventionDeliveredOptionChange = e => {
    const value = e.target.value;
    setMedicineValues(oldValues => ({ ...oldValues, previouslyDelivered: value }));

    setShowInterventionDelivered(value === 'false');

    if (value === 'true') {
      setMedicineValues(oldValues => ({ ...oldValues, sessionPreviouslyDeliveredNoType: null }));
      setMedicineValues(oldValues => ({ ...oldValues, sessionPatientPreviouslyDeliveredOtherDescription: null }));
      setShowInterventionDeliveredOther(false);
    }
  };

  const handleInterventionDeliveredNoType = e => {
    const value = e.target.value;
    setMedicineValues(oldValues => ({ ...oldValues, sessionPreviouslyDeliveredNoType: value }));

    setShowInterventionDeliveredOther(value == 6);
    if (value == 6) {
      setMedicineValues(oldValues => ({ ...oldValues, sessionPreviouslyDeliveredOther: null }));
    }
  };

  const handleInterventionDeliveredOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, sessionPatientPreviouslyDeliveredOtherDescription: e.target.value }));

    setFormValues(e);
  };

  const handleActualInterventionDate = date => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setMedicineValues(oldValues => ({
        ...oldValues,
        lastModifiedDate: formattedDate,
      }));
    }
  };

  const handleActualInterventionTime = time => {
    if (time) {
      const formattedTime = time.format('HH:mm');
      setMedicineValues(oldValues => ({
        ...oldValues,
        lastModifiedDateTime: formattedTime,
      }));
    }
  };

  const handleInterventionDate = date => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setMedicineValues(oldValues => ({
        ...oldValues,
        nextSession: formattedDate,
      }));
    }
  };

  const handleFollowUpDateUpdate = date => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setMedicineValues(oldValues => ({
        ...oldValues,
        followUpDate: formattedDate,
      }));
    }
  };

  const handleInterventionTime = time => {
    if (time) {
      const formattedTime = time.format('HH:mm');
      setMedicineValues(oldValues => ({
        ...oldValues,
        nextSessionTime: formattedTime,
      }));
    }
  };

  const handlemethodofConsultation = e => {
    const values = parseInt(e.target.value);
    const selectedLabel = e.target.closest('label').getAttribute('data-value');
    setMedicineValues(oldValues => ({ ...oldValues, methodOfConsultationIntervention: values }));
    setMedicineValues(oldValues => ({
      ...oldValues,
      methodOfConsultationInterventionValue: selectedLabel,
    }));
  };

  const handlemethodofConsultationFollowUp = e => {
    const values = parseInt(e.target.value);
    const selectedLabel = e.target.closest('label').getAttribute('data-value');
    setMedicineValues(oldValues => ({ ...oldValues, methodOfConsultationFollowUp: values }));
    setMedicineValues(oldValues => ({
      ...oldValues,
      methodOfConsultationFollowUpValue: selectedLabel,
    }));
  };

  const handleSelectNewMedicineCondition = e => {
    const [selectedConditionId, selectedConditionName] = e.target.value.split(':');
    const parsedConditionId = parseInt(selectedConditionId, 10);

    setMedicineValues(oldValues => ({
      ...oldValues,
      medicineConditions: [
        {
          id: parsedConditionId,
          value: selectedConditionName,
        },
      ],
    }));
  };

  const handlesetPatient_using_medicine_as_prescribed = e => {
    const newValue = e.target.value === 'true';

    if (newValue === false) {
      setShowMedicinePrescribed(true);
      setFieldErrorMedicine(oldValues => ({ ...oldValues, sessionMedicineUsedAsPrescribedNoType: true }));
    } else {
      setShowMedicinePrescribed(false);
      setMedicineValues(oldValues => ({ ...oldValues, sessionMedicineUsedAsPrescribedNoType: null }));
    }

    setMedicineValues(oldValues => ({ ...oldValues, medicineUsedAsPrescribed: newValue }));
  };

  const handlepatient_reports_not_using_the_medicine_as_prescribedoptions = e => {
    const values = parseInt(e.target.value);
    const selectedLabel = medicineUsedAsPrescribedNoTypeEntities.find(label => label.id === values).name;

    setMedicineValues(oldValues => ({ ...oldValues, sessionMedicineUsedAsPrescribedNoType: values }));
    setMedicineValues(oldValues => ({
      ...oldValues,
      sessionMedicineUsedAsPrescribedNoTypeValue: selectedLabel,
    }));
  };

  const handlemedicine_as_prescribedoptions = e => {
    const values = parseInt(e.target.value);
    const selectedLabel = medicineUsedAsPrescribedNoTypeEntities.find(label => label.id === values).name;

    setMedicineValues(oldValues => ({
      ...oldValues,
      sessionMedicineUsedAsPrescribedNoTypeFollowUp: values,
      sessionMedicineUsedAsPrescribedNoTypeFollowUpValue: selectedLabel,
      sessionMedicineUsedAsPrescribedNoTypeFollowUpCheck: values,
    }));
  };

  const handleIssueReportedByPatient = (e, label) => {
    const { checked } = e.target;
    let updatedIssues = [...medicineValues['patientReportedIssues']];

    if (checked) {
      updatedIssues.push({ id: label.id, value: label.name });
    } else {
      updatedIssues = updatedIssues.filter(issue => issue.id !== label.id);

      if (label.name === 'Other') {
        setMedicineValues(oldValues => ({
          ...oldValues,
          patientReportedIssuesOther: null,
        }));
      }
    }

    setMedicineValues(oldValues => {
      const newValues = {
        ...oldValues,
        patientReportedIssues: updatedIssues,
      };

      if (label.name === 'Other') {
        setShowIssueReportedByPatientOther(checked);
      }

      return newValues;
    });
  };

  const handleInformationProvided = (e, label) => {
    const { checked } = e.target;
    let updatedIssues = [...medicineValues['patientProvidedInformation']];

    if (checked) {
      updatedIssues.push({ id: label.id, value: label.name });
    } else {
      updatedIssues = updatedIssues.filter(issue => issue.id !== label.id);

      if (label.name === 'Other') {
        setMedicineValues(oldValues => ({
          ...oldValues,
          patientProvidedInformationOther: null,
        }));
      }
    }

    setMedicineValues(oldValues => {
      const newValues = {
        ...oldValues,
        patientProvidedInformation: updatedIssues,
      };

      if (label.name === 'Other') {
        setShowInformationProvidedOther(checked);
      }

      return newValues;
    });
  };

  const handleIssueReportedByPatientOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, patientReportedIssuesOther: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };
  const handleInformationProvidedOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, patientProvidedInformationOther: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const handleAdvicesToggle = (e, label) => {
    const { checked } = e.target;
    const value = label.name;
    const id = label.id;

    let updatedIssues = [...medicineValues['adviceProvidedIntervention']];

    if (checked) {
      updatedIssues.push({ id, value });
    } else {
      updatedIssues = updatedIssues.filter(issue => issue.id !== id);
      if (value === 'Other') {
        setMedicineValues(oldValues => ({
          ...oldValues,
          adviceProvidedOtherIntervention: null,
        }));
      }
    }

    setMedicineValues(oldValues => ({
      ...oldValues,
      adviceProvidedIntervention: updatedIssues,
    }));

    if (value === 'Other') {
      setShowAdviceProvidedOther(checked);
    }
  };

  const handleAdviceProvidedOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, adviceProvidedOtherIntervention: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const handleAgreedPatientActions = (e, label) => {
    const { checked } = e.target;
    const value = label.name;
    const id = label.id;

    let updatedIssues = [...medicineValues['patientAgreedAction']];
    if (checked) {
      updatedIssues.push({ id, value });
    } else {
      updatedIssues = updatedIssues.filter(issue => issue.id !== id);
      if (value === 'Other') {
        setMedicineValues(oldValues => ({
          ...oldValues,
          patientAgreedActionOther: null,
        }));
      }
    }

    setMedicineValues(oldValues => ({
      ...oldValues,
      patientAgreedAction: updatedIssues,
    }));

    if (value === 'Other') {
      setShowOther(checked);
    }
  };

  const handleAgreedPatientActionsOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, patientAgreedActionOther: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const handleAgreedPharmacistActions = (e, label) => {
    const { checked } = e.target;
    const value = label.name;
    const id = label.id;

    let updatedIssues = [...medicineValues['agreedPharmacistAction']];
    if (checked) {
      updatedIssues.push({ id, value });
    } else {
      updatedIssues = updatedIssues.filter(issue => issue.id !== id);
      if (value === 'Other') {
        setMedicineValues(oldValues => ({
          ...oldValues,
          agreedPharmacistActionOther: null,
        }));
      }
    }

    setMedicineValues(oldValues => ({
      ...oldValues,
      agreedPharmacistAction: updatedIssues,
    }));

    if (value === 'Referred back to the prescriber') {
      setShowPharmacistAgreedActionReferBack(checked);
      setMedicineValues(oldValues => ({
        ...oldValues,
        pharmacistAgreedReferBackIntervention: null,
      }));
      setGpValues(oldValues => ({
        ...oldValues,
        name: null,
        address: null,
        telephone: null,
        email: null,
        gPPracticeName: null,
      }));
    }

    if (value === 'Other') {
      setShowPharmacistAgreedActionOther(checked);
    }
  };

  const handlePharmacistAgreedActionsOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, agreedPharmacistActionOther: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const handleHealthyLivingAdvice = (e, label) => {
    const { checked } = e.target;
    const value = label.name;
    const id = label.id;

    let updatedIssues = [...medicineValues['healthyLivingAdvice']];
    if (checked) {
      updatedIssues.push({ id, value });
    } else {
      updatedIssues = updatedIssues.filter(issue => issue.id !== id);
      if (value === 'Other') {
        setMedicineValues(oldValues => ({
          ...oldValues,
          healthyLivingAdviceOther: null,
        }));
      }
    }

    setMedicineValues(oldValues => ({
      ...oldValues,
      healthyLivingAdvice: updatedIssues,
    }));

    if (value === 'Other') {
      setShowHealthyLivingAdviceOther(checked);
    }
  };

  const handleHealthyLivingAdviceOther = e => {
    setMedicineValues(oldValues => ({ ...oldValues, healthyLivingAdviceOther: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const handleActualFollowUpDate = date => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setMedicineValues(oldValues => ({
        ...oldValues,
        actualFollowUpDate: formattedDate,
      }));
    }
  };

  const handleFollowUpDate = date => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setMedicineValues(oldValues => ({
        ...oldValues,
        followUpDate: formattedDate,
      }));
    }
  };

  const handleFollowUpTime = time => {
    if (time) {
      const formattedTime = time.format('HH:mm');
      setMedicineValues(oldValues => ({
        ...oldValues,
        followUpTime: formattedTime,
      }));
    }
  };

  const handleActualFollowUpTime = time => {
    if (time) {
      const formattedTime = time.format('HH:mm');
      setMedicineValues(oldValues => ({
        ...oldValues,
        actualFollowUpTime: formattedTime,
      }));
    }
  };

  const handleAdviceProvidedFollowUp = e => {
    setShowAdviceProvidedFollowUp(e.target.checked);
    if (e.target.checked === false) {
      setMedicineValues(oldValues => ({
        ...oldValues,
        adviceProvidedFollowUp: null,
      }));
      setShowAdviceProvidedFollowUpOther(false);
      setMedicineValues(oldValues => ({
        ...oldValues,
        adviceProvidedOtherFollowUp: null,
      }));
    }
  };

  const handleReferredToGpFollowUp = e => {
    const checked = e.target.checked;
    const newValue = checked ? 'referredToGp' : null;
    setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackFollowUp: newValue }));
    setGpValues(oldValues => ({
      ...oldValues,
      name: null,
      address: null,

      telephone: null,
      email: null,
      gPPracticeName: null,
    }));

    if (newValue === null) {
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackFollowUpId: null }));
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackRecordFollowUpId: null }));
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackFollowUpValue: null }));
    }
  };

  const handleFollowUpNote = e => {
    setMedicineValues(oldValues => ({ ...oldValues, followUpNote: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const setGpFormValues = e => {
    setGpValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const handleAdviceToggle = (advice, adviceId, e) => {
    setMedicineValues(oldValues => {
      const newAdviceProvidedFollowUp = oldValues.adviceProvidedFollowUp || [];
      if (e.target.checked) {
        return {
          ...oldValues,
          adviceProvidedFollowUp: [...newAdviceProvidedFollowUp, { id: adviceId, value: advice }],
        };
      } else {
        return {
          ...oldValues,
          adviceProvidedFollowUp: newAdviceProvidedFollowUp.filter(adviceItem => adviceItem.value !== advice),
        };
      }
    });
  };

  const handle_referred_back_to_the_prescriber = e => {
    setMedicineValues(oldValues => ({ ...oldValues, [e.target.name]: parseInt(e.target.value) }));
    if (e.target.name === 'pharmacistAgreedReferBackIntervention') {
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackInterventionId: parseInt(e.target.value) }));
      setMedicineValues(oldValues => ({
        ...oldValues,
        pharmacistAgreedReferBackInterventionValue: e.target.closest('label').getAttribute('data-value'),
      }));
    } else {
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackFollowUpId: parseInt(e.target.value) }));
      setMedicineValues(oldValues => ({
        ...oldValues,
        pharmacistAgreedReferBackFollowUpValue: e.target.closest('label').getAttribute('data-value'),
      }));
    }

    setGpValues(oldValues => ({
      ...oldValues,
      ['referDetail']: e.target.closest('label').getAttribute('data-value'),
    }));

    if (e.target.name === 'pharmacistAgreedReferBackIntervention' && e.target.value === '10') {
      setShowPharmacistAgreedActionReferBackOtherIntervention(true);
    } else if (e.target.name === 'pharmacistAgreedReferBackFollowUp' && e.target.value === '10') {
      setShowPharmacistAgreedActionReferBackOtherFollowUp(true);
    } else if (e.target.name === 'pharmacistAgreedReferBackIntervention' && e.target.value !== '10') {
      setShowPharmacistAgreedActionReferBackOtherIntervention(false);
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackOtherIntervention: null }));
    } else if (e.target.name === 'pharmacistAgreedReferBackFollowUp' && e.target.value !== '10') {
      setShowPharmacistAgreedActionReferBackOtherFollowUp(false);
      setMedicineValues(oldValues => ({ ...oldValues, pharmacistAgreedReferBackOtherFollowUp: null }));
    }
  };

  const handleGpSubmit = () => {
    const requiredFields = ['name', 'address', 'session_id'];
    const missingFields = requiredFields.filter(field => !gpValues[field]);

    const data = {
      ...gpValues,
      prescriptionMedicine: medicineValues['prescriptionMedicineId'],
    };
    if (missingFields.length === 0) {
      // @ts-ignore
      dispatch(referGp(data))
        .then(res => {
          toast.success(res?.payload['data']['message']);
        })
        .catch(error => {
          toast.error(error);
        });
    } else {
      toast.error('Please fill all GP required options!');
    }
  };

  const handleMedicineSelectChange = event => {
    const selectedMedicineKeyChange = event.target.value.split('-')[0];
    const selectedMedicineIndexChange = event.target.value.split('-')[1];
    const selectedMedicineChange = event.target.value.split('-')[2];

    setSelectedMedicineKey(selectedMedicineKeyChange);
    setSelectedMedicineIndex(selectedMedicineIndexChange);

    setMedicineValues(oldValues => ({
      ...oldValues,
      ['medicineId']: Number(selectedMedicineChange),
      ['prescriptionMedicineId']: Number(selectedMedicineKeyChange),
    }));
    console.log(medicineValues);
    setSelectedMedicine(event.target.value);
    if (
      event.target.value !== null &&
      event.target.value !== undefined &&
      event.target.value !== '' &&
      event.target.value !== 'Select Medicine'
    ) {
      setShowMedicineRelatedContent(true);
    } else {
      setShowMedicineRelatedContent(false);
    }
  };

  const handleMedicineConditionToggle = (condition, conditionId, e) => {
    const conditionExistsInObjects = selectMedicineConditions.some(report => report.value.includes(condition));
    if (conditionExistsInObjects) {
      setSelectMedicineConditions(selectMedicineConditions.filter(item => item.value !== condition));
    } else {
      setSelectMedicineConditions([...selectMedicineConditions, { id: conditionId, value: condition }]);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['medicineConditions']: [...selectMedicineConditions, { id: conditionId, value: condition }],
      }));
      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const followUpHtml = () => {
    return (
      <>
        <h1 style={{ fontSize: 20, marginTop: '40px', color: '#246EBA', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>
          Actual Follow Up Date
        </h1>
        <Divider style={{ backgroundColor: 'lightgray', height: 2, width: 600, marginTop: 10 }} />
        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'start', marginBottom: 25 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Select Date"
                value={medicineValues.actualFollowUpDate ? dayjs(medicineValues.actualFollowUpDate, 'YYYY-MM-DD') : null}
                onChange={newValue => handleActualFollowUpDate(newValue)}
                sx={{
                  width: 220,
                  marginTop: 0,
                  marginLeft: 0,
                  border: fieldError['actualFollowUpDate'] ? '1px solid red' : '',
                }}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              marginLeft: 0,
              display: 'flex',
              borderRadius: 3,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={medicineValues.actualFollowUpTime ? dayjs(medicineValues.actualFollowUpTime, 'HH:mm') : null}
                onChange={newValue => handleActualFollowUpTime(newValue)}
                sx={{
                  width: 150,
                  marginTop: 0,
                  marginLeft: 2,
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <h1 style={{ fontSize: 20, marginTop: '40px', color: '#246EBA', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>
          Follow Up Date
        </h1>
        <Divider style={{ backgroundColor: 'lightgray', height: 2, width: 600, marginTop: 10 }} />
        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'start', marginBottom: 25 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Select Date"
                value={medicineValues.followUpDate ? dayjs(medicineValues.followUpDate, 'YYYY-MM-DD') : null}
                onChange={newValue => handleFollowUpDate(newValue)}
                sx={{
                  width: 220,
                  marginTop: 0,
                  marginLeft: 0,
                  border: fieldError['followUpDate'] ? '1px solid red' : '',
                }}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              marginLeft: 0,
              display: 'flex',
              borderRadius: 3,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={medicineValues.followUpTime ? dayjs(medicineValues.followUpTime, 'HH:mm') : null}
                onChange={newValue => handleFollowUpTime(newValue)}
                sx={{
                  width: 150,
                  marginTop: 0,
                  marginLeft: 2,
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="medicineUsedAsPrescribedFollowUp"
                size="small"
                disabled={medicineValues?.medicineUsedAsPrescribedFollowUp === false}
                checked={medicineValues?.medicineUsedAsPrescribedFollowUp === true}
                onChange={handleMedicinePrescribedFollowUp}
              />
            }
            label="Patient using medicine as prescribed"
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name="medicineNotUsedAsPrescribedFollowUp"
                  size="small"
                  checked={medicineValues?.medicineUsedAsPrescribedFollowUp === false}
                  onChange={handleMedicinePrescribedFollowUp}
                  disabled={medicineValues?.medicineUsedAsPrescribedFollowUp === true}
                />
              }
              label="Patient not using medicine as prescribed"
            />
          </div>
          <div style={{ marginLeft: '10px' }}>
            {showMedicineUsedAsPrescribedFollowUp && (
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={medicineValues?.sessionMedicineUsedAsPrescribedNoTypeFollowUp || null}
                    name="sessionMedicineUsedAsPrescribedNoType"
                    style={{ marginTop: 5 }}
                    onChange={handlemedicine_as_prescribedoptions}
                  >
                    {medicineUsedAsPrescribedNoTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                        value={label.id}
                        control={<Radio size="small" />}
                        label={label.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="adviceProvided"
                size="small"
                value={showAdviceProvidedFollowUp}
                checked={showAdviceProvidedFollowUp}
                onChange={handleAdviceProvidedFollowUp}
              />
            }
            label="Issues identified and discussed (advice give)"
          />
          <div style={{ marginLeft: '10px' }}>
            {showAdviceProvidedFollowUp && (
              <div>
                <FormGroup>
                  {adviceToPatientTypeEntities.map((label, key) => (
                    <FormControlLabel
                      key={key}
                      style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                      control={
                        <Checkbox
                          size="small"
                          name={label.name}
                          checked={medicineValues?.adviceProvidedFollowUp?.some(advice => advice.value === label.name) || false}
                          onChange={e => {
                            handleAdviceToggle(label.name, label.id, e);
                          }}
                        />
                      }
                      label={label.name}
                    />
                  ))}
                </FormGroup>

                {showAdviceProvidedFollowUpOther && (
                  <FormControl sx={{ width: '25ch', marginTop: 2, marginBottom: '16px' }}>
                    <OutlinedInput
                      style={{
                        border: fieldErrorMedicine['adviceProvidedOtherFollowUp'] ? '1px solid red' : '1px solid lightgray',
                        borderRadius: 5,
                        height: 35,
                        width: 600,
                        marginTop: 0,
                      }}
                      value={medicineValues ? medicineValues['adviceProvidedOtherFollowUp'] : ''}
                      name={'adviceProvidedOtherFollowUp'}
                      placeholder="Please Indicate"
                      onChange={handleAdviceProvidedOtherFollowUp}
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </FormControl>
                )}
              </div>
            )}
          </div>
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="referredToGp"
                size="small"
                checked={medicineValues ? !!medicineValues.pharmacistAgreedReferBackFollowUp : false}
                onChange={handleReferredToGpFollowUp}
              />
            }
            label="Issue identified referred to GP"
          />
        </div>

        <div style={{ marginLeft: '10px' }}>
          {showPharmacistAgreedActionReferBackFollowUp && (
            <div>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name={'pharmacistAgreedReferBackFollowUp'}
                    value={medicineValues ? medicineValues['pharmacistAgreedReferBackFollowUp'] : ''}
                    onChange={handle_referred_back_to_the_prescriber}
                    style={{ marginTop: 5 }}
                  >
                    {pharmacistReferBackTypes &&
                      pharmacistReferBackTypes.length > 0 &&
                      pharmacistReferBackTypes.map((label, key) => (
                        <FormControlLabel
                          key={key}
                          style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                          value={label.id}
                          data-value={label.name}
                          control={<Radio size="small" />}
                          label={label.name}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </div>

              {showPharmacistAgreedActionReferBackOtherFollowUp && (
                <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                  <OutlinedInput
                    style={{
                      borderRadius: 5,
                      height: 35,
                      width: 600,
                      marginTop: 0,
                    }}
                    value={medicineValues ? medicineValues['pharmacistAgreedReferBackOtherFollowUp'] : ''}
                    placeholder="Please Indicate"
                    name={'pharmacistAgreedReferBackOtherFollowUp'}
                    inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    onChange={setFormValuesMedicine}
                  />
                </FormControl>
              )}

              <div style={{ height: 200, backgroundColor: 'white', marginTop: 20 }}>
                <h2 style={{ fontSize: 18, marginTop: 40, fontWeight: '', color: 'black', fontFamily: 'Roboto, sans-serif' }}>
                  Patient Registered GP Details:{' '}
                </h2>
                <Grid container spacing={2} sx={{ paddingBottom: '3px' }}>
                  <Grid item xs={6}>
                    <OutlinedInput
                      className="outline"
                      placeholder="Name"
                      name="name"
                      fullWidth
                      value={gpValues ? gpValues['name'] : ''}
                      onChange={setGpFormValues}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '18px' }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <OutlinedInput
                      className="outline"
                      placeholder="Email"
                      name="email"
                      fullWidth
                      value={gpValues ? gpValues['email'] : ''}
                      onChange={setGpFormValues}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img src={'../../../../content/assets/email.png'} alt="logo" style={{ height: '18px' }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <OutlinedInput
                      className="outline"
                      placeholder="Address"
                      name="address"
                      fullWidth
                      value={gpValues ? gpValues['address'] : ''}
                      onChange={setGpFormValues}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <OutlinedInput
                      className="outline"
                      placeholder="Contact No."
                      name="telephone"
                      fullWidth
                      value={gpValues ? gpValues['telephone'] : ''}
                      onChange={setGpFormValues}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img src={'../../../../content/assets/phone.png'} alt="logo" style={{ height: '18px' }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ marginTop: 5 }}>
                    <OutlinedInput
                      className="outline"
                      placeholder="GP Practice Name"
                      name="gPPracticeName"
                      fullWidth
                      value={gpValues ? gpValues['gPPracticeName'] : ''}
                      onChange={setGpFormValues}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '18px' }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </Grid>
                </Grid>

                <div style={{ marginTop: 20 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleGpSubmit}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Refer to GP
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <FormControl sx={{ width: '25ch', marginTop: 5 }}>
          <OutlinedInput
            style={{
              borderRadius: 5,
              height: 35,
              width: 600,
              marginTop: 0,
            }}
            placeholder="Follow Up Note"
            value={medicineValues ? medicineValues['followUpNote'] : ''}
            name={'otherNotes'}
            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
            onChange={handleFollowUpNote}
          />
        </FormControl>
      </>
    );
  };

  const interventionUpHtml = () => {
    return (
      <div style={{ justifyContent: 'start', marginTop: 35 }}>
        <Divider style={{ backgroundColor: 'white', height: 2, width: 2, marginTop: 20 }} />

        <span
          style={{
            fontSize: 15,
            marginTop: 20,
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
            marginBottom: 20,
          }}
        >
          NSCR Access
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={medicineValues ? medicineValues['scrAccess'] : null}
              onChange={handleScrAccess}
              name="scrAccess"
            >
              <div style={{ display: 'flex', marginTop: 10, fontFamily: 'Roboto, sans-serif' }}>
                <FormControlLabel
                  value={'true'}
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>Yes</Typography>}
                />

                <FormControlLabel
                  value={'false'}
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>No</Typography>}
                  style={{ marginLeft: 30 }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        <span style={{ fontSize: 15, marginTop: 20, fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>Intervention delivered</span>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={medicineValues ? medicineValues['previouslyDelivered'] : null}
              onChange={handleInterventionDeliveredOptionChange}
              name="previouslyDelivered"
            >
              <div style={{ display: 'flex', marginTop: 10, fontFamily: 'Roboto, sans-serif' }}>
                <FormControlLabel
                  value={'true'}
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>Yes</Typography>}
                />

                <FormControlLabel
                  value={'false'}
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>No</Typography>}
                  style={{ marginLeft: 30 }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        {showInterventionDelivered && (
          <div>
            <h1
              style={{
                color: '#246EBA',
                fontSize: 17,
                marginTop: 30,
                marginLeft: 0,
                fontWeight: 'bold',
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              Reason For Not Completing{' '}
            </h1>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="sessionPreviouslyDeliveredNoType"
                value={medicineValues ? parseInt(medicineValues['sessionPreviouslyDeliveredNoType']) : null}
                onChange={handleInterventionDeliveredNoType}
                style={{ marginTop: 5, fontFamily: 'Roboto, sans-serif' }}
              >
                {previouslyDeliveredNoTypeEntities.map((label, key) => (
                  <FormControlLabel
                    key={key}
                    style={{ fontWeight: 700, fontSize: 13 }}
                    value={label.id}
                    control={<Radio size="small" />}
                    label={label.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <Divider style={{ backgroundColor: 'white', height: 2, width: 600 }} />

            {showInterventionDeliveredOther && (
              <FormControl sx={{ width: '25ch', marginTop: 1 }}>
                <OutlinedInput
                  type={'text'}
                  style={{
                    border: fieldError['sessionPreviouslyDeliveredOtherReason'] ? '1px solid red' : '1px solid lightgray',
                    borderRadius: 5,
                    height: 35,
                    width: 600,
                    marginTop: 0,
                  }}
                  placeholder="Please Indicate"
                  onChange={handleInterventionDeliveredOther}
                  name={'sessionPreviouslyDeliveredOtherReason'}
                  value={medicineValues ? medicineValues['sessionPatientPreviouslyDeliveredOtherDescription'] : ''}
                  inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                />
              </FormControl>
            )}
          </div>
        )}

        <h1
          style={{
            color: '#246EBA',
            fontSize: 17,
            marginTop: 25,
            marginLeft: 0,
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Intervention Call Date
        </h1>

        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'start' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Select Date"
                value={medicineValues.lastModifiedDate ? dayjs(medicineValues.lastModifiedDate, 'YYYY-MM-DD') : null}
                onChange={newValue => handleActualInterventionDate(newValue)}
                sx={{
                  width: 220,
                  marginTop: 0,
                  marginLeft: 0,
                  border: fieldError['lastModifiedDate'] ? '1px solid red' : '',
                }}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              marginTop: 2,
              borderRadius: 3,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Select Time"
                value={medicineValues.lastModifiedDateTime ? dayjs(medicineValues.lastModifiedDateTime, 'HH:mm') : null}
                onChange={handleActualInterventionTime}
                sx={{
                  width: 150,
                  marginLeft: 2,
                  border: fieldError['lastModifiedDateTime'] ? '1px solid red' : '',
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <h1
          style={{
            color: '#246EBA',
            fontSize: 17,
            marginTop: 25,
            marginLeft: 0,
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Date Of Engagement
        </h1>

        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'start' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Select Date"
                value={medicineValues.nextSession ? dayjs(medicineValues.nextSession, 'YYYY-MM-DD') : null}
                onChange={newValue => handleInterventionDate(newValue)}
                sx={{
                  width: 220,
                  marginTop: 0,
                  marginLeft: 0,
                  border: fieldError['nextSession'] ? '1px solid red' : '',
                }}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              marginTop: 2,
              borderRadius: 3,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Select Time"
                value={medicineValues.nextSessionTime ? dayjs(medicineValues.nextSessionTime, 'HH:mm') : null}
                onChange={handleInterventionTime}
                sx={{
                  width: 150,
                  marginLeft: 2,
                  border: fieldError['nextSessionTime'] ? '1px solid red' : '',
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <h1
          style={{
            color: '#246EBA',
            fontSize: 17,
            marginTop: 30,
            marginLeft: 0,
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Method of Consultation (Intervention)
        </h1>
        <div style={{ display: 'flex' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={medicineValues ? parseInt(medicineValues['methodOfConsultationIntervention']) : null}
              onChange={handlemethodofConsultation}
              name="sessionConsultationMethod"
            >
              <div style={{ display: 'flex', marginTop: 0, marginBottom: 25 }}>
                {consultationMethodEntities.map((value, key) => (
                  <FormControlLabel
                    key={key}
                    value={value.id}
                    data-value={value.name}
                    control={<Radio size="small" />}
                    label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>{value.name}</Typography>}
                    style={{ marginLeft: key > 0 ? 30 : 0, fontFamily: 'Roboto, sans-serif' }}
                  />
                ))}
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        <h1
          style={{
            color: '#246EBA',
            fontSize: 17,
            marginTop: 5,
            marginLeft: 0,
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Patient Ethnicity
        </h1>

        <div style={{ display: 'flex' }}>
          <FormControl>
            <Select
              labelId="ethnic-group-select-label"
              id="ethnic-group-select"
              name="ethnicityOfPatient"
              value={selectedOptionEthnic}
              onChange={handleChangeEthnic}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return <span style={{ color: '#393939', fontSize: '13px' }}>Select Ethnicity of Patient</span>;
                }
                return selected;
              }}
              style={{
                height: '40px',
                width: '350px',
                fontSize: '13px',
                backgroundColor: 'white',
                color: '#393939',
              }}
            >
              <MenuItem value="">
                <span style={{ fontSize: '13px' }}>Select Ethnicity of Patient</span>
              </MenuItem>
              {ethnicGroups.map(group => [
                <ListSubheader key={group.label} style={{ fontWeight: 'bold' }}>
                  {group.label}
                </ListSubheader>,
                ...group.options.map(option => (
                  <MenuItem key={option.key} value={option.value} style={{ fontSize: '12px', marginLeft: '8px' }}>
                    {option.value}
                  </MenuItem>
                )),
              ])}
            </Select>
            {showTextFieldEthnic && (
              <OutlinedInput
                style={{
                  // border: fieldError['ethnicityOfPatientOther'] ? '1px solid red' : '1px solid lightgray',
                  borderRadius: 5,
                  height: 40,
                  marginTop: 2,
                }}
                name={'ethnicityOfPatientOther'}
                value={medicineValues ? medicineValues['ethnicityOfPatientOther'] : ''}
                placeholder="Please specify"
                onChange={handleChangeEthnicOther}
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
            )}
          </FormControl>
        </div>
      </div>
    );
  };

  const followUpMethodofConsultation = () => {
    return (
      <div style={{ justifyContent: 'start' }}>
        <h1
          style={{
            color: '#246EBA',
            fontSize: 17,
            marginLeft: 0,
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Method of Consultation (Follow Up)
        </h1>
        <div style={{ display: 'flex' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={medicineValues ? parseInt(medicineValues['methodOfConsultationFollowUp']) : null}
              onChange={handlemethodofConsultationFollowUp}
              name="sessionConsultationMethod"
            >
              <div style={{ display: 'flex', marginTop: 0, marginBottom: 25 }}>
                {consultationMethodEntities.map((value, key) => (
                  <FormControlLabel
                    key={key}
                    value={value.id}
                    data-value={value.name}
                    control={<Radio size="small" />}
                    label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>{value.name}</Typography>}
                    style={{ marginLeft: key > 0 ? 30 : 0, fontFamily: 'Roboto, sans-serif' }}
                  />
                ))}
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    );
  };

  return (
    <div className="main_div">
      <div className="form_div">
        <div className="form_div_content">
          <h1 className="greetingName">Patient Details</h1>
          <Divider style={{ backgroundColor: 'gray', height: 1 }} />

          <div className="field_div dateFieldsDeo">
            <FormControl sx={{ width: '25ch', marginTop: 1 }}>
              <OutlinedInput
                className="outline"
                placeholder="Patient Name"
                name="fullName"
                error={fieldError['fullName']}
                value={values ? values['fullName'] : ''}
                onChange={setFormValues}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" style={{ height: 20 }}>
                      <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '16px' }} />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={'Date of birth'}
                className="clock"
                format={'DD/MM/YYYY'}
                value={values ? dayjs(values['dateofBirth']) : dayjs(dateOfBirth)}
                onChange={e => {
                  handleDateOfBirth(e);
                }}
                sx={{
                  width: 490,
                  marginTop: 1,
                  marginLeft: 36,
                  border: `${fieldError['dateofBirth'] ? '1px solid red' : ' 1px solid #7CB6F8'}`,
                  borderRadius: 1,
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="field_div dateFieldsDeo">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of engagement"
                className="clock"
                format={'DD/MM/YYYY'}
                value={values ? dayjs(values['nextSession']) : dayjs(nextSession)}
                onChange={e => {
                  handleNextSessionDate(e);
                }}
                sx={{
                  marginTop: 1,
                  width: '91%',
                  border: `${fieldError['nextSession'] ? '1px solid red' : ' 1px solid #7CB6F8'}`,
                  borderRadius: 1,
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="field_div">
            <FormControl sx={{ width: '25ch', marginTop: 0 }}>
              <OutlinedInput
                className="outline"
                placeholder="Patient Address"
                name="address"
                error={fieldError['address']}
                value={values ? values['address'] : ''}
                onChange={setFormValues}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" style={{ height: 20 }}>
                      <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
            </FormControl>
            <FormControl sx={{ width: '25ch', marginTop: 0, marginLeft: 36 }}>
              <OutlinedInput
                className="outline"
                placeholder="NHS No."
                name="nhsNumber"
                error={fieldError['nhsNumber']}
                value={values ? values['nhsNumber'] : ''}
                onChange={setFormValues}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" style={{ height: 20 }}>
                      <img src={'../../../../content/assets/hash.png'} alt="logo" style={{ height: '18px' }} />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
              <span style={{ color: 'red', fontSize: '11px' }}>{fieldError['nhsNumber_helper'] ? fieldError['nhsNumber_helper'] : ''}</span>
            </FormControl>
          </div>

          <div className="field_div">
            <FormControl sx={{ width: '25ch', marginTop: 0 }}>
              <OutlinedInput
                className="outline"
                placeholder="Primary Contact"
                name="telephone"
                error={fieldError['telephone']}
                value={values ? values['telephone'] : ''}
                onChange={setFormValues}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" style={{ height: 20 }}>
                      <img src={'../../../../content/assets/phone.png'} alt="logo" style={{ height: '18px' }} />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
              <span style={{ color: 'red', fontSize: '11px' }}>{fieldError['telephone_helper'] ? fieldError['telephone_helper'] : ''}</span>
            </FormControl>
            <FormControl sx={{ width: '25ch', marginTop: 0, marginLeft: 36 }}>
              <OutlinedInput
                className="outline"
                placeholder="Secondary Contact"
                name="secondary_telephone"
                error={fieldError['secondary_telephone']}
                value={values ? values['secondary_telephone'] : ''}
                onChange={setFormValues}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" style={{ height: 20 }}>
                      <img src={'../../../../content/assets/phone.png'} alt="logo" style={{ height: '18px' }} />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
              <span style={{ color: 'red', fontSize: '11px' }}>
                {fieldError['secondary_telephone_helper'] ? fieldError['secondary_telephone_helper'] : ''}
              </span>
            </FormControl>
          </div>

          <div className="field_div">
            <FormControl sx={{ width: '91%' }}>
              <OutlinedInput
                sx={{ width: '100%' }}
                className="outline"
                placeholder="Medicine(s) to be reviewed"
                name="medicineArray"
                value={inputText}
                onChange={handleInputChange2}
                onKeyPress={handleInputKeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" style={{ height: 20 }}>
                      <img src={'../../../../content/assets/reference.png'} alt="logo" style={{ height: '18px' }} />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
              <div className="chip_div" style={{ width: '100%' }}>
                {chips.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip}
                    onDelete={() => handleChipDelete(chip)}
                    deleteIcon={<CancelIcon />}
                    style={{ margin: '4px' }}
                  />
                ))}
              </div>
            </FormControl>
          </div>

          {isEdit && isCompleted && (
            <>
              <div className="field_div">
                <FormControl sx={{ width: '91%', marginTop: 0 }}>
                  <OutlinedInput
                    sx={{ width: '100%' }}
                    className="outline"
                    placeholder="Any Additional Comment(s)"
                    name="comment"
                    value={values ? values['comment'] : ''}
                    onChange={setFormValues}
                    inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                  />
                </FormControl>
              </div>
              <div>
                <div>
                  {deoSessionEntity?.sessionStatus?.id === NMS_STATUS.COMPLETED && showMedicineRelatedContent && interventionUpHtml()}
                </div>
                <div>
                  {deoSessionEntity?.sessionStatus?.id === NMS_STATUS.COMPLETED &&
                    deoSessionEntity?.prevSessionType.id === NMS_TYPES.FOLLOW_UP &&
                    showMedicineRelatedContent &&
                    followUpMethodofConsultation()}
                </div>
              </div>
              <div className="field_div">
                <FormControl sx={{ width: '91%', marginBottom: 3 }}>
                  <Select
                    id="medicine-select"
                    value={selectedMedicine}
                    onChange={handleMedicineSelectChange}
                    style={{
                      width: '100%',
                      height: '80%',
                      fontSize: '12px',
                      backgroundColor: '#EFEFEF',
                      color: '#393939',
                      marginRight: '10px',
                    }}
                  >
                    <MenuItem value={'Select Medicine'}>Select Medicine</MenuItem>
                    {deoSessionEntity?.prescription?.prescriptionMedicines?.map((item, index) => (
                      <MenuItem key={index} value={`${item?.id}-${index}-${item?.medicine?.id}`}>
                        {item?.medicine?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          {isEdit && isCompleted && showMedicineRelatedContent && (
            <Grid lg={8} container sx={{ flexDirection: 'column', marginLeft: 0, justifyContent: 'center' }}>
              <h1
                style={{
                  fontSize: 17,
                  marginTop: 20,
                  fontWeight: 'bold',
                  color: '#61A8F1',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Eligible Condition
              </h1>

              {showConditionsDropdown ? (
                <FormControl>
                  <Select
                    id="demo-simple-select"
                    name="medicineConditions"
                    value={
                      medicineValues.medicineConditions.length > 0
                        ? `${medicineValues.medicineConditions[0].id}:${medicineValues.medicineConditions[0].value}`
                        : ''
                    }
                    onChange={handleSelectNewMedicineCondition}
                    style={{
                      height: '40px',
                      width: '350px',
                      fontSize: '12px',
                      backgroundColor: 'white',
                      color: '#393939',
                    }}
                  >
                    <MenuItem disabled value="">
                      Select Medicine Condition
                    </MenuItem>
                    {medicineConditionsList.map(elem => (
                      <MenuItem key={elem.id} value={`${elem.id}:${elem.name}`}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl component="fieldset" style={{ marginTop: 5 }}>
                  <FormGroup>
                    {medicineConditions.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                        control={
                          <Checkbox
                            size="small"
                            name={'medicineConditions'}
                            checked={
                              (label.key === medicineConditions && defaultChecked) ||
                              selectMedicineConditions.some(report => report.value.includes(label.name))
                            }
                            onChange={e => {
                              if (label.key === medicineConditions) {
                                setDefaultChecked(e.target.checked);
                              }
                              handleMedicineConditionToggle(label.name, label.id, e);
                            }}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}

              <h1
                style={{
                  fontSize: 17,
                  marginTop: 40,
                  fontWeight: 'bold',
                  color: '#61A8F1',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Is the patient using medicine as prescribed ?{' '}
              </h1>
              <div style={{ display: 'flex' }}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={medicineValues ? String(medicineValues['medicineUsedAsPrescribed']) : null}
                    name="medicineUsedAsPrescribed"
                    onChange={handlesetPatient_using_medicine_as_prescribed}
                  >
                    <div style={{ display: 'flex', marginTop: 10 }}>
                      <FormControlLabel
                        value="true"
                        control={<Radio size="small" />}
                        label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>Yes</Typography>}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="small" />}
                        label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>No</Typography>}
                        style={{ marginLeft: 30 }}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              {showMedicinePrescribed && (
                <div>
                  <h1
                    style={{
                      color: '#246EBA',
                      fontSize: 17,
                      marginTop: 30,
                      marginLeft: 0,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Patient reports not using the medicine as prescribed{' '}
                    <span style={{ color: fieldErrorMedicine['sessionMedicineUsedAsPrescribedNoType'] ? 'red' : 'green' }}></span>
                  </h1>

                  <div>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={medicineValues ? medicineValues['sessionMedicineUsedAsPrescribedNoType'] : null}
                      name="sessionMedicineUsedAsPrescribedNoType"
                      style={{ marginTop: 5 }}
                      onChange={handlepatient_reports_not_using_the_medicine_as_prescribedoptions}
                    >
                      {medicineUsedAsPrescribedNoTypeEntities.map((label, key) => (
                        <FormControlLabel
                          key={key}
                          style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                          value={label.id}
                          control={<Radio size="small" />}
                          label={label.name}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
              )}

              <div>
                <h1
                  style={{
                    fontSize: 17,
                    marginTop: 40,
                    fontWeight: 'bold',
                    color: '#61A8F1',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Issues reported by the patient{' '}
                </h1>

                <FormControl component="fieldset" style={{ marginTop: 5 }}>
                  <FormGroup>
                    {patientReportedIssueTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                        control={
                          <Checkbox
                            size="small"
                            name={'patientReportedIssues'}
                            value={label.name}
                            checked={medicineValues['patientReportedIssues'].some(issue => issue.id === label.id)}
                            onChange={e => handleIssueReportedByPatient(e, label)}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>

                {showIssueReportedByPatientOther && (
                  <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                    <OutlinedInput
                      style={{
                        border: fieldErrorMedicine['patientReportedIssuesOther'] ? '1px solid red' : '1px solid lightgray',
                        borderRadius: 5,
                        height: 35,
                        width: 600,
                        marginTop: 0,
                      }}
                      name={'patientReportedIssuesOther'}
                      value={medicineValues ? medicineValues['patientReportedIssuesOther'] : ''}
                      placeholder="Please Indicate"
                      onChange={handleIssueReportedByPatientOther}
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </FormControl>
                )}
              </div>

              <div>
                <h1
                  style={{
                    fontSize: 17,
                    marginTop: 40,
                    fontWeight: 'bold',
                    color: '#61A8F1',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Information Provided
                </h1>

                <FormControl component="fieldset" style={{ marginTop: 5 }}>
                  <FormGroup>
                    {patientProvideInformationTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                        control={
                          <Checkbox
                            size="small"
                            name={'patientProvidedInformation'}
                            value={label.name}
                            checked={medicineValues['patientProvidedInformation'].some(issue => issue.id === label.id)}
                            onChange={e => handleInformationProvided(e, label)}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>

              {/*<h1*/}
              {/*  style={{*/}
              {/*    fontSize: 17,*/}
              {/*    marginTop: 40,*/}
              {/*    fontWeight: 'bold',*/}
              {/*    color: '#61A8F1',*/}
              {/*    fontFamily: 'Roboto, sans-serif',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Advice provided*/}
              {/*</h1>*/}

              {/*<div>*/}
              {/*  <FormGroup>*/}
              {/*    /!*<FormControl component="fieldset" style={{ marginTop: 5 }}>*!/*/}
              {/*    {adviceToPatientTypeEntities.map((label, key) => (*/}
              {/*      <FormControlLabel*/}
              {/*        key={key}*/}
              {/*        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}*/}
              {/*        control={*/}
              {/*          <Checkbox*/}
              {/*            value={label.name}*/}
              {/*            size="small"*/}
              {/*            name={'adviceToPatient'}*/}
              {/*            checked={medicineValues['adviceProvidedIntervention'].some(advice => advice.id === label.id)}*/}
              {/*            onChange={e => handleAdvicesToggle(e, label)}*/}
              {/*          />*/}
              {/*        }*/}
              {/*        label={label.name}*/}
              {/*      />*/}
              {/*    ))}*/}
              {/*    /!*</FormControl>*!/*/}
              {/*  </FormGroup>*/}

              {/*  {showAdviceProvidedOther && (*/}
              {/*    <FormControl sx={{ width: '25ch', marginTop: 2 }}>*/}
              {/*      <OutlinedInput*/}
              {/*        style={{*/}
              {/*          border: fieldErrorMedicine['adviceToPatientOther'] ? '1px solid red' : '1px solid lightgray',*/}
              {/*          borderRadius: 5,*/}
              {/*          height: 35,*/}
              {/*          width: 600,*/}
              {/*          marginTop: 0,*/}
              {/*        }}*/}
              {/*        name={'adviceToPatientOther'}*/}
              {/*        value={medicineValues ? medicineValues['adviceProvidedOtherIntervention'] : ''}*/}
              {/*        placeholder="Please Indicate"*/}
              {/*        onChange={handleAdviceProvidedOther}*/}
              {/*        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}*/}
              {/*      />*/}
              {/*    </FormControl>*/}
              {/*  )}*/}
              {/*</div>*/}

              <h1
                style={{
                  fontSize: 17,
                  marginTop: 40,
                  fontWeight: 'bold',
                  color: '#61A8F1',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Agreed patient actions
              </h1>

              <div>
                <FormGroup>
                  {patientAgreedActionTypeEntities.map((label, key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          size="small"
                          style={{ color: '' }}
                          value={label.name}
                          name={'patientAgreedAction'}
                          checked={medicineValues['patientAgreedAction'].some(
                            patientAgreedActionType => patientAgreedActionType.id === label.id
                          )}
                          onChange={e => handleAgreedPatientActions(e, label)}
                        />
                      }
                      label={label.name}
                    />
                  ))}
                </FormGroup>

                {showOther && (
                  <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                    <OutlinedInput
                      style={{
                        border: fieldErrorMedicine['patientAgreedActionOther'] ? '1px solid red' : '1px solid lightgray',
                        borderRadius: 5,
                        height: 35,
                        width: 600,
                        marginTop: 0,
                      }}
                      value={medicineValues ? medicineValues['patientAgreedActionOther'] : ''}
                      placeholder="Please Indicate"
                      name={'patientAgreedActionOther'}
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      onChange={handleAgreedPatientActionsOther}
                    />
                  </FormControl>
                )}
              </div>

              <h1
                style={{
                  fontSize: 17,
                  marginTop: 40,
                  fontWeight: 'bold',
                  color: '#61A8F1',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Agreed pharmacist actions
              </h1>

              <div>
                <FormGroup>
                  {pharmacistAgreedActionTypeEntities.map((label, key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          size="small"
                          style={{ borderColor: 'lightgray' }}
                          value={label.name}
                          name={'agreedPharmacistAction'}
                          checked={medicineValues['agreedPharmacistAction'].some(
                            pharmacistAgreedActionType => pharmacistAgreedActionType.id === label.id
                          )}
                          onChange={e => handleAgreedPharmacistActions(e, label)}
                        />
                      }
                      label={label.name}
                    />
                  ))}
                </FormGroup>

                {showPharmacistAgreedActionOther && (
                  <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                    <OutlinedInput
                      style={{
                        border: fieldErrorMedicine['agreedPharmacistActionOther'] ? '1px solid red' : '1px solid lightgray',
                        borderRadius: 5,
                        height: 35,
                        width: 600,
                        marginTop: 0,
                      }}
                      value={medicineValues ? medicineValues['agreedPharmacistActionOther'] : ''}
                      placeholder="Please Indicate"
                      name={'agreedPharmacistActionOther'}
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      onChange={handlePharmacistAgreedActionsOther}
                    />
                  </FormControl>
                )}
              </div>

              {showPharmacistAgreedActionReferBack && (
                <div>
                  <h1
                    style={{
                      color: '#246EBA',
                      fontSize: 17,
                      marginTop: 30,
                      marginLeft: 0,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Referred back to the prescriber{' '}
                    <span style={{ color: fieldErrorMedicine['pharmacistAgreedReferBackIntervention'] ? 'red' : 'green' }}></span>
                  </h1>

                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name={'pharmacistAgreedReferBackIntervention'}
                        value={medicineValues ? medicineValues['pharmacistAgreedReferBackIntervention'] : null}
                        onChange={handle_referred_back_to_the_prescriber}
                        style={{ marginTop: 5 }}
                      >
                        {pharmacistReferBackTypes &&
                          pharmacistReferBackTypes.length > 0 &&
                          pharmacistReferBackTypes.map((label, key) => (
                            <FormControlLabel
                              key={key}
                              style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                              value={label.id}
                              data-value={label.name}
                              control={<Radio size="small" />}
                              label={label.name}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {showPharmacistAgreedActionReferBackOtherIntervention && (
                    <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                      <OutlinedInput
                        style={{
                          border: fieldErrorMedicine['pharmacistAgreedReferBackOtherIntervention']
                            ? '1px solid red'
                            : '1px solid lightgray',
                          borderRadius: 5,
                          height: 35,
                          width: 600,
                          marginTop: 0,
                        }}
                        value={medicineValues ? medicineValues['pharmacistAgreedReferBackOtherIntervention'] : ''}
                        placeholder="Please Indicate"
                        name={'pharmacistAgreedReferBackOtherIntervention'}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                        onChange={setFormValuesMedicine}
                      />
                    </FormControl>
                  )}
                  {deoSessionEntity?.prevSessionType.id && (
                    <div style={{ height: 200, backgroundColor: 'white', marginTop: 20 }}>
                      <h2
                        style={{
                          fontSize: 18,
                          marginTop: 40,
                          fontWeight: '',
                          color: 'black',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Patient Registered GP Details:{' '}
                      </h2>
                      <Grid container spacing={2} sx={{ paddingBottom: '3px' }}>
                        <Grid item xs={6}>
                          <OutlinedInput
                            className="outline"
                            placeholder="Name"
                            name="name"
                            fullWidth
                            value={gpValues ? gpValues['name'] : ''}
                            onChange={setGpFormValues}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            className="outline"
                            placeholder="Email."
                            name="email"
                            fullWidth
                            value={gpValues ? gpValues['email'] : ''}
                            onChange={setGpFormValues}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/email.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <OutlinedInput
                            className="outline"
                            placeholder="Address"
                            name="address"
                            fullWidth
                            value={gpValues ? gpValues['address'] : ''}
                            onChange={setGpFormValues}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            className="outline"
                            placeholder="Contact No."
                            name="telephone"
                            fullWidth
                            value={gpValues ? gpValues['telephone'] : ''}
                            onChange={setGpFormValues}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/phone.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6} style={{ marginTop: 5 }}>
                          <OutlinedInput
                            className="outline"
                            placeholder="GP Practice Name"
                            name="gPPracticeName"
                            fullWidth
                            value={gpValues ? gpValues['gPPracticeName'] : ''}
                            onChange={setGpFormValues}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ marginTop: 20 }}>
                        <Button
                          variant={'contained'}
                          onClick={handleGpSubmit}
                          style={{
                            height: 30,
                            width: 140,
                            borderRadius: 50,
                            fontSize: 12,
                            fontWeight: 400,
                            color: 'white',
                            alignSelf: 'center',
                            margin: 'auto',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          Refer to GP
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <h1
                style={{
                  fontSize: 17,
                  marginTop: 30,
                  fontWeight: 'bold',
                  color: '#61A8F1',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Healthy Living Advice
              </h1>

              <div style={{ marginBottom: 25 }}>
                <FormGroup>
                  {healthyLivingAdviceEntities.map((label, key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          size="small"
                          style={{ borderColor: 'lightgray' }}
                          value={label.name}
                          name={'healthyLivingAdvice'}
                          checked={medicineValues['healthyLivingAdvice'].some(
                            healthyLivingAdviceType => healthyLivingAdviceType.id === label.id
                          )}
                          onChange={e => handleHealthyLivingAdvice(e, label)}
                        />
                      }
                      label={label.name}
                    />
                  ))}
                </FormGroup>
                {showHealthyLivingAdviceOther && (
                  <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                    <OutlinedInput
                      style={{
                        border: fieldErrorMedicine['healthyLivingAdviceOther'] ? '1px solid red' : '1px solid lightgray',
                        borderRadius: 5,
                        height: 35,
                        width: 600,
                        marginTop: 0,
                      }}
                      value={medicineValues ? medicineValues['healthyLivingAdviceOther'] : ''}
                      placeholder="Please Indicate"
                      name={'healthyLivingAdviceOther'}
                      inputProps={{ style: { fontSize: 13 } }}
                      onChange={handleHealthyLivingAdviceOther}
                    />
                  </FormControl>
                )}
              </div>
              <h1
                style={{
                  fontSize: 17,
                  marginTop: 10,
                  marginBottom: 15,
                  fontWeight: 'bold',
                  color: '#61A8F1',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Intervention Note
              </h1>
              <OutlinedInput
                className="outline"
                placeholder="Intervention Note"
                name="interventionNote"
                fullWidth
                value={medicineValues ? medicineValues['interventionNote'] : ''}
                onChange={setFormValuesMedicine}
                inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
              />
              {deoSessionEntity?.sessionStatus?.id === NMS_STATUS.IN_PROGRESS &&
                deoSessionEntity?.prevSessionType.id === NMS_TYPES.FOLLOW_UP && (
                  <>
                    <h1
                      style={{
                        color: '#246EBA',
                        fontSize: 17,
                        marginTop: 25,
                        marginLeft: 0,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Follow Up Date
                    </h1>

                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'start' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            label="Select Date"
                            value={medicineValues ? dayjs(medicineValues['followUpDate'], 'YYYY-MM-DD') : null}
                            onChange={newValue => handleFollowUpDateUpdate(newValue)}
                            sx={{
                              width: 220,
                              marginTop: 0,
                              marginLeft: 0,
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </>
                )}

              {deoSessionEntity?.sessionStatus?.id === NMS_STATUS.COMPLETED &&
                deoSessionEntity?.prevSessionType.id !== NMS_TYPES.FOLLOW_UP && (
                  <>
                    <h1
                      style={{
                        fontSize: 17,
                        marginTop: 40,
                        marginBottom: 5,
                        fontWeight: 'bold',
                        color: '#61A8F1',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Follow Up declined by patient?
                    </h1>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={medicineValues ? medicineValues['followUpDeclined'] : null}
                          onChange={handleFollowUpDeclined}
                          name="followUpDeclined"
                        >
                          <div style={{ display: 'flex', marginTop: 10, fontFamily: 'Roboto, sans-serif' }}>
                            <FormControlLabel
                              value={'true'}
                              control={<Radio size="small" />}
                              label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>Yes</Typography>}
                            />

                            <FormControlLabel
                              value={'false'}
                              control={<Radio size="small" />}
                              label={<Typography style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>No</Typography>}
                              style={{ marginLeft: 30 }}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {declinedPromptShow ? (
                      <TextField
                        style={{ marginTop: '3%', marginBottom: '5%' }}
                        className="outline"
                        placeholder="Type reason for follow up decline."
                        name="declinedReason"
                        fullWidth
                        value={medicineValues ? medicineValues['declinedReason'] : ''}
                        onChange={handleFollowUpDeclinedReason}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                        helperText="Example: include going on holiday, upcoming appointment with healthcare professional, language barriers, no longer using medicine, using medicine when required."
                        FormHelperTextProps={{
                          style: {
                            color: 'grey',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            marginLeft: 0,
                            marginTop: 10,
                          },
                        }}
                      />
                    ) : (
                      <div>
                        <h1
                          style={{
                            fontSize: 20,
                            color: '#246EBA',
                            marginTop: 30,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          Reason?
                        </h1>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={medicineValues ? medicineValues['notDeclinedByPatient'] : ''}
                            name="notDeclinedByPatient"
                            style={{ marginTop: 5 }}
                            onChange={handleNotDeclinedByPatient}
                          >
                            <FormControlLabel
                              style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                              value={'referred_to_gp'}
                              control={<Radio size="small" />}
                              label={'Referred to GP'}
                            />
                            <FormControlLabel
                              style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                              value={'medication_stopped_no_follow_up'}
                              control={<Radio size="small" />}
                              label={'Medication stopped by prescriber - follow up not required'}
                            />
                            <FormControlLabel
                              style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                              value={'medication_changed'}
                              control={<Radio size="small" />}
                              label={'Medication changed'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </>
                )}
              <div>
                {deoSessionEntity?.sessionStatus?.id === NMS_STATUS.COMPLETED &&
                  deoSessionEntity?.prevSessionType.id === NMS_TYPES.FOLLOW_UP &&
                  showMedicineRelatedContent &&
                  followUpHtml()}
              </div>
              <div style={{ marginBottom: 40, marginTop: 40 }}>
                <Button
                  variant={'contained'}
                  style={{
                    height: 30,
                    width: 185,
                    borderRadius: 50,
                    fontSize: 12,
                    fontWeight: 400,
                    color: 'white',
                    alignSelf: 'center',
                    margin: 'auto',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                  onClick={submitUpdateMedicineSection}
                >
                  Update Medicine Detail
                </Button>
              </div>
            </Grid>
          )}

          <div style={{ display: 'flex', width: 350, justifyContent: 'center', marginBottom: '5px', marginTop: '15px' }}>
            <IconButton
              style={{
                backgroundColor: '#EFEFEF',
                marginLeft: 650,
                height: 40,
                width: 170,
                border: '1px solid lightgray',
                padding: '10px',
                display: 'flex',
                borderRadius: 3,
                justifyContent: 'space-around',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleRedirect}
            >
              <div style={{ height: 28, width: 210, padding: '7px', display: 'flex', borderRadius: 3 }}>
                <img src={'../../../../content/assets/no-svgrepo-com.png'} alt="logo" style={{ height: '18px', marginLeft: 25 }} />
                <h1
                  style={{
                    fontSize: 15,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 400,
                    alignSelf: 'center',
                    color: 'black',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Cancel
                </h1>
              </div>
            </IconButton>
            <IconButton
              style={{
                backgroundColor: '#21AD64',
                marginLeft: 20,
                height: 40,
                width: 170,
                border: '1px solid lightgray',
                padding: '10px',
                display: 'flex',
                borderRadius: 3,
                justifyContent: 'space-around',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={submitSection}
            >
              <div style={{ height: 28, width: 210, padding: '7px', display: 'flex', borderRadius: 3 }}>
                <img src={'../../../../content/assets/circle-tick-svgrepo-com.png'} alt="logo" style={{ height: '18px', marginLeft: 25 }} />
                <h1
                  style={{
                    fontSize: 15,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 400,
                    alignSelf: 'center',
                    color: 'white',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  {!isEdit ? 'Add More' : 'Save'}
                </h1>
              </div>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlankSheetFormComp;
