import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  getAllDeoOverdueSessions,
  getAllDeoOverviewSessions,
  getAllDeoSessionsCurrentDateStats,
  getAllDeoUploadedFileSessions,
  getAllPharmacies,
  getManagePharmacyListing,
  getDeletedSessions,
  getDeletedSessionsFiles,
  permanentDeleteUnSubmittedFiles,
  getRecordsStatsForDeo,
} from '../deo-reducer';
import {
  IDeoOverdueSessionParam,
  IDeoOverviewSessionParam,
  IDeoSessionStatsParam,
  IDeoUploadedFileParam,
  IDeoManagePharmacyFileParam,
  IDeoFileParam,
  IDeoRecordSessionParam,
} from '../interface';
import {
  downloadExcelPharmacyNMSRecords,
  getAllServicePharmacist,
  getAllSessionsListingWithDateRange,
  getSessionStatuses,
} from 'app/modules/admin-work-flow/admin-reducer';
import {
  deoSessionStatsCurrentDate,
  interventionData,
  isValidDate,
  pharmacyNMS,
  pharmacyWithPharmacistNMS,
  s2ab,
} from 'app/shared/util/utits';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getGroupNames } from '../../admin-work-flow/admin-reducer';
import { getAllPharmacyByUserIdList } from 'app/modules/contractor-workflow/client-reducer';
import { toast } from 'react-toastify';

const intialState: IDeoSessionStatsParam = {
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  month: '',
  from: '',
  to: '',
};
const intialStateOverview: IDeoOverviewSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};
const intialStateOverdue: IDeoOverdueSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};
const intialStateUploadedFile: IDeoUploadedFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
};
const intialStateManagePharmacyFile: IDeoManagePharmacyFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
};

const intialStateFile: IDeoFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
};

const intialStateRecord: IDeoRecordSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};

const DailySummaryHook = () => {
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [searchCreteria, setsearchCreteria] = useState<IDeoSessionStatsParam>(intialState);
  const allPharmacies = useAppSelector(state => state.admin.allPharmacies);
  const allPharmaciesOfUser = useAppSelector(state => state.client.allUserPharmacies);

  const allServicesPharmacist = useAppSelector(state => state.admin.allServicePharmacist);
  const allDeoSessionStatsCurrentDate = useAppSelector(state => state.deo.deoSessionStats);
  const { isManagerDEO } = useAppSelector(state => state.authentication);
  const allGroupNames = useAppSelector(state => state.admin.allGroupNames);

  const [searchCreteriaOverview, setsearchCreteriaOverview] = useState<IDeoOverviewSessionParam>(intialStateOverview);
  const [searchCreteriaOverdue, setsearchCreteriaOverdue] = useState<IDeoOverdueSessionParam>(intialStateOverdue);
  const [searchCreteriaUploaded, setsearchCreteriaUploaded] = useState<IDeoUploadedFileParam>(intialStateUploadedFile);
  const [searchCreteriaManagePharmacy, setsearchCreteriaManagePharmacy] =
    useState<IDeoManagePharmacyFileParam>(intialStateManagePharmacyFile);

  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [searchCreteriaFile, setsearchCreteriaFile] = useState<IDeoFileParam>(intialStateFile);
  const [searchCreteriaRecord, setsearchCreteriaRecord] = useState<IDeoRecordSessionParam>(intialStateRecord);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem('role');
  const [checkedTabs, setCheckedTabs] = useState([]); // State to store checked tabs

  const handleTabClick = heading => {
    setCheckedTabs(prevState => {
      // Check if the heading is already checked
      const isHeadingChecked = prevState.includes(heading);

      if (isHeadingChecked) {
        // Remove the heading if it's already checked
        return prevState.filter(tab => tab !== heading);
      } else {
        // Add the heading if it's not checked
        return [...prevState, heading];
      }
    });
    // The `checkedTabs` update is asynchronous, so it won't reflect the current change immediately here
    // You can move this log inside a `useEffect` to see the updated state
  };

  useEffect(() => {
    dispatch(getGroupNames());
  }, []);

  const handleExcelFileStats = useCallback(async () => {
    let params = {};
    params = {
      ...searchCreteria,
    };
    console.log({ params });
    const response: any = await dispatch(getAllDeoSessionsCurrentDateStats(params));

    if (response.type === 'pharmacy/fetch_all_deo_session_stats_current_date/fulfilled') {
      const {
        results: { results: excelDataList },
      } = response.payload.data;
      const stats = excelDataList;
      const data = [];

      const addPharmacyStats = (stat, showCombined) => {
        data.push({
          Pharmacy: showCombined ? '--' : stat.pharmacy || '--',
          '': '',
          'Pharmacy Stats': moment().format('DD MMMM, YYYY') || '--',
        });
        const addStatRow = (heading, value) => {
          // If checkedTabs is empty, add all rows
          if (checkedTabs.length === 0 || checkedTabs.includes(heading)) {
            data.push({
              Pharmacy: heading,
              '': value,
              'Pharmacy Stats': '',
            });
          }
        };
        data.push({
          Pharmacy: 'Total Data',
          '':
            Number(stat.completed_nms) +
            Number(stat.no_answers) +
            Number(stat.no_consents) +
            Number(stat.not_new_medicine) +
            Number(stat.intervention_pending) +
            Number(stat.follow_up_pending) +
            Number(stat.patient_left) +
            Number(stat.incorrect_number) +
            Number(stat.intervention_not_delivered) +
            Number(stat.not_eligible),
        });

        addStatRow('Completed NMS', Number(stat.completed_nms));
        addStatRow('Completed Interventions', Number(stat.completed_intervention));
        addStatRow('Completed Follow-ups', Number(stat.completed_follow_up));
        addStatRow('Pending Interventions', stat.intervention_pending);
        addStatRow('Pending Follow-ups', stat.follow_up_pending);
        addStatRow('No Answers', stat.no_answers);
        addStatRow('No Consents', stat.no_consents);
        addStatRow('Not New Medicine', stat.not_new_medicine);
        addStatRow('Not Eligible Conditions', stat.not_eligible);
        addStatRow('Patient Left Pharmacy', stat.patient_left);
        addStatRow('Incorrect Number', stat.incorrect_number);
        addStatRow('Intervention Not Delivered', stat.intervention_not_delivered);
        data.push({ Pharmacy: '', '': '', 'Pharmacy Stats': '' }, { Pharmacy: '', '': '', 'Pharmacy Stats': '' });

        // data.push(
        //   {
        //     Pharmacy: 'Total Data',
        //     '':
        //       Number(stat.completed_nms) +
        //       // Number(stat.completed_follow_ups) +
        //       Number(stat.no_answers) +
        //       Number(stat.no_consents) +
        //       Number(stat.not_new_medicine) +
        //       Number(stat.intervention_pending) +
        //       Number(stat.follow_up_pending) +
        //       Number(stat.patient_left) +
        //       Number(stat.incorrect_number) +
        //       Number(stat.intervention_not_delivered) +
        //       Number(stat.not_eligible),
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Completed NMS',
        //     '': Number(stat.completed_nms),
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Completed Interventions',
        //     '': Number(stat.completed_intervention),
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Completed Follow-ups',
        //     '': Number(stat.completed_follow_up),
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Pending Intervention',
        //     '': stat.intervention_pending,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Pending Follow-ups',
        //     '': stat.follow_up_pending,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'No Answers',
        //     '': stat.no_answers,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'No Consents',
        //     '': stat.no_consents,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Not New Medicine',
        //     '': stat.not_new_medicine,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Not Eligible Condition',
        //     '': stat.not_eligible,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Patient Left Pharmacy',
        //     '': stat.patient_left,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Incorrect Number',
        //     '': stat.incorrect_number,
        //     'Pharmacy Stats': '',
        //   },
        //   {
        //     Pharmacy: 'Intervention Not Delivered',
        //     '': stat.intervention_not_delivered,
        //     'Pharmacy Stats': '',
        //   },
        //   { Pharmacy: '', '': '', 'Pharmacy Stats': '' },
        //   { Pharmacy: '', '': '', 'Pharmacy Stats': '' }
        // );
      };

      const showCombined = Array.isArray(searchCreteria.pharmacy) && searchCreteria.pharmacy.length > 1;

      if (searchCreteria.group && searchCreteria.pharmacy === '') {
        addPharmacyStats(stats[0], false);
      } else if (Object.values(searchCreteria).every(value => value === '')) {
        addPharmacyStats(stats[0], true);
      } else {
        stats.forEach((stat, index) => {
          addPharmacyStats(stat, showCombined && index === 0);
        });
      }

      const ws = XLSX.utils.json_to_sheet(data);

      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef] ? ws[cellRef].v.toString() : '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Today Stats');

      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = moment().format('DD MMMM, YYYY') + ' - Stats.xlsx'; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria, checkedTabs]);

  const handleSelectPharmacy = event => {
    const { value } = event.target;

    let updatedSelections;

    if (Array.isArray(value) && value.includes('All Pharmacies')) {
      updatedSelections = [];
    } else {
      updatedSelections = value;
    }

    setSelectedPharmacies(updatedSelections);

    const params = {
      ...searchCreteria,
      pharmacy:
        role === '"CLIENT"' && (updatedSelections.length === 0 || value.includes('All Pharmacies'))
          ? allPharmaciesOfUser?.map(pharmacy => pharmacy?.id)
          : updatedSelections.length > 0
          ? updatedSelections
          : '',
    };

    setsearchCreteria(params);
    dispatch(getAllDeoSessionsCurrentDateStats(params));
  };

  const handleSelectBranch = event => {
    const value = event.target.value;
    const groupId = value === 'All Groups' ? '' : value;
    setSelectedGroupId(groupId);

    // Clear selected pharmacies when a new group is selected
    setSelectedPharmacies([]);

    const params = {
      ...searchCreteria,
      group: groupId,
      pharmacy: '',
    };
    setsearchCreteria(params);
    dispatch(getAllDeoSessionsCurrentDateStats(params));
  };

  const handleSelectMonth = event => {
    const value = event.target.value;
    const month = value === 'All Months' ? '' : value;

    const params = {
      ...searchCreteria,
      month,
    };
    dispatch(getAllDeoSessionsCurrentDateStats(params));
    setsearchCreteria(params);
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllDeoSessionsCurrentDateStats(params));
  };

  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllDeoSessionsCurrentDateStats(params));
  };

  const filteredPharmacies = selectedGroupId
    ? allPharmacies.filter(pharmacy => pharmacy.pharmacyGroup && pharmacy.pharmacyGroup.id === selectedGroupId)
    : allPharmacies;
  const handleSelectService = event => {
    let value = event.target.value;
    value = value === 'All Service Pharmacists' ? '' : value;
    const params = {
      ...searchCreteria,
      pharmacist: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      pharmacist: value,
    });
    dispatch(getAllDeoSessionsCurrentDateStats(params));
  };
  const getAllPharmacy = useCallback(() => {
    dispatch(getAllPharmacies());
  }, []);

  const getAllPharmacist = useCallback(() => {
    dispatch(getAllServicePharmacist());
  }, []);

  const getAllSessionStatuses = useCallback(() => {
    dispatch(getSessionStatuses());
  }, []);
  const getRecordsStats = useCallback(() => {
    dispatch(getRecordsStatsForDeo());
  }, []);

  const getAllDeoSessionStatsCurrentDate = useCallback(() => {
    dispatch(getAllDeoSessionsCurrentDateStats(searchCreteria));
  }, []);

  const handleRefresh = useCallback(async () => {
    setCheckedTabs([]);
    setSelectedPharmacies([]);
    setsearchCreteria({
      ...intialState,
      pharmacist: '',
      pharmacy: '',
      branch: '',
      group: '',
      month: '',
      from: '',
      to: '',
    });
    if (role === '"CLIENT"') {
      const params = {
        ...searchCreteria,
        pharmacy: allPharmaciesOfUser?.map(pharmacy => pharmacy?.id).join(','),
      };
      setsearchCreteria({
        pharmacist: '',
        pharmacy: allPharmaciesOfUser?.map(pharmacy => pharmacy?.id).join(','),
        branch: '',
        group: '',
        month: '',
        from: '',
        to: '',
      });
      await dispatch(getAllDeoSessionsCurrentDateStats(params));
    } else {
      await dispatch(getRecordsStatsForDeo());
      await dispatch(getAllDeoSessionsCurrentDateStats(searchCreteria));
    }
  }, [allPharmaciesOfUser || allPharmacies]);

  const fetchAllUserPharmacy = useCallback(async () => {
    await dispatch(getAllPharmacyByUserIdList())
      .then(res => {
        const list = res.payload['data'];

        if (list.length > 0) {
          const params = {
            ...searchCreteria,
            pharmacy: list?.map(pharmacy => pharmacy?.id).join(','),
          };
          setsearchCreteria(params);
          dispatch(getAllDeoSessionsCurrentDateStats(params));
        }
      })
      .catch(error => {
        toast.error('Failed to list contractor pharmacies');
      });
  }, []);

  useEffect(() => {
    if (role === '"CLIENT"') {
      fetchAllUserPharmacy();
    } else {
      getAllPharmacy();
      getAllSessionStatuses();
      getAllPharmacist();
      getRecordsStats();
      getAllDeoSessionStatsCurrentDate();
    }
  }, []);

  const states = {
    allPharmacies,
    allPharmaciesOfUser,
    allServicesPharmacist,
    searchCreteria,
    allDeoSessionStatsCurrentDate,
    selectedPharmacies,
    allGroupNames,
    filteredPharmacies,
    checkedTabs,
  };

  const handlers = {
    handleSelectService,
    handleSelectPharmacy,
    handleSelectBranch,
    handleExcelFileStats,
    handleRefresh,
    handleSelectMonth,
    handledateFrom,
    handledateTill,
    handleTabClick,
  };
  return {
    states,
    handlers,
  };
};
export default DailySummaryHook;
