import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import '../rich-data-table/admin-dashboard.scss';
import AdminManagePharmacy from '../admin-manage-pharmacy/admin-manage-pharmacy';
export const ManagePharmacistDashboard = props => {
  const { state }: any = useLocation();
  const role = localStorage.getItem('role');
  const { sessionByPharmacyEntities, sessionCounts, sessionTypeValue, sessionStatusValue, rowsPer, pageNumber, isAdmin, isPharmacist } =
    props;
  const page = useAppSelector(sta => sta.session.pageNo);
  const rowsPerPage = useAppSelector(sta => sta.session.rows);
  const [showMenu, setShowMenu] = useState(false);
  const [index, setIndex] = useState(1);
  const [showOptions, setShowOptions] = useState(false);
  const [values, setValues] = useState({ service_id: 1, session_type: null, session_status: null });
  const [selectedColor, setSelectedColor] = useState(state ? state.selectedColor : 'one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(sta => sta.authentication.account);
  const pharmacyName = localStorage.getItem('pharmacy_name');
  const [showadmin, setShowadmin] = useState(false);
  useEffect(() => {
    if (state) {
      switch (state.selectedColor) {
        case 'one':
          setIndex(1);
          break;
        default:
          setIndex(1);
      }
      // setSelectedColor(selectedColor);

      setSelectedColor(state.selectedColor);
      setIndex(state.index);
    }
  }, [state]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };
  const handleTabClick = (color, idx) => {
    setSelectedColor(color);
    setIndex(idx);
  };
  return (
    <Grid lg={12} container style={{ height: '92.3vh' }}>
      <Grid lg={2.3} xs={12} sm={3} container style={{ backgroundColor: '#325C92', justifyContent: 'center' }}>
        {showMenu === false ? (
          <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%', backgroundColor: '' }}>
            <List
              aria-label="Sidebar"
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
                [`& .${listItemDecoratorClasses.root}`]: {
                  justifyContent: 'space-between',
                  pr: '18px',
                },
                '& [role="button"]': {
                  borderRadius: '0 2px 2px 0',
                },
                height: 300,
              }}
            >
              <ListItem
                onClick={() => {
                  handleTabClick('one', 1);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={1}
                  selected={index === 1}
                  style={{ backgroundColor: selectedColor === 'one' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('one');
                  }}
                >
                  <img
                    data-index={1}
                    src={'../../../../content/assets/managepharmacy.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '18px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={1}
                    sx={{ fontWeight: '', color: selectedColor === 'one' ? '#7CB6F8' : '#325C92', fontSize: 13, marginLeft: 1 }}
                  >
                    <span style={{ color: 'white' }} data-index={1}>
                      Manage Pharmacy
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </List>

            <div className="logout_div">
              <img src={'../../../../content/assets/profile1.png'} alt="logo" style={{ height: '25px' }} onClick={() => {}} />

              <div
                style={{
                  justifyContent: 'space-between',
                  marginLeft: 5,
                  width: '70%',
                  overflow: 'auto',
                  alignSelf: 'center',
                  marginTop: 8,
                }}
              >
                <div>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
                <h1 className="logout_text"> {role === null || role === 'null' ? '' : role.replace(/"/g, '').replace(/^ROLE_/, '')}</h1>
                <h1 className="logout_text">{currentUser && currentUser['login']}</h1>
              </div>
              <IconButton
                onClick={handleClick}
                style={{
                  backgroundColor: '',
                  marginRight: 5,
                  height: 30,
                  width: 20,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/threedots.png'} alt="logo" style={{ height: '25px' }} />
              </IconButton>
            </div>
          </Grid>
        ) : (
          []
        )}
      </Grid>
      {index === 1 && selectedColor === 'one' && (
        <>
          <AdminManagePharmacy />
        </>
      )}
    </Grid>
  );
};
export default ManagePharmacistDashboard;
