import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import './login.scss';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { agreement, handleLocumLogin, logout } from 'app/shared/reducers/authentication';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import ListItem from '@mui/joy/ListItem';
import PersonIcon from '@mui/icons-material/Person';
import { DoDisturb, Done, KeyboardArrowRight, Tag } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LocumModal = props => {
  const { account } = useAppSelector(state => state.authentication);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({ first_name: '', gphc_number: '' });
  const label = { inputProps: { 'aria-label': '' } };
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked); // Update checkbox state
  };
  const [fieldError, setFieldError] = useState({
    first_name: false,
    last_name: false,
    gphc_number: false,
  });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const setAgreement = () => {
    if (isChecked) {
      const param = {
        id: account?.id,
        agreement: isChecked,
      };
      dispatch(agreement(param));
      setOpen(false);
      navigate('/');
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(logout());
    navigate('/login');
  };

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      first_name: account && account.firstName,
      last_name: account && account.lastName,
    }));
    setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: true }));
  }, [account]);

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'first_name' || e.target.name === 'gphc_number') {
      checkSingleFieldValidations(e);
    }
  };
  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['first_name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['first_name']: true }));
    }

    if (values['gphc_number'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: true }));
    }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['first_name'] &&
      !fieldError['gphc_number'] &&
      values['first_name'] !== undefined &&
      values['gphc_number'] !== undefined
    ) {
      setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: false }));
      return true;
    } else {
      return false;
    }
  };

  const handleLocumLoginDetails = () => {
    if (isAllowedToSubmit() && account && account.id) {
      const appUserId = account.id;
      dispatch(handleLocumLogin(appUserId, values.first_name, null, values.gphc_number));
    }
  };
  useEffect(() => {
    if (localStorage.getItem('locumVerified')) {
      setOpen(true);
    } else {
      navigate('/login/gphc');
    }
  }, [localStorage.getItem('locumVerified')]);

  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLocumLoginDetails();
    }
  };
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`, // Ensure the URL is correct
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
              <ArrowBackIcon onClick={logoutUser} />
            </Box>
            <img src={'../../../content/logos/final-logo.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight={'600'} variant="h6">
              Welcome, {values.first_name}
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Please enter your GPHC No.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }} onKeyDown={handleKeyDown}>
              {/*<TextField*/}
              {/*  margin="normal"*/}
              {/*  required*/}
              {/*  fullWidth*/}
              {/*  name="firstname"*/}
              {/*  label="First Name"*/}
              {/*  value={values ? values.first_name : ''}*/}
              {/*  type="text"*/}
              {/*  error={fieldError['first_name']}*/}
              {/*  onChange={setFormValues}*/}
              {/*  id="firstname"*/}
              {/*  autoComplete="first_name"*/}
              {/*  InputLabelProps={{*/}
              {/*    shrink: true,*/}
              {/*  }}*/}
              {/*/>*/}
              <TextField
                margin="normal"
                required
                fullWidth
                autoFocus={true}
                name="gphc_number"
                placeholder="GPHC Number"
                error={fieldError['gphc_number']}
                onChange={setFormValues}
                type="text"
                id="gphcnumber"
                autoComplete="gphc_number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tag />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={'button-height'}
                sx={{ mt: 2, mb: 1, position: 'relative' }}
                onClick={handleLocumLoginDetails}
              >
                Continue
                <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
              </Button>
            </Box>
          </Box>
        </Container>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="agreement-dialog-description"
          maxWidth="lg" // Increase maxWidth to 'lg' for a larger default width
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              width: '90%', // Set to 90% of the screen width or adjust as needed
              maxWidth: '1200px', // Set a maximum width limit
            },
          }}
        >
          <DialogTitle sx={{ color: '#236EBA', fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>
            {'Service Pharmacist Agreement'}
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
              This Agreement outlines the Service Pharmacist’s commitment to adhering to the following terms and conditions necessary for
              the provision of the New Medicines Service (NMS) in compliance with NHS guidelines.
            </Typography>

            {/* Section 1 */}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              1. Responsibilities of the Service Pharmacist
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>1.1 Familiarisation with Pharmacy Information</strong>
              <br />
              As Service Pharmacist, I agree to review and familiarise myself with the designated pharmacy’s name and address before
              initiating any patient communications.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>1.2 Compliance with NHS NMS Service Specifications</strong>
              <br />
              As Service Pharmacist, I agree to strictly adhere to the NHS New Medicines Service (NMS) specifications for the entirety of my
              practice under this Agreement.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>1.3 Adherence to Pharmacy Solutions SOPs</strong>
              <br />
              As Service Pharmacist, I agree to comply with all Standard Operating Procedures (SOPs) as defined by Pharmacy Solutions.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>1.4 Indemnity Insurance</strong>
              <br />
              As Service Pharmacist, I confirm that I hold valid and appropriate indemnity insurance to conduct the New Medicines Service
              legally and safely under this Agreement.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>1.5 Completion of CPPE Training</strong>
              <br />
              As Service Pharmacist, I confirm that I have completed the Centre for Pharmacy Postgraduate Education (CPPE) training
              specifically for New Medicines Service consultations.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>1.6 Patient Confidentiality</strong>
              <br />
              As Service Pharmacist, I commit to maintaining patient confidentiality at all times in accordance with applicable privacy laws
              and ethical standards.
            </Typography>

            {/* Section 2 */}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              2. Payment Conditions
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>2.1</strong> As Service Pharmacist, I understand that any New Medicines Service (NMS) that fails to conform to the
              service specifications will be ineligible for payment.
            </Typography>

            {/* Section 3 */}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              3. Compliance and Fraud Prevention
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>3.1</strong> As Service Pharmacist, I acknowledge that any fraudulent actions or NMS consultations that do not comply
              with NHS service specifications may be reported to the appropriate authorities.
            </Typography>

            {/* Section 4 */}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              4. Documentation and Record-Keeping
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>4.1</strong> As Service Pharmacist, I confirm my commitment to creating accurate and detailed consultation notes for
              each NMS consultation conducted under this Agreement.
            </Typography>

            {/* Section 5 */}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              5. Term and Termination
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, pl: 2 }}>
              <strong>5.1</strong> This Agreement remains in effect from the date of Acknowledgement and Agreement until terminated by
              either party, with written notice provided to the other party.
            </Typography>

            {/* Acknowledgement */}
            <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 'bold', textAlign: 'center' }}>
              Acknowledgement and Agreement
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
              <Checkbox {...label} size="small" checked={isChecked} onChange={handleCheckboxChange} />
              By clicking on the "Accept" button, I acknowledge and agree to all terms and conditions outlined in this Agreement.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              sx={{
                backgroundColor: '#E90016',
                borderRadius: '4px',
                padding: '8px 16px',
                fontSize: 14,
                width: 150,
                height: 40,
                '&:hover': { backgroundColor: '#b80013' },
              }}
            >
              <DoDisturb sx={{ mr: 1, fontSize: 16 }} />
              Decline
            </Button>
            <Button
              onClick={setAgreement}
              disabled={!isChecked}
              variant="contained"
              sx={{
                backgroundColor: '#45C12B',
                borderRadius: '4px',
                padding: '8px 16px',
                fontSize: 14,
                width: 150,
                height: 40,
                '&:hover': { backgroundColor: '#38a623' },
              }}
            >
              Accept
              <Done sx={{ ml: 1, fontSize: 16 }} />
            </Button>
          </DialogActions>
        </Dialog>

        {/*<Dialog*/}
        {/*  open={open}*/}
        {/*  TransitionComponent={Transition}*/}
        {/*  keepMounted*/}
        {/*  onClose={handleClose}*/}
        {/*  aria-describedby="alert-dialog-slide-description"*/}
        {/*  maxWidth="md" // Set a reasonable maxWidth, 'md' stands for medium*/}
        {/*  fullWidth // Ensure the dialog uses full width*/}
        {/*>*/}
        {/*  <DialogTitle sx={{ color: '#236EBA' }}>{'Agreement.'}</DialogTitle>*/}
        {/*  <DialogContent>*/}
        {/*    <DialogContentText id="alert-dialog-slide-description">*/}
        {/*      <List>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>1. </Typography>{' '}*/}
        {/*          <ListItemText primary="Please familiarise yourself with the Pharmacy name and address prior to calling the patients" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>2. </Typography>*/}
        {/*          <ListItemText primary=" I confirm I will adhere to NHS New Medicines Service Service specification" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>3. </Typography>*/}
        {/*          <ListItemText primary=" I confirm I will adhere to Pharmacy Solutions SOP's" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>4. </Typography>*/}
        {/*          <ListItemText primary=" I confirm I have appropriate Indemnity insurance in place to conduct the new medicines service" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>5. </Typography>*/}
        {/*          <ListItemText primary=" I confirm I have conducted the CPPE training for the New Medicines Service consultation" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>6. </Typography>*/}
        {/*          <ListItemText primary=" I confirm I will maintain patient confidentiality throughout my practice" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>7. </Typography>*/}
        {/*          <ListItemText primary=" Any NMS I can conduct not in line with the service specification will not be paid" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>8. </Typography>*/}
        {/*          <ListItemText primary=" Any NMS which are conducted fraudulently or do not adhere to the service specification will be reported" />*/}
        {/*        </ListItem>*/}
        {/*        <ListItem>*/}
        {/*          <Typography className={'list-count'}>9. </Typography>*/}
        {/*          <ListItemText primary=" I confirm I will write accurate and detailed intervention notes" />*/}
        {/*        </ListItem>*/}
        {/*      </List>*/}
        {/*    </DialogContentText>*/}
        {/*  </DialogContent>*/}
        {/*  <DialogActions>*/}
        {/*    <Button*/}
        {/*      onClick={handleClose}*/}
        {/*      variant="contained"*/}
        {/*      color="error"*/}
        {/*      sx={{*/}
        {/*        backgroundColor: '#E90016',*/}
        {/*        borderRadius: '2px', // make the button a perfect circle*/}
        {/*        padding: '10px 20px',*/}
        {/*        fontSize: 12,*/}
        {/*        width: 150, // set the width of the button*/}
        {/*        height: 40, // set the height of the button*/}
        {/*        position: 'relative',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />*/}
        {/*      Decline*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      onClick={agreement}*/}
        {/*      variant="contained"*/}
        {/*      sx={{*/}
        {/*        backgroundColor: '#45C12B',*/}
        {/*        borderRadius: '2px', // make the button a perfect circle*/}
        {/*        padding: '10px 20px',*/}
        {/*        fontSize: 12,*/}
        {/*        width: 150, // set the width of the button*/}
        {/*        height: 40, // set the height of the button*/}
        {/*        position: 'relative',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Accept*/}
        {/*      <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />*/}
        {/*    </Button>*/}
        {/*  </DialogActions>*/}
        {/*</Dialog>*/}
      </Grid>
    </Grid>
  );
};

export default LocumModal;
