import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IPharmacySessionList } from '../../admin-work-flow/interface';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useNavigate } from 'react-router-dom';
import {
  downloadAllSessionListForPharmacy,
  getAllClientStats,
  getAllPharmacyByUserIdList,
  getAllSessionOfPharmacyWithDateRange,
} from '../client-reducer';
import { generateExcelData, interventionData, isValidDate, s2ab } from 'app/shared/util/utits';
import * as XLSX from 'xlsx';
import moment from 'moment/moment';
import { NMS_TYPES } from 'app/config/constants';
import { downloadExcelFileRecords } from 'app/modules/admin-work-flow/admin-reducer';

const intialState: IPharmacySessionList = {
  size: 20,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
  month: '',
};

const useClientDashboard = () => {
  const pageNo = useAppSelector(state => state.client.page);
  const limit = useAppSelector(state => state.client.rows);
  const totalCount = useAppSelector(state => state.client.count);
  const allPharmacies = useAppSelector(state => state.client.allUserPharmacies);
  const allSessionPharmacyListingIndex = useAppSelector(state => state.client.results);
  const allSessionPharmacyListing = allSessionPharmacyListingIndex?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const countTotalIntervention = useAppSelector(state => state.client.totalCountIntervention);
  const countTotalFollowUp = useAppSelector(state => state.client.totalCountFollowUp);
  const countTotal = useAppSelector(state => state.client.totalCount);
  const [downloadData, setDownloadData] = useState([]);
  const [searchCreteria, setsearchCreteria] = useState<IPharmacySessionList>(intialState);
  const [selectedPharmacy, setPharmacy] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSelectMonth = event => {
    let value = event.target.value;
    value = value === 'All Months' ? '' : value;
    const params = {
      ...searchCreteria,
      month: value,
      pharmacy: selectedPharmacy,
    };
    setsearchCreteria({
      ...searchCreteria,
      month: value,
    });
    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };

  const emptyRowCount = 14;
  const emptyCellCount = 6;

  const allUserPharmacy = useMemo(() => {
    return allPharmacies.map(elem => {
      return {
        value: elem.id,
        label: elem.name,
        branch_name: elem.branch_name,
        postal_code: elem.postal_code,
      };
    });
  }, [allPharmacies]);

  const customFilterOption = (option, inputValue) => {
    const searchString = inputValue.toLowerCase();
    const label = option.data.label.toLowerCase();
    const branch_name = option.data?.branch_name?.toLowerCase();
    const postal_code = option.data?.postal_code?.toLowerCase();

    return label.includes(searchString) || branch_name.includes(searchString) || postal_code.includes(searchString);
  };

  const handleChangePage = (event, newPage) => {
    const params = {
      ...searchCreteria,
      page: newPage,
      pharmacy: selectedPharmacy,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: newPage,
    });
    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };

  const handleChangeRowsPerPage = event => {
    const pagePerRow = event.target.value;
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
      pharmacy: selectedPharmacy,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
      pharmacy: selectedPharmacy,
    };

    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      from: date,
      pharmacy: selectedPharmacy,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };
  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
      pharmacy: selectedPharmacy,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };

  const handleRole = options => {
    const value = options ? options.value : '';
    setPharmacy(value);
    setsearchCreteria({
      ...searchCreteria,
      pharmacy: options,
    });

    const params = {
      ...searchCreteria,
      pharmacy: value,
    };
    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  };

  const handleRefresh = useCallback(() => {
    const params = {
      ...intialState,
    };

    setsearchCreteria({
      ...intialState,
      pharmacy: '',
      from: '',
      to: '',
      searchQuery: '',
    });

    dispatch(getAllSessionOfPharmacyWithDateRange(params));
  }, []);

  const getDownloadData = useCallback(async () => {
    const options = searchCreteria.pharmacy;
    const value = options ? options.value : '';
    const params = {
      ...searchCreteria,
      pharmacy: value,
    };
    const response: any = await dispatch(downloadAllSessionListForPharmacy(params));
    if (response.type === 'client/download_all_session_pharmacy/fulfilled') {
      setDownloadData(response.payload.data.results);
    }
  }, [searchCreteria]);

  const downloadPharmacyInExcelFile = useCallback(() => {
    const groupedData = downloadData.reduce((acc, record) => {
      const status = record.sessionStatus.name === 'completed' ? 'Completed NMS' : record.sessionStatus.name;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(record);
      return acc;
    }, {});

    const wb = XLSX.utils.book_new();

    generateExcelData(groupedData, wb);
    const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Completed NMS.xlsx`; // Set the file name here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [downloadData]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const fetchAlClientStats = useCallback(() => {
    dispatch(getAllClientStats());
  }, []);

  const fetchAllUserPharmacy = useCallback(() => {
    dispatch(getAllPharmacyByUserIdList());
  }, []);

  const fetchAllSessionListingForPharmacy = useCallback(() => {
    dispatch(getAllSessionOfPharmacyWithDateRange(searchCreteria));
  }, [searchCreteria]);

  useEffect(() => {
    fetchAlClientStats();
    fetchAllUserPharmacy();
    fetchAllSessionListingForPharmacy();
  }, []);

  useEffect(() => {
    // getDownloadData();
  }, [searchCreteria]);

  const states = {
    pageNo,
    limit,
    totalCount,
    allUserPharmacy,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    allSessionPharmacyListing,
    countTotalIntervention,
    countTotalFollowUp,
    countTotal,
  };

  const handlers = {
    downloadPharmacyInExcelFile,
    handleSearchChange,
    customFilterOption,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelectMonth,
  };

  return { states, handlers };
};

export default useClientDashboard;
